import React, { useState } from 'react'

import Utils from '@utils'
import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import AddPost from '@components/Admin/Airline/AddPost'

const AirlinePostScreen = (props) => {
  const id = props?.match?.params.id;
  
  const [post, setPost] = useState(null)

  const panel = [{ title: 'AIRLINE_SCREEN_TAB_POST', icon: 'pe-7s-news-paper' }]

  const onSuccess = (success) => {
    const data = Utils.getValue(success, 'data')
    setPost(data)
  }

  return (
    <div className="content">
      <Axios run api="postairlineairline" onSuccess={onSuccess} others={id}>
        <Tabs tabs={panel} >
          <AddPost {...props} post={post} airline={id} />
        </Tabs>
      </Axios>
    </div>
  )
}

export default AirlinePostScreen
