import React, { useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import EditTags from './components/EditTags'

// @import styles
import './styles.scss'

const ListTags = (props) => {
  const [tag, setTag] = useState({})
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState('')

  const headers = [
    { name: '#' },
    { name: 'LIST_TAGS_TABLE_HEADER_NOME' },
    { name: 'LIST_TAGS_TABLE_HEADER_COLOR' },
    { name: 'LIST_TAGS_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (tag) => {
    setTag(tag)
    setOpen(true)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const { listTags = [], updateScreen } = props

  let array = Utils.filter(listTags, 'title', filter)

  return (
    <div className="ListTags">
      <Card title="LIST_TAGS_CARD_TITLE" category="LIST_TAGS_CARD_DESCRIPTION">
        <Table pagination={10} label={'LIST_TAGS_TABLE_FILTER'} onChange={onChange} headers={headers}>
          {array.map((element, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{element.title}</td>
              <td>
                <span className="color" style={{ backgroundColor: element.color }}></span>
              </td>
              <td className="td-options text-center">
                <button onClick={() => onEdit(element)} className="button-edit" />
                <Axios api="tag" method="delete" others={element._id} onSuccess={updateScreen}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <EditTags tag={tag} open={open} close={toggle} {...props} />
    </div>
  )
}

export default ListTags
