import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Translate, { dict } from '@UI/Translate'

import './styles.scss'

const defaultContext = {
  isShow: false,
  message: '',
  setLoad: () => {},
}

const { Consumer, Provider } = React.createContext(defaultContext)

class LoadConsumer extends Component {
  render() {
    return <Consumer>{this.props.children}</Consumer>
  }
}

class LoadProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShow: defaultContext.isShow,
      message: '',
    }
    this.timer = null
    this.setLoad = this.setLoad.bind(this)
  }

  /**
   *
   * @param {type} show
   * @returns {undefined}
   */
  setLoad(show) {
    if (typeof show === 'string') {
      this.setState({
        isShow: true,
        message: dict.translate(show),
      })
    } else {
      if (!show) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setState({
            message: '',
            isShow: show,
          })
        }, 300)
      } else {
        this.setState({
          message: '',
          isShow: show,
        })
      }
    }
  }

  render() {
    const value = {
      isShow: this.state.isShow,
      message: this.state.message,
      setLoad: this.setLoad,
    }
    /**/
    return <Provider value={value}>{this.props.children}</Provider>
  }
}

/**
 * Description of Load
 * @description load
 */
class Load extends Component {
  static propTypes = {
    text: PropTypes.string,
    internalLoad: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    internalLoad: false,
  }

  getTemplate(text) {
    text = !text ? 'LOAD' : text
    return (
      <div className="image">
        <div className="LoadImage lds-css ng-scope">
          <div className="lds-rolling">
            <div />
          </div>
        </div>
        <h5 className="text-center pl-3 pr-3">
          <Translate>{text}</Translate>
        </h5>
      </div>
    )
  }

  render() {
    const { text, internalLoad } = this.props
    return Boolean(internalLoad) ? (
      <div className="load center-center">{this.getTemplate(text)}</div>
    ) : (
      <LoadConsumer>
        {({ isShow, message }) =>
          isShow && (
            <div className="Load">
              <div className="background" />
              {this.getTemplate(message)}
            </div>
          )
        }
      </LoadConsumer>
    )
  }
}
/**/
export { LoadConsumer, LoadProvider }
export default Load
