import React from 'react'
import { Modal } from 'semantic-ui-react'

import MultiStep from '@UI/MultiStep'
import Translate from '@UI/Translate'
import Address from '@components/Admin/Register/Address'
import Documents from '@components/Admin/Register/Documents'
import BankDetails from '@components/Admin/Register/BankDetails'
import PersonalData from '@components/Admin/Register/PersonalData'
import LoyaltyProgram from '@components/Admin/Register/LoyaltyProgram'

import { Formik } from 'formik'

// @import styles
import './styles.scss'

const ViewUsers = (props) => {
  const { open, close, user = {} } = props

  const steps = [
    {
      name: 'Dados Pessoais',
      component: (props) => <PersonalData disabled {...props} />,
    },
    {
      name: 'Endereço',
      component: (props) => <Address disabled {...props} />,
    },
    {
      name: 'Dados Bancários',
      component: (props) => <BankDetails disabled {...props} />,
    },
    {
      name: 'Programa de Fidelidade',
      component: (props) => <LoyaltyProgram disabled {...props} />,
    },
    {
      name: 'Documentos',
      component: (props) => <Documents disabled {...props} />,
    },
  ]

  const formik = {
    enableReinitialize: true,
    initialValues: { ...user },
    validationSchema: {},
  }

  return (
    <Modal open={open} centered={false} size="large" className="ViewUsers" onClose={close}>
      <Modal.Header>
        <Translate>VIEW_USER_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Formik {...formik}>{(formikProps) => <MultiStep steps={steps} formik={formikProps} />}</Formik>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default ViewUsers
