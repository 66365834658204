import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import Utils from '@utils'
import Axios from '@UI/Axios'
import Card from '@UI/Card'
import Translate from '@UI/Translate'

import Form from './components/Form'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AddPost = (props) => {
  const { post = {} } = props

  const [object, setObject] = useState({})

  useEffect(() => {
    if (Utils.isObject(post.tag)) post.tag = post.tag._id
    setObject(post)
  }, [])

  const formik = {
    enableReinitialize: true,
    initialValues: { ...post },
    validationSchema: Yup.object().shape({
      tag: Yup.string().required('REQUIRED'),
      title: Yup.string().required('REQUIRED'),
      urlPhoto: Yup.string().required('REQUIRED'),
      description: Yup.string().min(70, 'REQUIRE_MIN_LENGTH').max(200, 'REQUIRE_MAX_LENGTH').required('REQUIRED'),
    }),
  }

  const onSubmit = ({ values, submit }) => {
    submit({ params: values })
  }

  const onSuccess = () => {
    props.updateScreen()
    props.close()
  }

  let type = Object.keys(post).length ? 'EDIT' : 'ADD'

  const api = {}
  let title = 'ADD_POST_TITLE_ADD'

  api.params = object
  if (type === 'ADD') {
    api.method = 'post'
  } else {
    api.method = 'put'
    api.others = post._id
    title = 'ADD_POST_TITLE_EDIT'
  }

  return (
    <Axios {...api} api="posts" onSuccess={onSuccess}>
      {({ submit }) => (
        <Formik {...formik} onSubmit={(values) => onSubmit({ values, submit })}>
          {({ handleSubmit, isValid, ...form }) => (
            <div className="AddPost">
              <Card title={title} category="ADD_POST_TITLE_DESCRIPTION">
                <Form formik={form} {...props} />
              </Card>
              <div className="form-footer">
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_POST_SEND</Translate>
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Axios>
  )
}

export default AddPost
