import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

const Form = (props) => {
  const { formik } = props

  const { handleBlur, handleChange, values = {}, errors } = formik

  const setSizeImage = ({ width, height }) => {
    values.image_width = width
    values.image_height = height
  }

  const onChange = (event) => {
    const { target } = event
    const { nameValue } = target
    event = {
      target: {
        ...target,
        value: nameValue,
      },
    }
    handleChange(event)
  }

  return (
    <Grid stretched stackable columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Input
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            label="ADD_AIRLINE_FORM_LABEL_NAME"
            value={Utils.getValue(values, 'name')}
            error={Utils.getValue(errors, 'name')}
            helperText={Utils.getValue(errors, 'name')}
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            type="number"
            name="percent"
            onBlur={handleBlur}
            onChange={handleChange}
            label="ADD_AIRLINE_FORM_LABEL_PERCENT"
            value={Utils.getValue(values, 'percent')}
            error={Utils.getValue(errors, 'percent')}
            helperText={Utils.getValue(errors, 'percent')}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Input
            type="number"
            name="limitMinimum"
            onBlur={handleBlur}
            onChange={handleChange}
            label="ADD_AIRLINE_FORM_LABEL_LIMIT_MINIMUM"
            value={Utils.getValue(values, 'limitMinimum')}
            error={Utils.getValue(errors, 'limitMinimum')}
            helperText={Utils.getValue(errors, 'limitMinimum')}
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            type="number"
            name="limitMaximum"
            onBlur={handleBlur}
            onChange={handleChange}
            label="ADD_AIRLINE_FORM_LABEL_LIMIT_MAXIMUM"
            value={Utils.getValue(values, 'limitMaximum')}
            error={Utils.getValue(errors, 'limitMaximum')}
            helperText={Utils.getValue(errors, 'limitMaximum')}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Input
            type="radio"
            name="type"
            onBlur={handleBlur}
            onChange={onChange}
            nameValue="NATIONAL"
            value={Utils.getValue(values, 'type')}
            error={Utils.getValue(errors, 'type')}
            label="ADD_AIRLINE_FORM_LABEL_TYPE_NATIAONAL"
            helperText={Utils.getValue(errors, 'type')}
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            type="radio"
            name="type"
            onBlur={handleBlur}
            onChange={onChange}
            nameValue="INTERNATIONAL"
            label="ADD_AIRLINE_FORM_LABEL_TYPE_INTERNATIONAL"
            value={Utils.getValue(values, 'type')}
            error={Utils.getValue(errors, 'type')}
            helperText={Utils.getValue(errors, 'type')}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5} className="mg-auto">
          <Input
            type="cropper"
            aspectRatio={1 / 1}
            name="urlPhoto"
            onBlur={handleBlur}
            onChange={handleChange}
            setSizeImage={setSizeImage}
            value={Utils.getValue(values, 'urlPhoto')}
            error={Utils.getValue(errors, 'urlPhoto')}
            helperText={Utils.getValue(errors, 'urlPhoto')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Form
