import React, { useState } from 'react'

import Axios from '@UI/Axios'
import Tabs from '@UI/Tabs'
import AddTestimony from '@components/Admin/Testimony/AddTestimony'
import ListTestimony from '@components/Admin/Testimony/ListTestimony'

const TestimonyScreen = (props) => {
  let [run, setRun] = useState(1)
  const [testimonys, setTestimonys] = useState([])

  const panel = [
    { title: 'TESTIMONY_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'TESTIMONY_SCREEN_TAB_ADD', icon: 'pe-7s-plus' },
  ]

  const onGetSuccess = ({ data }) => {
    setTestimonys(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

  return (
    <div className="content">
      <Axios run={run} api="testimony" messageLoad="LOADING" onSuccess={onGetSuccess}>
        {() => (
          <Tabs tabs={panel}>
            <ListTestimony testimonys={testimonys} updateScreen={updateScreen} {...props} />
            <AddTestimony updateScreen={updateScreen} {...props} />
          </Tabs>
        )}
      </Axios>
    </div>
  )
}

export default TestimonyScreen
