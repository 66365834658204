import React, { useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'

import Axios from '@UI/Axios'
import Translate from '@UI/Translate'
import PersonalData from '@components/Admin/Register/PersonalData'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AddEditor = (props) => {
  const { user = {}, updateScreen } = props

  useEffect(() => {
    if (Object.keys(user).length) {
      setObject(user)
    } else {
      setObject({})
    }
  }, [props])

  const [object, setObject] = useState({})

  const type = Object.keys(user).length ? 'EDIT' : 'ADD'

  const validationSchemaAdd = Yup.object().shape({
    phone: Yup.string(),
    birthdate: Yup.string(),
    name: Yup.string().required('REQUIRED'),
    cpf: Yup.string().required('REQUIRED'),
    email: Yup.string().email('USER_ALREADY_REGISTERED').required('REQUIRED'),
    password: Yup.string().min(4, 'TO_SHORT').max(70, 'TOO_LONG').required('REQUIRED'),
  })

  const validationSchemaEdit = Yup.object().shape({
    phone: Yup.string(),
    password: Yup.string(),
    birthdate: Yup.string(),
    name: Yup.string().required('REQUIRED'),
    cpf: Yup.string().required('REQUIRED'),
    email: Yup.string().email('USER_ALREADY_REGISTERED').required('REQUIRED'),
  })

  const formik = {
    enableReinitialize: true,
    initialValues: { ...object },
    validationSchema: type === 'ADD' ? validationSchemaAdd : validationSchemaEdit,
  }

  const api = {}
  api.params = object

  if (type === 'ADD') {
    api.method = 'post'
  } else {
    api.method = 'put'
    api.others = user._id
  }

  const onSubmit = ({values: params, resetForm, submit}) => {
    submit({ params })
    resetForm()
  }

  const onSuccess = () => {
    updateScreen()
  }

  return (
    <div className="AddEditor">
      <Axios {...api} api="editor" onSuccess={onSuccess}>
        {({ submit }) => (
          <Formik {...formik} onSubmit={(values, data) => onSubmit({ values, submit, ...data })}>
            {({ handleSubmit, isValid, ...formik }) => (
              <>
                <PersonalData formik={formik} />
                <div className="form-footer">
                  <Button disabled={!isValid} onClick={handleSubmit}>
                    <Translate>EDITOR_ADD_EDIT_SEND</Translate>
                  </Button>
                </div>
              </>
            )}
          </Formik>
        )}
      </Axios>
    </div>
  )
}

export default AddEditor
