import { ReactComponent as maismilhas } from "@svg/maismilhas-white.svg";
import { ReactComponent as phone } from "@svg/phone-call.svg";
import { ReactComponent as mail } from "@svg/envelope.svg";
import { ReactComponent as face } from "@svg/facebook.svg";
import { ReactComponent as instagram } from "@svg/instagram.svg";
import { ReactComponent as rightArrow } from "@svg/right-arrow.svg";
import { ReactComponent as leftArrow } from "@svg/left-arrow.svg";
import { ReactComponent as RightArrowO } from "@svg/arrow-right-o.svg";
import { ReactComponent as LefttArrowO } from "@svg/arrow-left-o.svg";
import { ReactComponent as logo } from "@svg/logo.svg";
import { ReactComponent as MarkBanner } from "@svg/mark-banner.svg";
import { ReactComponent as StepLeft } from "@svg/step-left.svg";
import { ReactComponent as StepRigth } from "@svg/step-rigth.svg";
import { ReactComponent as user } from "@svg/user.svg";
import { ReactComponent as cursor } from "@svg/cursor.svg";
import { ReactComponent as tickets } from "@svg/tickets.svg";
import { ReactComponent as leader } from "@svg/leader.svg";
import { ReactComponent as base } from "@svg/base.svg";
import { ReactComponent as five } from "@svg/five.svg";
import { ReactComponent as MarkerArline } from "@svg/marker-arline.svg";
import { ReactComponent as TicketMarck } from "@svg/ticket-marcker.svg";
import { ReactComponent as moeda } from "@svg/moeda.svg";
import { ReactComponent as sent } from "@svg/sent.svg";
import { ReactComponent as plane } from "@svg/plane.svg";
import { ReactComponent as surface } from "@svg/surface.svg";
import { ReactComponent as sellstep } from "@svg/sellstep.svg";
import { ReactComponent as notecoins } from "@svg/node_coins.svg";
import { ReactComponent as paginationleft } from "@svg/pagination-left.svg";
import { ReactComponent as paginationrigth } from "@svg/pagination-rigth.svg";
import { ReactComponent as search } from "@svg/search.svg";
import { ReactComponent as nextpost } from "@svg/nextpost.svg";
import { ReactComponent as blog } from "@svg/blog.svg";
import { ReactComponent as large_arrow_rigth } from "@svg/large_arrow_rigth.svg";
import { ReactComponent as coin } from "@svg/coin.svg";
import { ReactComponent as calendar } from "@svg/calendar.svg";
import { ReactComponent as check } from "@svg/check.svg";
import { ReactComponent as close } from "@svg/close.svg";
import { ReactComponent as large_arrow_left } from "@svg/large_arrow_left.svg";
import { ReactComponent as google } from "@svg/google.svg";
import { ReactComponent as trash } from "@svg/trash.svg";
import { ReactComponent as heart_o } from "@svg/heart_o.svg";
import { ReactComponent as whatsapp_o } from "@svg/whatsapp_o.svg";
import { ReactComponent as youtube_o } from "@svg/youtube_o.svg";
import { ReactComponent as facebook_o } from "@svg/facebook_o.svg";
import { ReactComponent as twitter_o } from "@svg/twitter_o.svg";
import { ReactComponent as linkedin } from "@svg/linkedin.svg";
import { ReactComponent as whatsapp } from "@svg/whatsapp.svg";
import { ReactComponent as instagram_o } from "@svg/instagram_o.svg";
import { ReactComponent as marck } from "@svg/marck.svg";
import { ReactComponent as heart } from "@svg/heart.svg";
import { ReactComponent as price } from "@svg/price.svg";
import { ReactComponent as flight } from "@svg/flight.svg";
import { ReactComponent as comment } from "@svg/comment.svg";

export default {
  flight,
  marck,
  heart,
  price,
  youtube_o,
  whatsapp_o,
  twitter_o,
  instagram_o,
  linkedin,
  close,
  google,
  trash,
  heart_o,
  facebook_o,
  whatsapp,
  phone,
  mail,
  face,
  instagram,
  leftArrow,
  logo,
  blog,
  rightArrow,
  user,
  cursor,
  tickets,
  base,
  five,
  leader,
  maismilhas,
  moeda,
  sent,
  plane,
  surface,
  sellstep,
  notecoins,
  paginationrigth,
  paginationleft,
  search,
  nextpost,
  coin,
  large_arrow_rigth,
  calendar,
  check,
  large_arrow_left,
  comment,
  "leftArrow-o": LefttArrowO,
  "rightArrow-o": RightArrowO,
  "mark-banner": MarkBanner,
  "step-left": StepLeft,
  "step-rigth": StepRigth,
  "marker-arline": MarkerArline,
  "ticket-marcker": TicketMarck
};
