import React from 'react'
import { Button, Modal, Grid } from 'semantic-ui-react'

// @import components
import Utils from '@utils'
import Input from '@UI/Input'
import Translate from '@UI/Translate'

import { Formik } from 'formik'
import * as Yup from 'yup'

// @import styles
import './styles.scss'

const AddEdit = (props) => {
  const type = Object.keys(props.topic).length ? 'EDIT' : 'ADD'

  const { topic = {}, open, close } = props

  const formik = {
    enableReinitialize: true,
    initialValues: { ...topic },
    validationSchema: Yup.object().shape({
      title: Yup.string(),
      description: Yup.string().required('REQUIRED'),
    }),
  }

  const onSubmit = (item) => {
    const { onAdd, onEdit } = props
    if (type === 'ADD') {
      onAdd(item)
    } else {
      onEdit(item)
    }
  }

  const formComponent = (formik) => {
    const { handleBlur, handleChange, values = {}, errors } = formik
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Input
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'title')}
              error={Utils.getValue(errors, 'title')}
              helperText={Utils.getValue(errors, 'title')}
              label="ADD_TOPIC_AIRLINE_ADD_EDIT_INPUT_LABEL_TITLE"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Input
              type="tinymce"
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'description')}
              error={Utils.getValue(errors, 'description')}
              helperText={Utils.getValue(errors, 'description')}
              label="ADD_TOPIC_AIRLINE_ADD_EDIT_INPUT_LABEL_DESCRIPTION"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  return (
    <Modal open={open} centered={false} size="small" className="AddEdit" onClose={close}>
      <Formik {...formik} onSubmit={onSubmit}>
        {({ handleSubmit, isValid, ...form }) => (
          <>
            <Modal.Header>
              <Translate>{`ADD_TOPIC_AIRLINE_ADD_EDIT_TITLE_${type}`}</Translate>
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>{formComponent(form)}</Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <div className="form-footer modal-footer">
                <Button onClick={close}>
                  <Translate>ADD_TOPIC_AIRLINE_ADD_EDIT_CANCEL</Translate>
                </Button>
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_TOPIC_AIRLINE_ADD_EDIT_SEND</Translate>
                </Button>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default AddEdit
