import React, { useEffect, useState } from 'react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import * as Api from '@UI/Axios'
import { getDecode } from '@utils/user.utils'
import PermissionConstant from '@constants/permission.contants'

import ListQuotation from '@components/Admin/Quotation/ListQuotation'
import AddQuotation from '@components/Admin/Quotation/AddQuotation'

import useDebounce from '@hooks/useDebounce'

const QuotationScreen = (props) => {

  const [load, setLoad] = useState(true)
  const [name, setName] = useState('')
  const [filter, setFilter] = useState({})
  const [airlines, setAirlines] = useState([])
  const [quotations, setQuotations] = useState([])
  const [params, setParams] = useState({ limit: 10, total: 1 })

  const { permission, user } = getDecode() || {}

  const debouncedName = useDebounce(name, 500)
  const manager = permission === PermissionConstant.MANAGER;

  const panel = [
    { title: 'QUOTATION_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'QUOTATION_SCREEN_TAB_ADD', icon: 'pe-7s-plus' }
  ]

  let others = ''
  let api = 'quotations'
  if (user && !manager) {
    panel.pop();
    api = 'users'
    others = `${user._id}/quotations`
  }

  useEffect(() => {
    const init = async () => {
      filter.name = name
      setFilter({ ...filter })
      await getApi()
    }
    init();
  }, [debouncedName])

  const onGetSuccessAirline = ({ data }) => {
    setAirlines(data)
  }

  const onGetSuccess = ({ data }) => {
    const { data: list, ...itens } = data
    setQuotations(list)
    setParams(itens)
    setLoad(false)
  }

  const pagination = async (page) => {
    params.page = page
    await getApi()
  }

  const getApi = async () => {
    setLoad(true)
    setQuotations([])
    if (manager) {
      const { data } = await Api.http({ api, others: { ...params, ...filter } })
      onGetSuccess(data)
    } else {
      const { data } = await Api.http({ api, others: [others, { ...params, ...filter }] })
      onGetSuccess(data)
    }
  }

  return (
    <div className="content">
      {user._id && (
        <Axios run load={false} api="airlines" onSuccess={onGetSuccessAirline}>
          <Tabs tabs={panel}>
            <ListQuotation
              load={load}
              params={params}
              setName={setName}
              airlines={airlines}
              pagination={pagination}
              permission={permission}
              quotations={quotations}
              updateScreen={getApi}
              {...props}
            />
            {manager && <AddQuotation airlines={airlines} updateScreen={getApi} />}
          </Tabs>
        </Axios>
      )}
    </div>
  )
}

export default QuotationScreen
