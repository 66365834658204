import classnames from 'classnames';
import React, { Component } from 'react';

import NavPanel from './components/NavPanel';

//Styles
import './styles.scss';

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelActive: 0
    };
  }

  component() {
    const { children } = this.props;
    const { panelActive } = this.state;
    const content = (index, child) => {
      return <div key={index} className={`tab__content ${index === panelActive ? 'block' : 'hide'}`}>
        {child}
      </div>
    }
    if (Array.isArray(children)) {
      return <div className="card-content">{children.map((child, index) => content(index, child))}</div>
    } else {
      return <div className="card-content">{content(0, children)}</div>
    }
  }

  changePanel = (panelActive) => {
    this.setState({
      panelActive
    });
  }

  render() {
    const { tabs, minHeight, height = true, color = true } = this.props;
    const className = classnames(`Tabs`, {
      colorTabs: color,
      heightTabs: height
    });
    return (
      <div className={className} style={{ minHeight }}>
        {Array.isArray(tabs) &&
          <>
            <NavPanel {...this.props} tabs={tabs} panelOpen={this.changePanel} isActive={this.state.panelActive} />
            {this.component()}
          </>
        }
      </div>
      );
    }
  }

  export default Tabs;
