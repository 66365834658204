import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import Axios from '@UI/Axios'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Translate from '@UI/Translate'
import Crypto from '@utils/crypto.utils'
import PermissionConstant from '@constants/permission.contants'

import ViewUsers from './components/ViewUsers'
import ModalEdit from './components/ModalEdit'
import ModalDelete from './components/ModalDelete'

// @import styles
import './styles.scss'

const ListUsers = (props) => {
  const headers = [
    { name: 'USER_LIST_TABLE_HEADER_NAME' },
    { name: 'USER_LIST_TABLE_HEADER_CPF' },
    { name: 'USER_LIST_TABLE_HEADER_OPTION', class: 'text-center' },
  ];

  const [user, setUser] = useState({})
  const [item, setItem] = useState({})
  const [open, setOpen] = useState(false)

  const [hashModal, setHashModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const dictionary = {
    open: setOpen,
    hashModal: setHashModal,
    deleteModal: setDeleteModal,
  }

  const toggle = (name) => {
    const setAttr = dictionary[name]
    setAttr(![name])
  }

  const onSuccessGetViewUser = ({ data }) => {
    setUser(data)
    setHashModal(true)
  }

  const onSuccessDeleteUser = ({ data }) => {
    setItem(data)
    setDeleteModal(true)
  }

  const onSuccessGetHash = (hash) => {
    try {
      const data = Crypto.decrypt({ ...user, key: hash })
      setOpen(true)
      setHashModal(false)
      setUser(JSON.parse(data))
    } catch (error) {
      setHashModal(false)
    }
  }

  const onSuccessExport = ({ data }) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'report.csv'
    link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data)
    link.click()
  }

  const onChange = ({ target: { value } }) => {
    props.setName(value)
  }

  const { load, users = [], permission, pagination, params } = props

  const MANAGER = permission === PermissionConstant.MANAGER

  return (
    <div className="ListUsers">
      <Card
        category="Cadastros"
        title="Lista de Usuários"
        component={() =>
          MANAGER && (
            <Axios api="users.export" onSuccess={onSuccessExport}>
              {({ submit }) => (
                <div className="actions">
                  <Button onClick={submit} color="success">
                    <Translate>EXTRACT_DATA_CSV</Translate>
                  </Button>
                </div>
              )}
            </Axios>
          )
        }
      >
        <Table
          load={load}
          params={params}
          headers={headers}
          onChange={onChange}
          pagination={pagination}
          label="TABLE_LIST_PLACEHOLDER_SEARCH_NAME"
        >
          {users.map((element, index) => (
            <tr key={index}>
              <td>{element.name}</td>
              <td>{element.cpf}</td>
              <td className="td-options text-center">
                <Axios api="usersview" others={element._id} onSuccess={onSuccessGetViewUser}>
                  {({ submit }) => <button onClick={submit} className="button-config" />}
                </Axios>
                <Axios api="users" method="delete" others={element._id} onSuccess={onSuccessDeleteUser}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <ModalEdit close={() => toggle('hashModal')} open={hashModal} submit={onSuccessGetHash} />
      <ModalDelete item={item} close={() => toggle('deleteModal')} open={deleteModal} {...props} />
      <ViewUsers {...props} user={user} open={open} close={() => toggle('open')} />
    </div>
  )
}

export default ListUsers
