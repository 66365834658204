import React from 'react'

import Utils from '@utils'
import Translate from '@UI/Translate'
import CardStepOne from './components/CardStepOne'

// Styles
import './styles.scss'

const StepOne = (props) => {
  const { airlines, formik } = props

  const { handleChange, values } = formik

  const findAirline = (id) => {
    const miles = Utils.getValue(values, 'miles') || []
    return !!miles.find((e) => e.idAirline === id)
  }

  const national = airlines.filter(({ type }) => type === 'NATIONAL')
  const international = airlines.filter(({ type }) => type === 'INTERNATIONAL')

  const onChange = ({ target: { name, value, item = {}, nameValue } }) => {
    let element = {}
    const miles = Utils.getValue(values, 'miles') || []
    if (name === 'idAirline') {
      if (value) {
        element[name] = nameValue
        element.airline = item.name
        element.urlPhoto = item.urlPhoto
        miles.push(element)
      } else {
        const i = miles.findIndex((event) => event.idAirline === nameValue)
        miles.splice(i, 1)
      }
    }
    Utils.setValue(values, 'miles', miles)
    handleChange({ target: { name: 'miles', value: miles } })
  }

  return (
    <div className="StepOne">
      <div className="header_step_one">
        <h3 className="title">
          <Translate>ADMIN_STEP_STEP_ONE_TITLE_NATIONAL</Translate>
        </h3>
      </div>
      <div className="step_one_body">
        {national.map((item, index) => (
          <div className="stepOne_content" key={index}>
            <CardStepOne onChange={onChange} index={index} item={item} active={findAirline(item._id)} />
          </div>
        ))}
      </div>
      <div className="header_step_one">
        <h3 className="title">
          <Translate>ADMIN_STEP_STEP_ONE_TITLE_INTERNATIONAL</Translate>
        </h3>
      </div>
      <div className="step_one_body">
        {international.map((item, index) => (
          <div className="stepOne_content" key={index}>
            <CardStepOne onChange={onChange} index={index} item={item} active={findAirline(item._id)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepOne
