import React, { Component } from "react";
import { Switch } from 'react-router-dom'

import Footer from "@components/Admin/Footer";
import Sidebar from "@components/Admin/Sidebar";
import AdminNavbar from "@components/Admin/Navbars/AdminNavbar";

import GuardedRoute from "@UI/GuardedRoute";
import { getDecode } from "@utils/user.utils";

import routes from "@src/routes.js";
import image from "@images/sidebar-3.jpg";

import "./styles.scss";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "black",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open"
    };
  }

  getRoutes = routes => {
    return routes.map((router, key) => {
      return (<GuardedRoute key={key} path={router.layout + router.path + router.params} component={router.component} />)
    })
  };

  getBrandText = routes => {
    const { location } = this.props;
    for (let i = 0; i < routes.length; i++) {
      if (
        location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  handleImageClick = image => {
    this.setState({ image: image });
  };

  handleColorClick = color => {
    this.setState({ color: color });
  };

  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }

  componentDidUpdate(e) {
    const { location } = this.props;
    if (
      location.pathname &&
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  render() {
    const decode = getDecode();
    const array = routes.filter(({ permission }) => permission?.includes(decode?.permission));
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          routes={array.filter(item => item.menu)}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} brandText={this.getBrandText(array)} />
          <Switch>
            {this.getRoutes(array)}
          </Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
