import React, { useRef, useEffect, useState } from 'react'

import clsx from 'clsx'
import Translate, { dict } from '@UI/Translate'
import TextField from '@material-ui/core/TextField'

import File from './components/File'
import Select from './components/Select'
import Password from './components/Password'
import Checkbox from './components/Checkbox'
import DatePicker from './components/DatePicker'
import Autocomplete from './components/Autocomplete'

import InputMask from 'react-input-mask'

import './styles.scss'

const Input = (props) => {
  const inputRef = useRef(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [inputRef])

  const handleChange = (event) => {
    const { positive } = props
    if (typeof props.onChange === 'function') {
      const { value, type } = event.target
      event.target = {
        ...props,
        ...event.target,
      }
      if (type === 'number' && !isNaN(value)) {
        event.target.value = Number(value)
        if (positive && value < 0) {
          event.target.value = 0
        }
      }
      if (value === '') event.target.value = value
      props.onChange(event)
    }
  }

  const label = () => {
    const { label, type } = props
    if (label && type !== 'radio' && type !== 'checkbox') {
      return <Translate>{String(label)}</Translate>
    } else {
      return null
    }
  }

  const onClick = () => {
    setOpen(true)
  }

  const inputProps = () => {
    let { run, checked, placeholder, helperText, readOnly, disabled, shrink, load, value, ...others } = props
    const InputProps = {
      readOnly,
      disabled,
    }
    const InputLabelProps = { shrink: shrink || open || value }
    if (load) {
      const Load = () => {
        return (
          <div class="icon-container">
            <i class="loader"></i>
          </div>
        )
      }
      InputProps.endAdornment = Load()
      others.popupIcon = <Load />
    }
    return {
      ...others,
      value,
      InputProps,
      InputLabelProps,
      label: label(),
      handleChange: handleChange,
      checked: Boolean(checked),
      placeholder: dict.translate(placeholder),
      helperText: !disabled ? dict.translate(helperText) : null,
    }
  }

  const switchType = () => {
    let { type, mask } = props

    switch (type) {
      case 'autocomplete':
        return <Autocomplete {...inputProps()} />
      case 'file':
        return <File {...inputProps()} />
      case 'select':
        return <Select {...inputProps()} />
      case 'password':
        return <Password {...inputProps()} />
      case 'date':
        return <DatePicker {...inputProps()} />
      case 'checkbox':
        return <Checkbox {...inputProps()} />
      default: {
        if (mask) {
          return (
            <InputMask onChange={handleChange} {...inputProps()}>
              {(inputProps) => <TextField {...inputProps} disableUnderline />}
            </InputMask>
          )
        } else {
          return <TextField onChange={handleChange} {...inputProps()} />
        }
      }
    }
  }

  const { className, error, disabled } = props

  let classes = clsx(['InputMaterial', className], { error: !disabled && error, disabled })

  return (
    <div ref={inputRef} onClick={onClick} className={classes}>
      {switchType()}
    </div>
  )
}

Input.defaultProps = {
  run: false,
  onChange: () => true,
}

export default Input
