import React from 'react'
import { Modal } from 'semantic-ui-react'

// @import components
import Translate from '@UI/Translate'
import AddManager from '@components/Admin/Manager/AddManager'

// @import styles
import './styles.scss'

const EditManager = (props) => {
  const { open, close } = props
  return (
    <Modal open={open} centered={false} size="large" className="EditManager" onClose={close}>
      <Modal.Header>
        <Translate>MANAGER_EDIT</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddManager {...props} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default EditManager
