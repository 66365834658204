class Util {
  constructor() {
    this.scrollPosition = 0;
    this.moment = require('moment')
  }

  isObject(value) {
    return value && value.constructor === Object
  }

  isArray(value) {
    return value && value.constructor === Array && value.length
  }

  getValue(obj, path) {
    return String(path)
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.')
      .reduce((acc, part) => acc && acc[part], obj)
  }

  setValue(obj = {}, path, value) {
    let i
    let array = String(path).replace(/^\./, '').split('.')
    for (i = 0; i < array.length - 1; i++) {
      if (!obj[array[i]]) obj[array[i]] = {}
      obj = obj[array[i]]
    }
    obj[array[i]] = value
  }

  verifyProp(object) {
    for (const key in object) {
      if (object[key] === false) {
        this.form.name = key
        this.form.valid = true
      } else if (this.isObject(object[key])) {
        this.verifyProp(object[key])
      }
      if (this.form.valid) return 0
    }
  }

  verifyInvalid(object) {
    this.form = {
      name: '',
      valid: false,
    }
    this.verifyProp(object)
    return this.form
  }

  setInvalid(invalid, name, valid, index) {
    if (index) {
      if (!this.isObject(invalid[index])) {
        invalid[index] = {}
      }
      invalid[index][name] = valid
    } else {
      invalid[name] = valid
    }
    return invalid
  }

  deleteInvalid(invalid, name, index) {
    if (index && name) {
      if (!this.isObject(invalid[index])) {
        invalid[index] = {}
      }
      if (name === 'all') {
        delete invalid[index]
      } else {
        delete invalid[index][name]
      }
    } else {
      delete invalid[name]
    }
    return invalid
  }

  isDate(date) {
    return !!(String(date).length > 17 && new Date(date) !== 'Invalid Date' && !isNaN(new Date(date)))
  }

  filter(array = [], name, value = '') {
    if (name) {
      return array.filter(
        (o) => String(this.getValue(o, name)).toLowerCase().includes(String(value).toLowerCase()) || value === ''
      )
    } else {
      return array.filter((o) => String(o).toLowerCase().includes(String(value).toLowerCase()) || value === '')
    }
  }

  elipses(input, size = 50) {
    if (String(input).length <= size) return input
    return input.substring(0, size) + '...'
  }

  toMaskNumber = (value = '') => {
    return new Intl.NumberFormat('pt-BR').format(value);
  };

  maskMoney = (value = '') => {
    const newValue = this.toMaskNumber(value);
    return newValue ? `R$ ${newValue}` : '';
  };

  removeMaskNumber(value) {
    return Number(String(value).replace(/[R$. ]+/g,"").replace(/,/g, '.'))
  }

  formatMoney(n, c, d, t) {
    let s, j, i
    c = isNaN((c = Math.abs(c))) ? 2 : c
    d = d || ','
    t = t || '.'
    s = n < 0 ? '-' : ''
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))
    j = (j = i.length) > 3 ? j % 3 : 0
    return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
        ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
        : '')
    )
  }

  fomartMinutes(value) {
    if (value >= 24 * 60 || value < 0) {
      throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
    }
    let hours = (value / 60) | 0
    let minutes = value % 60 | 0
    return this.moment.utc().hours(hours).minutes(minutes).format('hh:mm')
  }

  fomartMillisecond(value) {
    return this.moment.utc(value).format('h:mm')
  }

  fomartDate(value) {
    return this.moment(value).utc().format('DD/MM/YYYY')
  }

  stopPropagation(event) {
    if (this.isObject(event)) {
      event.stopPropagation()
    }
  }

  sort(array = [], key) {
    if (array.length) {
      return array.sort((a, b) => {
        if (a[key] > b[key]) {
          return -1
        }
        if (a[key] < b[key]) {
          return 1
        }
        return 0
      })
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateCPF(value) {
    let cpf = String(value).replace(/[^\d]+/g, '')
    if (cpf === '') return false
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false
    }
    let add = 0
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i)
    }
    let rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
      rev = 0
    }
    if (rev !== parseInt(cpf.charAt(9))) {
      return false
    }
    add = 0
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i)
    }
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) {
      rev = 0
    }
    if (rev !== parseInt(cpf.charAt(10))) {
      return false
    }
    return true
  }

  disableScroll() {
    this.scrollPosition = window.pageYOffset;
    const body = document.body
    body.style.width = '100%';
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${this.scrollPosition}px`;
  }

  enableScroll() {
    const body = document.body
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    document.documentElement.scrollTop = this.scrollPosition;
  }

  removeAccents(value) {
    return String(value)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  tranformUrl(text) {
    const value = this.removeAccents(text);
    return value
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }
}

export default new Util()
