import React from 'react'

// Styles
import './styles.scss'

import { Editor } from '@tinymce/tinymce-react'

const Tinymce = (props) => {
  
  const config = {
    height: 400,
    plugins: [
      'advlist autolink lists link image', 
      'charmap print preview anchor help',
      'searchreplace visualblocks code',
      'insertdatetime media table paste wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic | \
      alignleft aligncenter alignright | \
      bullist numlist outdent indent | help'
  }

  const handleChange = (value) => {
    const { name } = props
    const event = {
      target: {
        name,
        value,
      },
    }
    props.handleChange(event)
  }

  const { value } = props

  return (
    <Editor
      init={config}
      value={value}
      onEditorChange={handleChange}
      apiKey="ps74d8nwauq8rco9kf67oh0t9ayujj03qkjr2u1cxxg5zbsh"
    />
  )
}
export default Tinymce
