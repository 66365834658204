import React, { useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import Image from '@UI/Image'
import EditTestimony from './components/EditTestimony'

// @import styles
import './styles.scss'

const ListTestimony = (props) => {
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState('')
  const [testimony, setTestimony] = useState({})

  const headers = [
    { name: '#' },
    { name: 'LIST_TESTIMONY_TABLE_HEADER_NOME' },
    { name: 'LIST_TESTIMONY_TABLE_HEADER_REGION' },
    { name: 'LIST_TESTIMONY_TABLE_HEADER_DESCRIPTION' },
    { name: 'LIST_TESTIMONY_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (testimony) => {
    setOpen(true)
    setTestimony(testimony)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const { testimonys = [], updateScreen } = props

  let array = Utils.filter(testimonys, 'name', filter)

  return (
    <div className="ListTestimony">
      <Card title="LIST_TESTIMONY_CARD_TITLE" category="LIST_TESTIMONY_CARD_DESCRIPTION">
        <Table pagination={10} label={'LIST_TESTIMONY_TABLE_FILTER'} onChange={onChange} headers={headers}>
          {array.map((element, index) => (
            <tr key={index}>
              <td className="logo-testimony">
                <Image src={element.urlPhoto} alt="TESTIMONY" />
              </td>
              <td>{element.name}</td>
              <td>{element.region}</td>
              <td>{Utils.elipses(element.text)}</td>
              <td className="td-options text-center">
                <button onClick={() => onEdit(element)} className="button-edit" />
                <Axios api="testimony" method="delete" others={element._id} onSuccess={updateScreen}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <EditTestimony testimony={testimony} open={open} close={toggle} {...props} />
    </div>
  )
}

export default ListTestimony
