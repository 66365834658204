import _ from 'lodash';
import React, { Component } from 'react';

import * as Semantic from 'semantic-ui-react'

//@import compoent
import { dict } from '@UI/Translate';

// Styles
import './styles.scss';

export default class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
    this.onChange = this.onChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  onChange(event, { result }) {
    const { name, nameValue } = this.props
    event.target.name = name;
    event.target.value = result;
    event.target.nameValue = nameValue;
    this.props.handleChange(event);
  };

  handleSearchChange(event, { value }) {
    const { array, filter, name, nameValue } = this.props;
    event.target.name = name;
    event.target.value = value;
    event.target.nameValue = nameValue;
    this.props.handleChange(event);
    this.setState({ isLoading: true });
    setTimeout(() => {
      if (value.length < 1) {
        this.setState({ value: null });
        this.props.onChange({ target: { value: {}, name } });
      };
      const re = new RegExp(_.escapeRegExp(value), 'i');
      const isMatch = result => re.test(result[filter])
      this.setState({
        isLoading: false,
        results: _.filter(array, isMatch)
      });
    }, 200);
  };

  render() {
    let {
      load,
      name,
      index,
      value,
      disabled,
      readonly,
      placeholder = '',
    } = this.props;

    const { results, isLoading } = this.state;

    return (<Semantic.Search
      name={name}
      index={index}
      value={value}
      className="Search"
      results={results}
      loading={isLoading || load}
      onResultSelect={this.onChange}
      onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
      input={{
        disabled: readonly || disabled,
        fluid: true,
        placeholder: dict.translate(placeholder || '')
      }}
    />)
  }
}
