import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import Translate from '@UI/Translate'
import PermissionConstant from '@constants/permission.contants'

// @import styles
import './styles.scss'

const ListNewsLetters = (props) => {
  const [filter, setFilter] = useState('')

  const headers = [
    { name: '#' },
    { name: 'LIST_NEWS_LETTERS_TABLE_HEADER_NAME' },
    { name: 'LIST_NEWS_LETTERS_TABLE_HEADER_EMAIL' },
    { name: 'LIST_NEWS_LETTERS_TABLE_HEADER_PHONE' },
    { name: 'LIST_NEWS_LETTERS_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const onSuccessExport = ({ data }) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'report.csv'
    link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data)
    link.click()
  }

  const { newsLetters = [], updateScreen, permission } = props

  let array = Utils.filter(newsLetters, 'name', filter)
  const MANAGER = permission === PermissionConstant.MANAGER

  return (
    <div className="ListNewsLetters">
      <Card
        title="LIST_NEWS_LETTERS_CARD_TITLE"
        category="LIST_NEWS_LETTERS_CARD_DESCRIPTION"
        component={() =>
          MANAGER && (
            <Axios api="newsletter.export" onSuccess={onSuccessExport}>
              {({ submit }) => (
                <div className="actions">
                  <Button onClick={submit} color="success">
                    <Translate>EXTRACT_DATA_CSV</Translate>
                  </Button>
                </div>
              )}
            </Axios>
          )
        }
      >
        <Table pagination={10} label={'LIST_NEWS_LETTERS_TABLE_FILTER'} onChange={onChange} headers={headers}>
          {array.map((element, index) => (
            <tr key={index}>
              <td>{index}</td>
              <td>{element.name}</td>
              <td>{element.email}</td>
              <td>{element.phone}</td>
              <td className="td-options text-center">
                <Axios api="newsletter" method="delete" others={element._id} onSuccess={updateScreen}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
    </div>
  )
}

export default ListNewsLetters
