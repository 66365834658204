import React, { useState, useEffect } from 'react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import * as Api from '@UI/Axios'
import useDebounce from '@hooks/useDebounce'
import ListPosts from '@components/Admin/Posts/ListPosts'
import AddPost from '@components/Admin/Posts/AddPost'

const PostsScreen = (props) => {
  const [run, setRun] = useState(1)
  const [tags, setTags] = useState([])
  const [title, setTitle] = useState('')
  const [load, setLoad] = useState(true)
  const [filter, setFilter] = useState({})
  const [listPosts, setListPosts] = useState([])
  const [params, setParams] = useState({limit : 10, total: 1})

  const debouncedTitle = useDebounce(title, 500)

  const panel = [
    { title: 'POST_SCREEM_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'POST_SCREEM_TAB_ADD', icon: 'pe-7s-plus' },
  ]

  useEffect(() => {
    const init = async () => {
      filter.title = title
      setFilter({ ...filter })
      await getApi()
    }
    init()
  }, [debouncedTitle])

  const onGetSuccess = ({ data }) => {
    const { data: posts, ...itens } = data
    setListPosts(posts)
    setParams(itens)
    setLoad(false)
  }

  const onGetSuccessTag = ({ data }) => {
    setTags(data)
  }

  const updateScreen = () => {
    setLoad(true)
    setRun(run + 1)
  }

  const pagination = async (page) => {
    params.page = page
    await getApi()
  }

  const getApi = async () => {
    setLoad(true)
    setListPosts([])
    const { data } = await Api.http({ api: 'posts', others: { ...params, ...filter } })
    onGetSuccess(data)
  }

  return (
    <div className="content">
      <Axios load={false} run={run} others={params} api="posts" onSuccess={onGetSuccess}>
        <Axios load={false} run api="tag" onSuccess={onGetSuccessTag}>
          <Tabs tabs={panel}>
            <ListPosts
              load={load}
              tags={tags}
              params={params}
              setTitle={setTitle}
              listPosts={listPosts}
              pagination={pagination}
              updateScreen={updateScreen}
              {...props}
            />
            <AddPost tags={tags} updateScreen={updateScreen} {...props} />
          </Tabs>
        </Axios>
      </Axios>
    </div>
  )
}

export default PostsScreen
