import React, { useState } from 'react'
import { Link } from "react-router-dom";

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import Image from '@UI/Image' 
import EditAirline from './components/EditAirline'

// @import styles
import './styles.scss'

const ListAirline = (props) => {
  const [open, setOpen] = useState(false)
  const [filter, setFilter] = useState('')
  const [airline, setAirline] = useState({})

  const headers = [
    { name: '#' },
    { name: 'LIST_AIRLINE_TABLE_HEADER_NAME' },
    { name: 'LIST_AIRLINE_TABLE_HEADER_BEGIN' },
    { name: 'LIST_AIRLINE_TABLE_HEADER_END' },
    { name: 'LIST_AIRLINE_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (airline) => {
    setOpen(true)
    setAirline(airline)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const { airlines = [], updateScreen } = props

  let array = Utils.filter(airlines, 'name', filter)

  return (
    <div className="ListAirline">
      <Card title="LIST_AIRLINE_CARD_TITLE" category="LIST_AIRLINE_CARD_DESCRIPTION">
        <Table pagination={10} label={'LIST_AIRLINE_TABLE_FILTER'} onChange={onChange} headers={headers}>
          {array.map((element, index) => (
             <tr key={index}>
             <td className="logo-airline">
               <Image src={element.urlPhoto} alt="Airline" />
             </td>
             <td>{element.name}</td>
             <td>{element.limitMinimum}</td>
             <td>{element.limitMaximum}</td>
             <td className="td-options text-center">
               <button onClick={() => onEdit(element)} className="button-edit" />
               <Axios api="airlines" method="delete" others={element._id} onSuccess={updateScreen}>
                 {({ submit }) => <button onClick={submit} className="button-delete" />}
               </Axios>
               <Link to={`postairline/${element._id}`}>
                 <button className="button-news" />
               </Link>
             </td>
           </tr>
          ))}
        </Table>
      </Card>
      <EditAirline airline={airline} setAirline={setAirline} open={open} close={toggle} {...props} />
    </div>
  )
}

export default ListAirline
