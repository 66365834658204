import React from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import './styles.scss'

const DatePicker = (props) => {
  const { label, value, helperText, variant } = props

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  })

  const handleChange = ({ target: { value } }) => {
    const { name } = props
    const event = {
      target: {
        name,
        value,
      },
    }
    props.handleChange(event)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <TextField
      label={label}
      value={value}
      variant={variant}
      helperText={helperText}
      id="standard-adornment-password"
      type={values.showPassword ? 'text' : 'password'}
      onChange={handleChange}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              aria-label="toggle password visibility"
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
      }}
    />
  )
}

DatePicker.defaultProps = {
  error: null,
  helperText: null,
}

export default DatePicker
