import React from 'react';
import { TwitterPicker } from 'react-color';

// Styles
import './styles.scss';

class Color extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.colors = [
      '#FFC312',
      '#C4E538',
      '#12CBC4',
      '#12CBC4',
      '#FDA7DF',
      '#ED4C67',
      '#F79F1F',
      '#A3CB38',
      '#1289A7',
      '#D980FA',
      '#B53471',
      '#EE5A24',
      '#009432',
      '#0652DD',
      '#9980FA',
      '#833471',
      '#006266',
      '#1B1464',
      '#5758BB',
      '#6F1E51',
      '#FF6900',
      '#FCB900',
      '#7BDCB5',
      '#00D084',
      '#8ED1FC',
      '#0693E3',
      '#ABB8C3',
      '#EB144C',
      '#F78DA7',
      '#9900EF',
      '#84817a',
      '#cc8e35'
    ];
  }

  handleChange({ hex }) {
    const { name } = this.props;
    const event = {
      target: {
        name, value: hex
      }
    }
    this.props.handleChange(event)
  };

  render() {
    let {
      value
    } = this.props;
    return <div className="ColorPicker">
      <TwitterPicker
        color={value}
        {...this.props}
        colors={this.colors}
        onChange={this.handleChange}
      />
    </div >;
  }
}

export default Color;
