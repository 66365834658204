import React, { useState, useEffect } from 'react'

import Icon from '@UI/Icon'
import Axios from '@UI/Axios'
import Button from '@UI/Button'
import Translate from '@UI/Translate'
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import StepThree from './components/StepThree'
import StepFour from './components/StepFour'
import StepFive from './components/StepFive'

import { getUser, setUser } from '@utils/user.utils'
import SellMilesUtils from '@utils/sellmiles.utils'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AdminStep = () => {
  const [currentUser, setCurrentUser] = useState({})
  const [step, setStep] = useState(0)
  const [airlines, setAirline] = useState([])
  const [airlineMap, setAirlineMap] = useState([])

  useEffect(() => {
    setCurrentUser(getUser())
  }, [])

  const validator = {
    0: ({ miles = [] }) => !miles.length,
    1: ({ miles = [] }) => !!miles.filter((e) => !e.price).length,
    2: () => false,
    3: () => false,
  }

  const nextStep = () => {
    const item = step + 1
    setStep(item)
  }

  const backtStep = () => {
    const item = step - 1
    setStep(item)
  }

  const getWidth = () => {
    if (step <= 3) {
      return ((step + 1) / header.length) * 100
    }
  }

  const onSubmitQuotation = ({ data, submit }) => {
    data.price = SellMilesUtils.countTheFullPrice(data)
    submit({ params: data })
  }

  const onSuccessQuotation = ({ data }) => {
    const { user } = data || {}
    setUser(user);
    nextStep()
  }

  const onSuccessAirline = ({ data }) => {
    const airlineMap = SellMilesUtils.transformData(data)
    setAirline(data)
    setAirlineMap(airlineMap)
  }

  const resetStep = (resetForm) => {
    setStep(0)
    resetForm()
  }

  const formik = {
    initialValues: { bank: currentUser.bank },
    validationSchema: Yup.object().shape({
      bank: Yup.object().shape({
        name: Yup.string('REQUIRED').required('REQUIRED'),
        agency: Yup.string('REQUIRED').required('REQUIRED'),
        account: Yup.string('REQUIRED').required('REQUIRED'),
        holder: Yup.string('REQUIRED').required('REQUIRED'),
        type: Yup.string('REQUIRED').required('REQUIRED').nullable(),
      }),
      miles: Yup.array().of(
        Yup.object().shape({
          idAirline: Yup.string(),
          airline: Yup.string(),
          urlPhoto: Yup.string(),
          amount: Yup.number(),
          price: Yup.number(),
          payment: Yup.string(),
        })
      ),
      fidelity: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          urlPhoto: Yup.string(),
          limitMinimum: Yup.string(),
          login: Yup.string().required('REQUIRED'),
          password: Yup.string().required('REQUIRED'),
        })
      ),
    }),
  }

  const header = [
    {
      title: 'ADMIN_HOME_HEADER_STEP_ONE',
      subTitle: 'ADMIN_HOME_HEADER_STEP_ONE_SUB_TITLE',
      components: (props) => <StepOne airlines={airlines} {...props} />,
    },
    {
      title: 'ADMIN_HOME_HEADER_STEP_TWO',
      subTitle: 'ADMIN_HOME_HEADER_STEP_TWO_SUB_TITLE',
      components: (props) => <StepTwo airlineMap={airlineMap} {...props} />,
    },
    {
      title: 'ADMIN_HOME_HEADER_STEP_THREE',
      subTitle: 'ADMIN_HOME_HEADER_STEP_THREE_SUB_TITLE',
      description: 'ADMIN_HOME_HEADER_STEP_THREE_DESCRIPTION',
      components: (props) => <StepThree {...props} />,
    },
    {
      title: 'ADMIN_HOME_HEADER_STEP_FOUR',
      subTitle: 'ADMIN_HOME_HEADER_STEP_FOUR_SUB_TITLE',
      components: (props) => <StepFour {...props} />,
    },
  ]

  const data = header[step]

  const verifySteps = (formik) => {
    const { values, resetForm, handleSubmit } = formik
    if (step <= 3) {
      return (
        <>
          <div className="steps_card_content">{data.components({ formik })}</div>
          <div className="steps_card_footer">
            <Button disabled={step <= 0} onClick={backtStep} basic iconLeft="large_arrow_left">
              ADMIM_STEP_BACK
            </Button>
            <Button
              disabled={validator[step](values)}
              onClick={step === 3 ? handleSubmit : nextStep}
              iconRigth="large_arrow_rigth"
            >
              ADMIN_STEP_NEXT
            </Button>
          </div>
        </>
      )
    } else {
      return <StepFive resetStep={() => resetStep(resetForm)} />
    }
  }

  return (
    <div className="AdminStep">
      <Axios run api="airlines" onSuccess={onSuccessAirline}>
        <div className="dashboard-content_header">
          <div className="dashboard-content_header_title">
            <h3>
              <Translate>ADMIN_HOME_TITLE</Translate>
            </h3>
          </div>
          <div className="dashboard-content_header_progress">
            <div className="progress_bar">
              <div style={{ width: `${getWidth()}%` }} className="progress_bar_width">
                <span className="icon_flight">
                  <Icon name="flight" />
                </span>
              </div>
            </div>
            <div className="progress_bar_label">
              {header.map((item, index) => (
                <div
                  key={index}
                  style={{ width: `25%` }}
                  className={`progress_bar_label_item ${step >= index ? 'active' : ''}`}
                >
                  <p className="number_label">0{index + 1}.</p>
                  <p>
                    <Translate>{item.title}</Translate>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="dashboard-content_header_body">
            {step <= 3 && (
              <div className="content_header_body_header">
                <h3 className="title">
                  <Translate>{data.subTitle}</Translate>
                </h3>
                <p className="description">
                  <Translate>{data.description}</Translate>
                </p>
              </div>
            )}
            <div className="steps_card_main">
              <Axios api="quotationadmin" method="post" onSuccess={onSuccessQuotation}>
                {({ submit }) =>
                  currentUser.name && (
                    <Formik {...formik} onSubmit={({ ...data }) => onSubmitQuotation({ data, submit })}>
                      {({ ...formik }) => verifySteps(formik)}
                    </Formik>
                  )
                }
              </Axios>
            </div>
          </div>
        </div>
      </Axios>
    </div>
  )
}

export default AdminStep
