let configs = {};
const api = require('./api').default;
const reactAppEnv = Boolean(process.env.REACT_APP_ENV_TYPE) ? require(`./${process.env.REACT_APP_ENV_TYPE}`).default : {};
const local = require(`./local`).default;
const listConfigs = [
  api,
  reactAppEnv,
  local
];
/**/
for (let item in listConfigs) {
  configs = Object.assign(configs, listConfigs[item]);
}
/**/
export default configs;
