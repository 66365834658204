import React, { Component } from 'react'
import { Pagination } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

import './styles.scss'

class TablePagination extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e, { activePage }) {
    this.props.newPage(activePage)
  }

  serverPage = () => {
    const { params, pagination } = this.props
    const { page, limit, total } = params
    const onChange = (e, { activePage }) => {
      pagination(activePage)
    }
    return (
      <Pagination
        firstItem={false}
        lastItem={false}
        activePage={page}
        onPageChange={onChange}
        totalPages={Math.ceil(total / limit)}
      />
    )
  }

  render() {
    const { pages, params, pageActual, onChange, pageItens, pagination } = this.props

    const server = typeof pagination === 'function' && params

    let pageSize = !isNaN(pagination) ? pagination : params.total

    let data = Utils.getValue(pagination, 'success.data') || {}
    return (
      pagination && (
        <div columns="equal" className="footer-pagination ml-0 mr-0">
          {!server ? (
            <Input
              array={pageItens}
              inline
              type="select"
              name="pageSize"
              nameValue="value"
              onChange={onChange}
              label="TABLE_LIST_PAGE_ITENS"
              placeholder="TABLE_LIST_PAGE_ITENS"
              value={pageSize === data.maxResults ? 0 : pageSize}
            />
          ) : null}
          {!server ? (
            <Pagination
              firstItem={false}
              lastItem={false}
              activePage={pageActual}
              onPageChange={this.onChange}
              totalPages={pages.length}
            />
          ) : (
            this.serverPage()
          )}
        </div>
      )
    )
  }
}

export default TablePagination
