import React, { useState } from 'react'

import Table from '@UI/Table'
import Card from '@UI/Card'
import Image from '@UI/Image'
import Axios from '@UI/Axios'
import EditPost from './components/EditPost'

// @import styles
import './styles.scss'

const ListPosts = (props) => {
  const [post, setPost] = useState({})
  const [open, setOpen] = useState(false)

  const headers = [
    { name: '#' },
    { name: 'LIST_POST_TABLE_HEADER_TITLE' },
    { name: 'LIST_POST_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (post) => {
    setPost(post)
    setOpen(true)
  }

  const onChange = ({ target: { value } }) => {
    props.setTitle(value)
  }

  const { load, listPosts = [], updateScreen, pagination, params } = props

  return (
    <div className="ListPosts">
      <Card title="LIST_POST_CARD_TITLE" category="LIST_NEWS_CARD_DESCRIPTION">
        <Table
          load={load}
          params={params}
          headers={headers}
          onChange={onChange}
          pagination={pagination}
        >
          {listPosts.map((element, index) => (
            <tr key={index}>
              <td className="logo-post">
                <Image src={element.urlPhoto} alt="POST" />
              </td>
              <td>{element.title}</td>
              <td className="td-options text-center">
                <button onClick={() => onEdit(element)} className="button-edit" />
                <Axios api="posts" method="delete" others={element._id} onSuccess={updateScreen}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <EditPost post={post} open={open} close={toggle} {...props} />
    </div>
  )
}

export default ListPosts
