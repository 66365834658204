import React from 'react'

import Image from '@UI/Image'
import Translate from '@UI/Translate'
import load from '@images/load.png'

import './styles.scss'

const Axios = (props) => {
  return (
    <div className="Load_primary">
      <Image src={load} size="small" alt="LOAD" />
      <p>
        <Translate>{props.message}</Translate>
      </p>
    </div>
  )
}
export default Axios
