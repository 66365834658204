import React from 'react'

import Translate from '@UI/Translate'
import * as Material from '@material-ui/core'
import { FormHelperText } from '@material-ui/core'
import { MenuItem, InputLabel, FormControl } from '@material-ui/core'

import './styles.scss'

const Select = (props) => {
  const handleChange = (e, { props: { value } }) => {
    const { array = [], nameValue, name } = props
    const event = {
      target: {
        name,
        value: value,
        item: array.find((item) => item[nameValue] === value),
      },
    }
    props.handleChange(event)
  }

  let { label, value, translate, nameValue, nameText = nameValue, array = [], inputProps, helperText } = props

  return (
    <FormControl className="Select">
      <InputLabel>{label}</InputLabel>
      <Material.Select value={value} onChange={handleChange} inputProps={inputProps}>
        {array.map((item, index) => (
          <MenuItem key={index} value={item[nameValue]}>
            {translate ? <Translate>{item[nameText]}</Translate> : item[nameText]}
          </MenuItem>
        ))}
      </Material.Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

Select.defaultProps = {
  value: '',
  array: [],
}

export default Select
