import React from 'react'
import clsx from 'clsx'

import Image from '@UI/Image'
import Input from '@UI/Input'

// Styles
import './styles.scss'

const CardStepOne = ({ item, index, onChange, active }) => {
  const styles = clsx(['CardStepOne'], { active })
  return (
    <div className={styles}>
      <div className="card_step_one_body">
        <Input
          type="checkbox"
          index={index}
          name="idAirline"
          item={item}
          nameValue={item._id}
          value={active}
          onChange={onChange}
        />
        <div className="company_image">
          <Image src={item.urlPhoto} size="small" alt="IMAGE" />
        </div>
      </div>
      <p className="card_step_one_body_name">{item.name}</p>
    </div>
  )
}

export default CardStepOne
