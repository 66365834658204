import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import Axios from '@UI/Axios'
import Card from '@UI/Card'
import Translate from '@UI/Translate'

import Form from './components/Form'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AddTestimony = (props) => {

  const [testimony, setTestimony] = useState({})

  useEffect(() => {
    if(props.testimony){
      setTestimony(props.testimony)
    }
  }, [props])

  const formik = {
    enableReinitialize: true,
    initialValues: { ...testimony },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('REQUIRED'),
      region: Yup.string().required('REQUIRED'),
      text: Yup.string().required('REQUIRED'),
      urlPhoto: Yup.string().required('REQUIRED')
    }),
  }

  const onSubmit = ({ values, submit }) => {
    submit({ params: values })
  }

  const onSuccess = () => {
    props.updateScreen()
    props.close()
  }

  let type = Object.keys(testimony).length ? 'EDIT' : 'ADD'

  const api = {}
  let title = 'ADD_TESTIMONY_CARD_TITLE_ADD'

  if (type === 'ADD') {
    api.method = 'post'
  } else {
    api.method = 'put'
    api.others = testimony._id
    title = 'ADD_TESTIMONY_CARD_TITLE_EDIT'
  }

  return (
    <Axios {...api} api="testimony" onSuccess={onSuccess}>
      {({ submit }) => (
        <Formik {...formik} onSubmit={(values) => onSubmit({ values, submit })}>
          {({ handleSubmit, isValid, ...form }) => (
            <div className="AddTestimony">
              <Card title={title} category="ADD_TESTIMONY_CARD_TITLE_DESCRIPTION">
                <Form formik={form} {...props} />
              </Card>
              <div className="form-footer">
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_TESTIMONY_SEND</Translate>
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Axios>
  )
}

export default AddTestimony
