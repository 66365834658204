import React from 'react'

import { Menu } from 'semantic-ui-react'

import Translate from '@UI/Translate'
import { goToSite } from '@utils/user.utils'

const AdminNavbarLinks = (props) => {

  return (
    <Menu position="right">
      <Menu.Item eventKey={3}>
        <a onClick={goToSite}>
          <span className="link">
            <Translate>ADMIN_NAV_BAR_LINKS_EXIT</Translate>
          </span>
        </a>
      </Menu.Item>
    </Menu>
  )
}

export default AdminNavbarLinks
