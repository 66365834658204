export default {
  api: {
    endpoints: {
      airlines: {
        url: '/airline',
      },
      bank: {
        url: '/bank',
      },
      postairline: {
        url: '/postairline',
        page: {
          url: '/postairline/page',
        },
      },
      postairlineairline: {
        url: '/postairline/airline',
      },
      quotationverify: {
        url: '/quotation/verify',
      },
      quotations: {
        url: '/quotation',
        aproved: {
          url: '/quotation/aproved',
        },
      },
      quotationadmin: {
        url: '/quotation/admin',
      },
      done: {
        url: '/quotation/done',
      },
      users: {
        url: '/user',
        export: {
          url: '/user/export',
        },
      },
      usersview: {
        url: '/user/view',
      },
      editor: {
        url: '/editor',
      },
      news: {
        url: '/news',
      },
      posts: {
        url: '/posts',
        mostread: {
          url: '/posts/mostread',
        },
        param: {
          url: '/posts/param',
        },
        page: {
          url: '/posts/page',
        },
      },
      tag: {
        url: '/tag',
      },
      comment: {
        url: '/comment',
        like: {
          url: '/comment/like',
        },
        reply: {
          url: '/comment/reply',
        }
      },
      userquotation: {
        url: '/user/quotation',
      },
      usersearch: {
        url: '/user/search',
      },
      signup: {
        url: '/user/signup',
      },
      managers: {
        url: '/manager',
      },
      testimony: {
        url: '/testimony',
      },
      newsletter: {
        url: '/newsletter',
        export: {
          url: '/newsletter/export',
        },
      },
      newsletterSubscribe: {
        url: '/newsletter/subscribe',
      },
      login: {
        url: '/login',
      },
      loginPassword: {
        url: '/login/password',
      },
      cep: {
        external: true,
        url: 'https://viacep.com.br/ws',
      },
      state: { 
        url: '/state',
      },
      city: { 
        url: '/city/stateid',
      },
    },
  },
  routes: {
    publics: ['/login', '/login/'],
  },
  
  PageBlog: 'PYEDCTuI%#@c556355266643',
  UserSession: 'UYEDCTuI%#@cgG6654@$%',
  TokenSession: 'emY}q#{*m6N<B<~eQ^@J',
  ObjectSession: 'Ad@dZ}%g]M?@A+(;$=4F',
  AirlineSession: 'AIL$CTrT%$#3fvDE%¨%#',
  Location: 'https://maismilhas.com.br',
  user: {
    lang: 'pt-BR',
  },
}
