
import crypto from 'crypto';

class CryptoUtils {
  decrypt = ({ data, iv, key }) => {
    let hash = Buffer.from(iv, 'hex');
    let encryptedText = Buffer.from(data, 'hex');
    let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key, 'hex'), hash);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }
}

export default new CryptoUtils()
