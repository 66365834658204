import React, { useState } from 'react'
import { Modal, Button } from 'semantic-ui-react';

import Utils from '@utils'
import Table from '@UI/Table'
import Translate from '@UI/Translate';

// @import styles
import './styles.scss'

const ViewQuotation = (props) => {
  const [filter, setFilter] = useState('')

  const headers = [
    { name: '#' },
    { name: 'VIEW_QUOTATION_TABLE_HEADER_NAME' },
    { name: 'VIEW_QUOTATION_TABLE_HEADER_RECEIPT' },
    { name: 'VIEW_QUOTATION_TABLE_HEADER_MILES' },
    { name: 'VIEW_QUOTATION_TABLE_HEADER_VALUE' },
  ]

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const { open, close, quotation = {}} = props
  const {miles} = quotation;

  let array = Utils.filter(miles, 'airline', filter)

  return (

    <Modal open={open} size="large" className="ViewQuotation" onClose={close}>
        <Modal.Header>
          <Translate>VIEW_QUOTATION_MODAL_TITLE</Translate>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Table  onChange={onChange} label={'VIEW_QUOTATION_TABLE_FILTER'} pagination={2} headers={headers}>
              {array.map((element, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{element.airline}</td>
                  <td>{element.payment}</td>
                  <td>{element.amount}</td>
                  <td><Translate>COIN</Translate> {Utils.formatMoney(element.price)}</td>
                </tr>
              ))}
            </Table>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div className="form-footer">
            <Button onClick={close}>
              <Translate>VIEW_QUOTATION_MODAL_CANCEL</Translate>
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
  )
}

export default ViewQuotation
