import React from 'react'

import Utils from '@utils'
import CardStepTwo from './components/CardStepTwo'

// Styles
import './styles.scss'

const StepTwo = (props) => {
  const { airlineMap, formik } = props
  const { values, handleChange } = formik
  const miles = Utils.getValue(values, 'miles') || []

  const onChange = ({ target: { name, value, index, nameValue, keys } }) => {
    const miles = Utils.getValue(values, 'miles') || []
    if (name === 'price') {
      Utils.setValue(miles[index], name, nameValue)
      Utils.setValue(miles[index], 'payment', keys.payment)
    } else {
      Utils.setValue(miles[index], name, value)
    }
    Utils.setValue(values, 'miles', miles)
    handleChange({ target: { name: 'miles', value: miles } })
  }

  return (
    <div className="StepOne">
      {miles.map((item, index) => (
        <div className="stepOne_content" key={index}>
          <CardStepTwo index={index} item={item} onChange={onChange} airlineMap={airlineMap} />
        </div>
      ))}
    </div>
  )
}

export default StepTwo
