import React from 'react';
import * as Semantic from 'semantic-ui-react';

import { dict } from '@UI/Translate';

// Styles
import './styles.scss';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, { name, checked }) {
    const { nameValue, keys } = this.props;
    const event = {
      target: {
        name,
        value: checked,
        nameValue,
        keys
      }
    };
    this.props.handleChange(event);
  }

  render() {
    let { name, value, label } = this.props;
    return (
      <Semantic.Checkbox
        name={name}
        className="Checkbox"
        checked={Boolean(value)}
        onChange={this.handleChange}
        label={dict.translate(label)}
      />
    );
  }
}

export default Checkbox;
