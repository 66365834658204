import React from 'react'
import { Modal } from 'semantic-ui-react';

import AddQuotation from '../../../AddQuotation'

// @import styles
import './styles.scss'

const AprovedQuotation = (props) => {

  const { open, close } = props
  
  return (
    <Modal open={open} size="large" className="AprovedQuotation" onClose={close}>
     <div className="modalaprovedquotation">
        <AddQuotation {...props} />
     </div>
    </Modal>
  )
}

export default AprovedQuotation
