export default {
  COIN: "R$",
  LANG: 'pt-BR',
  CLOSE: 'Fechar',
  SEND: 'Enviar Registro',
  LOAD: 'Carregando...',
  LOADING: 'Carregando',
  EMPTY_LIST: 'Lista Vazia',

  INVALID_CRITERIA: 'Dado Invalido!',

  TOKEN_EXPIRED: 'Sessão expirada!',
  ADD_NEWS_SEND: 'Adicionar notícias',

  APROVED_QUOTATION_MODAL_TITLE: 'Aprovar quotação',

  REQUIRED: 'Item obrigátorio!',
  REQUIRE_MIN_LENGTH: "Limite minimo não obedecido",
  REQUIRE_MAX_LENGTH: "Limite máximo ultrapassado",

  POSTAIRLINE_UPDATE_SUCCESS: "Post atualizado com sucesso",
  POST_AIRLINE_URL_EXISTS: 'Essa url já esta sendo utilizada',
  POST_EMPTY_LIST_TITLE: 'Ups! ... não foram encontrados resultados',
  POST_EMPTY_LIST_DESCRIPTION: 'Por favor tente outra pesquisa',

  CPF_INVALID: 'Cpf Inválido',
  EMAIL_INVALID: 'Email Inválido',
  USER_ALREADY_REGISTERED: 'Usuário já cadastrado!',

  ERROR_FORM_SEND: 'Verifique os dados inseridos!',
  ERROR_FORM_FILL_FULL_DATA: 'Preencha todos os dados!',
  ERROR_FORM_FIELD_REQUIRED: '{name} é obrigatório!',

  MAIS_MILHAS: 'Mais Milhas',

  HOME_SCREEN_NAV_BAR_BUT_MILES: 'Quero comprar passagem aérea !',

  TABLE_LIST_PLACEHOLDER_SEARCH_NAME: 'Pesquisar pelo nome',
  TABLE_LIST_PLACEHOLDER_SEARCH_USER: 'Pesquisar por usuário',
  TABLE_LIST_PAGE_ITENS: 'Itens',
  INVALID_PARAMS: 'Verifique os dados inseridos',
  ADD_CIA_SEND: 'Enviar',

  QUOTATION_SEND_SUCCESS: 'Enviamos a cotação para o seu e-mail. Leia com atenção todas informações 😊',
  QUOTATION_NOT_FOUND: 'Cotação não encontrada!',
  QUOTATION_EXPIRED: 'Cotação expirada!',
  QUOTATION_UPDATE_SUCCESS: 'Cotação atualizada com sucesso!',
  QUOTATION_DELETE_SUCCESS: 'Cotação removida com sucesso!',

  USER_UPDATE_SUCCESS: 'Usuário atualizado com sucesso!',
  USER_EMAIL_CONFLICT: 'Você já possui um cadastro!',
  USER_NOT_FOUND: 'Usuário não encontrado!',
  USER_CREATE_SUCCESS: 'Usuário criado com sucesso!',
  USER_DELETE_SUCCESS: 'Usuário removido com sucesso!',
  USER_PASSWORD_REQUIRED: 'Senha invalida!',

  LOGIN_PASSWORD_INCORRECT: 'Senha invalida!',
  LOGIN_EMAIL_AND_PASSWORD_INCORRECT: 'Usuário ou email não cadastrados!',
  LOGIN_EMAIL_SEND_SUCCESS: 'E-mail enviado com sucesso!',
  LOGIN_EMAIL_NOT_FOUND: 'E-mail não encontrado!',
  LOGIN_EMAIL_REQUIRED: 'E-mail é obrigatório!',

  AIRLINE_UPDATE_SUCCESS: 'CIA atualizada com sucesso!',
  AIRLINE_CREATE_SUCCESS: 'CIA criada com sucesso!',
  AIRLINE_DELETE_SUCCESS: 'CIA removida com sucesso!',

  ADMIN_EMAIL_CONFLICT: 'Você já possui um cadastro!',
  ADMIN_CREATE_SUCCESS: 'Usuário criado com sucesso!',
  ADMIN_NOT_FOUND: 'Usuário não encontrado!',
  ADMIN_UPDATE_SUCCESS: 'Usuário atualizado com sucesso!',

  EDITOR_EMAIL_CONFLICT: 'Você já possui um cadastro!',
  EDITOR_CREATE_SUCCESS: 'Usuário criado com sucesso!',
  EDITOR_NOT_FOUND: 'Usuário não encontrado!',
  EDITOR_UPDATE_SUCCESS: 'Usuário atualizado com sucesso!',

  SERVER_ERROR: 'Error de servidor',
  SERVER_SUCCESS: 'Sucesso',

  TESTIMONY_TITLE: 'Veja os depoimentos de quem confia',
  TESTIMONY_DESCRIPTION: 'Vender suas milhas nunca foi tão fácil, veja os depoimentos de quem já vendeu e saiba mais',
  HOME_BANNER_TITLE: 'Venda suas milhas pelo melhor preço',
  HOME_BANNER_DESCRIPTION: 'Recebimento à vista ou programado, 100% seguro e transparente.',
  HOME_BANNER_TOGGLE_DESCRIPTION: 'VENDA SUAS MILHAS AQUI',
  BANNER_SELL_MILES_TITLE: 'Venda aqui suas milhas',
  BANNER_SELL_MILES_DESCRIPTION: 'Escolha a(s) cia(s) aérea(s)',

  CARD_MILES_AIRLINES_FOOTER_DESCRIPTION: 'Vender Milhas',

  HOME_MILES_AIRLINES_TITLE: 'Venda suas milhas',
  HOME_MILES_AIRLINES_DESCRIPTION: 'Escolha a companhia que deseja vender as milhas',

  ADD_AIRLINE_TITLE: 'Companhia Aéreas',
  ADD_AIRLINE_DESCRIPTION: 'Preencha todos os campos',

  SELL_STEP_TWO_TITLE: 'Configure sua cotação:',
  CARD_SELL_STEP_TWO_TITLE: 'Quantidade de pontos:',

  SELL_STEP_THREE_TITLE: 'Resultado',
  SELL_STEP_THREE_EARLY_RECEIPT: 'Recebimento antecipado',
  SELL_STEP_THREE_SCHEDULED_RECEIPT: ' Recebimento programado',
  SELL_STEP_FOUR_TITLE: 'Resumo da Proposta',
  SELL_STEP_FOUR_PROGRAM: 'Programa',
  SELL_STEP_FOUR_AMOUNT: 'Quantidade',
  SELL_STEP_FOUR_VALUE: 'Valor',

  LOGIN_FORM_INPUT_LABEL_USER: 'E-mail',
  LOGIN_FORM_INPUT_LABEL_PASSWORD: 'Senha',

  LOGIN_TITLE: 'Login',
  LOGIN_DESCRIPTION: 'Preencha todos os campos',
  LOGIN_SINGIN: 'Cadastre-se',
  LOGIN_FORGET_PASSWORD: 'Esqueceu sua senha ?',
  LOGIN_CANCEL: 'Cancelar',
  LOGIN_SEND: 'Entrar',

  LOGIN_INPUT_LABEL_EMAIL: 'Login *',
  LOGIN_INPUT_LABEL_PASSWORD: 'Senha *',

  FORGET_PASSWORD_TITLE: 'Precisa de ajuda com sua senha?',
  FORGET_PASSWORD_SUB_TITLE: 'Informe seu email de login da plataforma e nós o ajudaremos a criar uma nova senha.',
  FORGET_PASSWORD_INPUT_EMAIL: 'Email *',
  FORGET_PASSWORD_SEND: 'Enviar',
  FORGET_PASSWORD_CANCEL: 'Cancelar',

  SLIDER_BUTTONS_PREVIOS: 'Anterior',
  SLIDER_BUTTONS_NEXT: 'Próximo',
  SLIDER_BUTTONS_SEND: 'Enviar',

  HOW_TO_SELL_TITLE: 'Entenda todos os passos para vender suas milhas',
  HOW_TO_SELL_DESCRIPTION: 'Vender suas milhas nunca foi tão simples, veja o passo a passo e aprenda.',

  HOW_TO_SELL_CARD_HOW_TO_SELL_ONE_TITLE: 'Escolha a(s) companhia(s) aérea(s)',
  HOW_TO_SELL_CARD_HOW_TO_SELL_TWO_TITLE: 'Informe a quantidade de milhas que deseja vender',
  HOW_TO_SELL_CARD_HOW_TO_SELL_THREE_TITLE: 'Selecione como quer receber seu pagamento',
  HOW_TO_SELL_CARD_HOW_TO_SELL_FOUR_TITLE: 'Envie uma cópia da cotação para seu e-mail',
  HOW_TO_SELL_CARD_HOW_TO_SELL_FIVE_TITLE: 'Complete o cadastro e comece a lucrar',

  HOW_IT_WORKS_TITLE: 'Próximos passos',
  HOW_IT_WORKS_DESCRIPTION: 'Entenda como funciona o nosso sistema',
  HOW_IT_WORKS_CARD_HOW_IT_WORKS_ONE_TITLE: 'Em até 24 horas seu cadastro será analisado',
  HOW_IT_WORKS_CARD_HOW_IT_WORKS_TWO_TITLE: 'Você receberá um e-mail com a aprovação dessa análise',
  HOW_IT_WORKS_CARD_HOW_IT_WORKS_THREE_TITLE: 'O pagamento será depositado na sua conta bancária',

  PAYMENT_METHOD_TITLE: 'Você pode escolher como deseja receber pelas suas milhas',
  PAYMENT_METHOD_SCHEDULED_RECEIPT: 'Recebimento programado',
  PAYMENT_METHOD_SCHEDULED_RECEIPT_ONE_TITLE:
    'Você recebe em sua conta bancária em até 30 dias corridos. Suas milhas poderão ser usadas antes ou depois do pagamento.',
  PAYMENT_METHOD_SCHEDULED_RECEIPT_TWO_TITLE: 'Assim suas milhas valem mais.',

  PAYMENT_METHOD_EARLY_RECEIPT: 'Recebimento antecipado',
  PAYMENT_METHOD_EARLY_RECEIPT_ONE_TITLE: 'Você receberá em até 24 horas após a aprovação do seu cadastro.',
  PAYMENT_METHOD_EARLY_RECEIPT_TWO_TITLE: 'Suas milhas só serão resgatadas após você receber o pagamento em sua conta.',

  KNOW_OUR_NUMBERS_TITLE: 'Conheça nossos números',
  KNOW_OUR_NUMBERS_CARD_KNOW_OUR_NUMBERS_ONE_TITLE: '<strong>500 milhões</strong> de milhas negociadas',
  KNOW_OUR_NUMBERS_CARD_KNOW_OUR_NUMBERS_TWO_TITLE: '<strong>5 anos</strong> de atuação',
  KNOW_OUR_NUMBERS_CARD_KNOW_OUR_NUMBERS_THREE_TITLE: '<strong>Liderança</strong> de mercado',

  CONTACT_TITLE: 'Cadastre-se e fique sabendo tudo sobre o mercado de milhas',
  CONTACT_DESCRIPTION: 'Newsletter',
  CONTACT_DESCRIPTION_INPUT_LABEL_NAME: 'Nome',
  CONTACT_DESCRIPTION_INPUT_PLACEHOLDER_NAME: 'Digite seu nome',
  CONTACT_DESCRIPTION_INPUT_LABEL_EMAIL: 'E-mail',
  CONTACT_DESCRIPTION_INPUT_PLACEHOLDER_EMAIL: 'Digite seu email ex: exemplo@email.com',
  CONTACT_DESCRIPTION_INPUT_LABEL_PHONE: 'Telefone',
  CONTACT_DESCRIPTION_INPUT_PLACEHOLDER_PHONE: 'Digite seu telefone ex: (81) 8888-8888',
  CONTACT_SEND: 'Cadastrar',

  FOOTER_SERVICE_HOURS: 'Horário de atendimento',
  FOOTER_SERVICE_HOURS_ONE: 'Segunda a Sabádo: 24h',
  FOOTER_SERVICE_HOURS_TWO: 'Domingo e feriados: 08h às 20h',

  FOOTER_PHONE: '(81) 3072-5497',
  FOOTER_WHATSAPP: '(81) 99797-6911',
  FOOTER_EMAIL: 'contato@maismilhas.com.br',
  FOOTER_SOCIAL_NETWORK: 'Siga a Mais Milhas nas redes sociais',
  FOOTER_CERTIFICATE: 'Nossos Certificados',
  FOOTER_DESCRIPTION: 'Todos os direitos reservados, 2020.',
  FOOTER_DESCRIPTION_CNPJ: '<span>&nbsp;CNPJ:&nbsp;</span>26.878.221/0001-50',
  FOOTER_QUESTION: 'Dúvidas?',
  FOOTER_QUESTION_DESCRIPTION: 'Tire todas elas',
  FOOTER_QUESTION_DESCRIPTION_LINK: 'clicando aqui',

  REGISTER_SCREEN_TITLE: 'Complete seu cadastro',
  REGISTER_SCREEN_QUOTATION_EXPIRED: 'Cotação expirada!',
  REGISTER_SCREEN_QUOTATION_EXPIRED_DESCRIPTION: 'Cotação expirada, por favor faça uma nova cotação',
  REGISTER_SCREEN_QUOTATION_DONE: 'Cotação realizada!',
  REGISTER_SCREEN_QUOTATION_DONE_DESCRIPTION:
    'Esta cotação já foi processada, acesse o nosso site e realize uma nova cotação',
  REGISTER_SCREEN_MULTI_STEP_ADDRESS: 'Endereço',
  REGISTER_SCREEN_MULTI_STEP_PERSONAL_DATA: 'Dados Pessoais',
  REGISTER_SCREEN_MULTI_STEP_BACK_DETAILS: 'Dados Bancários',
  REGISTER_SCREEN_MULTI_STEP_LOYALTY_PROGRAM: 'Programa de Fidelidade',
  REGISTER_SCREEN_MULTI_STEP_DOCUMENTS: 'Documentos',
  REGISTER_SCREEN_SEND_SUCCESS: 'Utilize os dados cadastrados para realizar o login na plataforma!',

  MULTI_STEP_PREVIOS: 'Anterior',
  MULTI_STEP_NEXT: 'Próximo',
  MULTI_STEP_SEND: 'Enviar',

  USER_LIST_TABLE_HEADER_NAME: 'Nome',
  USER_LIST_TABLE_HEADER_CPF: 'CPF',
  USER_LIST_TABLE_HEADER_OPTION: 'Opções',

  USER_PROFILE_SCREEN_FORM_SEND: 'Atualizar',
  USER_PROFILE_SCREEN_MULTI_STEP_ADDRESS: 'Endereço',
  USER_PROFILE_SCREEN_MULTI_STEP_PERSONAL_DATA: 'Dados Pessoais',
  USER_PROFILE_SCREEN_MULTI_STEP_BACK_DETAILS: 'Dados Bancários',
  USER_PROFILE_SCREEN_MULTI_STEP_LOYALTY_PROGRAM: 'Programa de Fidelidade',
  USER_PROFILE_SCREEN_MULTI_STEP_DOCUMENTS: 'Documentos',

  PERSONAL_TITLE: 'Dados Pessoais',
  PERSONAL_DESCRIPTION: 'Preencha todos os campos',
  PERSONAL_DATA_INPUT_LABEL_NAME: 'Nome Completo *',
  PERSONAL_DATA_INPUT_LABEL_BIRTHDATE: 'Data de Nascimento *',
  PERSONAL_DATA_INPUT_LABEL_CPF: 'Cpf *',
  PERSONAL_DATA_INPUT_LABEL_PHONE: 'Telefone *',
  PERSONAL_DATA_INPUT_LABEL_EMAIL: 'Email *',
  PERSONAL_DATA_INPUT_LABEL_PASSWORD: 'Senha *',
  PERSONAL_DATA_INPUT_LABEL_MAN: 'Masculino',
  PERSONAL_DATA_INPUT_LABEL_WOMAM: 'Feminino',

  ADDRESS_TITLE: 'Endereço',
  ADDRESS_DESCRIPTION: 'Preencha todos os campos',
  ADDRESS_INPUT_LABEL_CEP: 'Cep *',
  ADDRESS_INPUT_LABEL_STREET: 'Rua *',
  ADDRESS_INPUT_LABEL_NUMBER: 'Numero *',
  ADDRESS_INPUT_LABEL_DISTRICT: 'Bairro *',
  ADDRESS_INPUT_LABEL_COMPLEMENT: 'Complemento',
  ADDRESS_INPUT_LABEL_UF: 'Estado *',
  ADDRESS_INPUT_LABEL_CITY: 'Cidade *',

  BANK_DETAILS_TITLE: 'Dados Bancário',
  BANK_DETAILS_DESCRIPTION: 'O pagamento será efetuado somente a conta vinculada ao titular do programa fidelidade.',
  BANK_DETAILS_INPUT_LABEL_HOLDER: 'Titular *',
  BANK_DETAILS_INPUT_LABEL_BANK: 'Banco *',
  BANK_DETAILS_INPUT_LABEL_AGENCY: 'Agência com dígito *',
  BANK_DETAILS_INPUT_LABEL_ACCOUNT: 'Conta com dígito *',
  BANK_DETAILS_INPUT_LABEL_OP: 'OP',
  BANK_DETAILS_INPUT_LABEL_PIX_TYPE: 'Tipo de Chave Pix',
  BANK_DETAILS_INPUT_LABEL_PIX_KEY: 'Chave Pix',

  LOYALTY_PROGRAM_TITLE: 'Programa de Fidelidade',
  LOYALTY_PROGRAM_DESCRIPTION: 'Preencha todos os campos',
  FORM_LOYALTY_PROGRAM_INPUT_LABEL_NAME: 'Nome',
  FORM_LOYALTY_PROGRAM_INPUT_LABEL_LOGIN: 'Login *',
  FORM_LOYALTY_PROGRAM_INPUT_LABEL_PASSWORD: 'Senha *',

  DOCUMENTS_TITLE: 'Documentos',
  DOCUMENTS_DESCRIPTION: 'Preencha todos os campos',
  DOCUMENTS_INPUT_LABEL_DOCUMENT: 'Identidade *',
  DOCUMENTS_INPUT_LABEL_HOME: 'Comprovante de Residência *',

  BLOG_SCREEN_NAV_BAR_HOME: 'Mais Milhas',
  BLOG_SCREEN_NAV_BAR_BLOG: 'Blog',
  BLOG_SCREEN_NAV_BAR_SINGUP: 'Cadastre-se',
  BLOG_SCREEN_NAV_BAR_LOGIN: 'Login',

  POST_SCREEM_TAB_LIST: 'Lista de Post',
  POST_SCREEM_TAB_ADD: 'Adicionar de Post',

  LIST_POST_TABLE_HEADER_TITLE: 'Título',
  LIST_POST_TABLE_HEADER_DESCRIPTION: 'Descrição',
  LIST_POST_TABLE_HEADER_OPTION: 'Opções',
  LIST_POST_CARD_TITLE: 'Posts',
  LIST_POST_CARD_DESCRIPTION: 'Lista de Posts',
  POST_ADD_ARTICLE_TABLE_FILTER: 'Pesquisar por título',

  EDIT_POST_TITLE: 'Editar Post',
  ADD_POST_TITLE_ADD: 'Adicionar Post',
  ADD_POST_TITLE_EDIT: 'Editar Post',
  ADD_POST_TITLE_DESCRIPTION: 'Preencha todos os campos',

  ADD_POST_SEND: 'Enviar',
  ADD_POST_INPUT_LABEL_URL: 'URL',
  ADD_POST_INPUT_LABEL_TAG: 'Tag *',
  ADD_POST_INPUT_LABEL_TITLE: 'Títilo *',
  ADD_POST_INPUT_LABEL_DESCRIPTION: 'Descrição *',

  ADD_ARTICLE_TABLE_HEADER_TITLE: 'Títilo',
  ADD_ARTICLE_TABLE_HEADER_DESCRIPTION: 'Descrição',
  ADD_ARTICLE_TABLE_HEADER_OPTIONS: 'Opções',

  ADD_ARTICLE_ADD_EDIT_TITLE_ADD: 'Adicionar Artigos',
  ADD_ARTICLE_ADD_EDIT_TITLE_EDIT: 'Editar Artigos',
  ADD_ARTICLE_ADD_EDIT_INPUT_LABEL_TITLE: 'Títilo',
  ADD_ARTICLE_ADD_EDIT_INPUT_LABEL_DESCRICAO: 'Descrição',
  ADD_ARTICLE_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_ARTICLE_ADD_EDIT_SEND: 'Enviar Artigo',

  CROPPER_REMOVE: 'Remover',
  CROPPER_SEND: 'Selecionar',
  CARD_NEXT_POST_NEXT: 'Próximo Post',

  CARD_NEW_WRITTEN_BY: 'Escrito por:',

  BLOG_FILTERS_INPUT_PLACEHOLDER_TAG: 'TUDO',
  BLOG_FILTERS_INPUT_LABEL_TAG: 'Quero ver posts sobre:',
  BLOG_FILTERS_INPUT_PLACEHOLDER_TITLE: 'O que você está buscando?',

  MOST_READ_TITLE: 'Posts mais lidos',

  CARD_MOST_CONTENT_TITLE: 'Saiba Mais',
  BLOG_NOTES_TITLE: 'Saiba Mais',
  BLOG_CARD_POST_TITLE: 'Saiba Mais',

  AIRLINE_SCREEN_TAB_LIST: 'Lista de Cias',
  AIRLINE_SCREEN_TAB_ADD: 'Adicionar Cias',
  AIRLINE_SCREEN_TAB_POST: 'Adicioanar Post',
  BLOG_SCREEN_NODE_ONE_TITLE: 'Transforme suas milhas em dinheiro',
  BLOG_SCREEN_NODE_TWO_TITLE: 'Faça seu cadastrado na Mais Milhas e começa a lucrar',

  EDITOR_SCREEN_TAB_LIST: 'Lista de Gestores',
  EDITOR_SCREEN_TAB_ADD: 'Adicionar Gestor',

  LIST_NEWS_LETTERS_TAG_LIST: 'Boletins Informativos',

  NEWS_SCREEN_TAG_LIST: 'Lista de Notícias',
  NEWS_SCREEN_TAG_ADD: 'Adicionar Notícia',

  QUOTATION_SCREEN_TAB_LIST: 'Lista de Cotações',
  QUOTATION_SCREEN_TAB_ADD: 'Enviar Cotação',

  TAGS_SCREEN_TAB_LIST: 'Lista de Tags',
  TAGS_SCREEN_TAB_ADD: 'Adicionar Tag',

  TESTIMONY_SCREEN_TAB_LIST: 'Lista de Depoimentos',
  TESTIMONY_SCREEN_TAB_ADD: 'Adicionar Depoimento',

  USER_SCREEN_TAB_LIST: 'Lista de Usuários',
  VIEW_USER_TITLE: "Visualizador de Usuários",
  VIEW_POST_SCREEN_NOTE_ONE_TITLE: 'Transforme suas milhas em dinheiro',

  ADMIN_NAV_BAR_LINKS_EXIT: 'Sair',

  REGISTER_HEADER_TITLE: 'Mais Milhas - Cotação de milhas',

  USER_MODAL_EDIT_TITLE: 'Informe o código',
  USER_MODAL_EDIT_SUB_TITLE: 'Para prosseguir, precisa informar o código de autorização.',
  USER_MODAL_EDIT_INPUT_HASH: 'Código',
  USER_MODAL_EDIT_CANCEL: 'Cancelar',
  USER_MODAL_EDIT_SEND: 'Prosseguir',

  USER_MODAL_DELETE_TITLE: 'Informe o código',
  USER_MODAL_DELETE_SUB_TITLE: 'Para prosseguir, precisa informar o código de autorização.',
  USER_MODAL_DELETE_INPUT_HASH: 'Código',
  USER_MODAL_DELETE_CANCEL: 'Cancelar',
  USER_MODAL_DELETE_SEND: 'Prosseguir',

  SELL_MILES_FLEX_HEADER_TITLE: 'Escolha a(s) cia(s) aérea(s):',
  SELL_MILES_FLEX_TITLE: 'Venda aqui as suas milhas',
  SELL_MILES_FLEX_SUB_TITLE: 'Escolha a(s) cia(s) aérea(s):',
  SELL_MILES_FLEX_NEXT: 'Continuar',

  BLOG_POST_TITLE: 'Acompanhe nosso blog e fique por dentro das nossas dicas',
  HOME_CARD_BLOG_POST_BUTTON: 'Saiba Mais',

  AILINE_PREVIEW_TITLE: 'Descubra quanto valem suas milhas',
  AILINE_PREVIEW_BUTTON: 'Cotar milhas',

  AIRLINE_PREVIEW_INPUT_PLACEHOLDER_NOME: 'Qual é o seu nome?',
  AIRLINE_PREVIEW_INPUT_PLACEHOLDER_EMAIL: 'Qual é o seu email?',
  AIRLINE_PREVIEW_INPUT_PLACEHOLDER_AIRLINE: 'Companhia aérea',
  AIRLINE_PREVIEW_INPUT_PLACEHOLDER_MILES: 'Quantas milhas você quer vender?',
  AIRLINE_PREVIEW_INPUT_PLACEHOLDER_WHATSAPP: 'Qual é seu número do Whatsapp ?',

  SELL_MILES_HOW_TO_SELL_TITLE: 'Entenda o processo:',
  PAGE_SELL_MILES_STEP_BACK: 'Voltar',
  PAGE_SELL_MILES_STEP_NEXT: 'Continuar',
  PAGE_SELL_MILES_STEP_BACK_HOME: 'Mudar cia(s) aérea(s)',
  PAGE_SELL_MILES_ANTECIPATED: 'Pagamento Antecipado',
  PAGE_SELL_MILES_PROGRAMED: 'Pagamento Programado',

  SELL_MILES_HOW_TO_SELL_CARD_HOW_TO_SELL_ONE_TITLE: 'Confira a(s) cia(s) aérea(s) escolhida(s)',
  SELL_MILES_HOW_TO_SELL_CARD_HOW_TO_SELL_FOUR_TITLE: 'Confira a cotação e envie uma cópia para seu e-mail',

  SELL_MILES_HEADER_ONE_TITLE: 'Quantas milhas você quer vender?',
  SELL_MILES_HEADER_TWO_TITLE: 'Escolha o tipo de recebimento',
  SELL_MILES_HEADER_THREE_TITLE: 'Resumo da proposta',
  SELL_MILES_HEADER_FOUR_TITLE: 'Informe seu email',
  SELL_MILES_HEADER_FIVE_TITLE: 'Cotação enviada por email',

  SELL_MILES_CARD_STEP_ONE_TITLE: 'Selecione a quantidade de milhas:',
  SELL_MILES_LABEL_NUMBER_TEXT: 'mil milhas',

  SELL_MILES_STEP_THREE_TITLE: 'Resumo da proposta',

  SELL_MILES_STEP_FOUR_INPUT_PLACEHOLDER_NAME: 'Nome completo',
  SELL_MILES_STEP_FOUR_INPUT_PLACEHOLDER_EMAIL: 'E-mail',
  SELL_MILES_STEP_FOUR_INPUT_PLACEHOLDER_WHATSAPP: 'Whatsapp',

  SELL_MILES_STEP_FOUR_TITLE: 'Estamos quase acabando!',
  SELL_MILES_STEP_FOUR_SUB_TITLE: 'Informe o seu e-mail para enviarmos as informações da cotação',
  SELL_MILES_STEP_FIVE_TITLE: 'Aguarde um momento, estamos enviando uma cópia da cotação para o seu e-mail',

  NAV_BAR_SUB_MENU_TITLE: 'Menu',
  NAV_BAR_SUB_MENU_HOW_TO_SELL: 'Como vender milhas?',
  NAV_BAR_SUB_MENU_HOW_IT_WORKS: 'Como funciona?',
  NAV_BAR_SUB_MENU_HOW_IT_PATMENT: 'Formas de recebimento',
  NAV_BAR_SUB_MENU_HOW_IT_KNOW_NUMBERS: 'Nossos números',
  NAV_BAR_SUB_MENU_HOW_IT_TESTIMONY: 'Depoimentos',
  NAV_BAR_SUB_MENU_NEW_LETTER: 'Newsletter',
  NAV_BAR_SUB_MENU_BLOG: 'Mais Milhas Blog',
  NAV_BAR_SUB_MENU_HOW_IT_CONTACT: 'Contato',

  COMMENTS_CARD_TITLE: 'Deixe um comentário',

  COMMENT_INPUT_PLACEHOLDER_TEXT: 'Digite um comentário aqui',
  COMMENTS_CARD_ACTION_TITLE: 'Publique sua resposta utilizando as redes socias!',
  COMMENTS_CARD_ACTION_FACEBOOK: 'Facebook',
  COMMENTS_CARD_ACTION_GOGLE: 'Google',

  COMMENTS_MESSAGE_PUBLISH: 'Publicar',
  COMMMENT_FOOTER_COMMENTS: 'comentários',
  COMMENTS_MESSAGE_REPLY: 'Responder',

  COMMENT_ICON_HEART: 'Curtir',
  COMMENT_ICON_TRASH: 'Excluir',
  COMMENT_ICON_COMMENT: 'Responder',

  REGISTER_HEADER_PERSONAL_DATA: 'Dados Pessoais',
  REGISTER_HEADER_ADDRESS: 'Endereço',
  REGISTER_HEADER_BANCK_DETAILS: 'Dados bancários',
  REGISTER_HEADER_BANCK_DETAIL_SMESSAGE_TITLE:
    'O pagamento será efetuado somente a conta vinculada ao titular do programa de fidelidade',
  REGISTER_HEADER_LOYALTY_PROGRAM: 'Programa de fidelidade',
  REGISTER_HEADER_BANCK_DOCUMENT: 'Documentos',
  REGISTER_HEADER_LOYALTY_PROGRAM_MESSAGE_TITLE:
    'Precisamos dos dados do programa de fidelidade para verificarmos o saldo de milhas e o prazo de expiração delas.',
  REGISTER_HEADER_LOYALTY_PROGRAM_MESSAGE_DESCRIPTION:
    'Não resgaremos suas milhas sem antes fecharmos negócio com você!',

  REGISTER_FOOTER_NEXT: 'Próximo',
  REGISTER_FOOTER_PREVIEW: 'Voltar',

  BANK_DETAILS_INPUT_LABEL_TYPE: 'Tipo de Conta',
  BACK_DETAILS_TYPE_CHAIN: 'Conta Corrente',
  BACK_DETAILS_TYPE_SAVINGS: 'Conta poupança',

  REGISTER_BREADCRUMB_SELL_MILES: 'Vender Milhas',
  REGISTER_BREADCRUMB_FORM: 'Completar cadastro',
  REGISTER_SCREEN_MESSAGE_SUCCESS: 'Seu cadastro de venda foi concluído e a sua cotação está em análise!',
  REGISTER_SCREEN_BUTTON_MESSAGE_SUCCESS: 'Voltar para tela inicial',

  ADMIN_HOME_TITLE: 'Cotação',
  ADMIN_HOME_HEADER_STEP_ONE: 'Programa de fidelidade',
  ADMIN_HOME_HEADER_STEP_TWO: 'Definir quantidade de milhas',
  ADMIN_HOME_HEADER_STEP_THREE: 'Dados de pagamento',
  ADMIN_HOME_HEADER_STEP_FOUR: 'Dados do programa',

  ADMIN_HOME_HEADER_STEP_ONE_SUB_TITLE: 'Escolha o(s) programa(s) de fidelidade',
  ADMIN_HOME_HEADER_STEP_TWO_SUB_TITLE: 'Defina a quantidade de milhas que deseja vender:',
  ADMIN_HOME_HEADER_STEP_THREE_SUB_TITLE: 'Dados do pagamento',
  ADMIN_HOME_HEADER_STEP_FOUR_SUB_TITLE: 'Dados do programa',
  ADMIN_HOME_HEADER_STEP_THREE_DESCRIPTION:
    'O pagamento será efetuado somente a conta vinculada ao titular do programa de fidelidade',

  ADMIN_STEP_STEP_ONE_TITLE_NATIONAL: 'Cias Nacionais',
  ADMIN_STEP_STEP_ONE_TITLE_INTERNATIONAL: 'Cias Internacionais',
  ADMIN_STEP_LABEL_NUMBER_TEXT: 'mil milhas',
  ADMIN_STEP_NEXT: 'Próximo',
  ADMIM_STEP_BACK: 'Anterior',
  ADMIN_STEP_FORM_LOYALTY_PROGRAM_INPUT_LABEL_LOGIN: 'Login',
  ADMIN_STEP_FORM_LOYALTY_PROGRAM_INPUT_LABEL_PASSWORD: 'Senha',

  ADMIN_STEP_STEP_FIVE_TITLE: 'Seu cadastro de venda foi concluído e a sua cotação está em análise!',
  ADMIM_STEP_BACK_HOME: 'Voltar para home',

  FILE_SELECT: 'Selecionar',
  FILE_REMOVE: 'Remover',
  FILE_VIEW: 'Visualizar',

  HOME_BLOG_POSTS_LOAD_MESSAGE: 'Carregando posts...',
  BLOG_POSTS_LOAD_MESSAGE: 'Carregando posts...',
  BLOG_MOST_READ_LOAD_MESSAGE: 'Carregando posts...',
  MILES_AIRLIENES_LOAD_MESSAGE: 'Carregando airlines...',

  ADD_AIRLINE_POST_TITLE: 'Adicionar Post',
  ADD_AIRLINE_POST_TAB_FORM_TITLE: 'Adicionar Post',
  ADD_AIRLINE_POST_TAB_TICKET_TITLE: 'Adicionar conteúdo',
  ADD_AIRLINE_POST_DESCRIPTION: 'Preencha todos os campos',
  ADD_AIRLINE_POST_TAB_TOPIC_TITLE: 'Adicionar tópico inicial',
  ADD_AIRLINE_POST_TAB_ARTICLE_TITLE: 'Adicionar tópico final',

  ADD_AIRLINE_POST_INPUT_LABEL_URL: 'URL',
  ADD_AIRLINE_POST_INPUT_PLACEHOLDER_URL: 'Ex: vender-milhas-smiles',

  ADD_TOPIC_AIRLINE_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_TOPIC_AIRLINE_ADD_EDIT_SEND: 'Enviar',
  ADD_TOPIC_AIRLINE_TABLE_HEADER_TITLE: 'Title',
  ADD_TOPIC_AIRLINE_TABLE_HEADER_OPTIONS: 'Opções',
  ADD_TOPIC_AIRLINE_ADD_EDIT_INPUT_LABEL_TITLE: 'Title',
  ADD_TOPIC_AIRLINE_TABLE_HEADER_DESCRIPTION: 'Descrição',
  ADD_TOPIC_AIRLINE_ADD_EDIT_TITLE_ADD: 'Adicionar Tópico',
  ADD_TOPIC_AIRLINE_ADD_EDIT_INPUT_LABEL_DESCRIPTION: 'Descrição',
  ADD_TOPIC_AIRLINE_TABLE_FILTER: 'Pesquisar por título...',

  ADD_TICKET_AIRLINE_ADD_EDIT_TITLE_ADD: 'Adicionar Ticket',
  ADD_TICKET_AIRLINE_ADD_EDIT_TITLE_EDIT: 'Editar Ticket',
  ADD_TICKET_AIRLINE_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_TICKET_AIRLINE_ADD_EDIT_SEND: 'Enviar',
  ADD_TICKET_AIRLINE_TABLE_HEADER_TITLE: 'Title',
  ADD_TICKET_AIRLINE_TABLE_HEADER_DESCRIPTION: 'Descrição',
  ADD_TICKET_AIRLINE_TABLE_HEADER_OPTIONS: 'Opções',
  ADD_TICKET_AIRLINE_ADD_EDIT_INPUT_LABEL_TITLE: 'Title',
  ADD_TICKET_AIRLINE_ADD_EDIT_INPUT_LABEL_DESCRIPTION: 'Descrição',
  ADD_TICKET_AIRLINE_TABLE_FILTER: 'Pesquisar por título...',

  ADD_POST_AIRLINE_SEND: 'Enviar Post',
  SELL_MILES_FLEX_LOAD_MESSAGE: 'Carrerando cia(s)',

  COMMENT_SHARE_MOBILE: 'Compartilhe',

  MANAGER_SCREEN_TAB_LIST: 'Lista de Administradores',
  MANAGER_SCREEN_TAB_ADD: 'Adicionar Administrador',

  MANAGER_ADD_EDIT_MANAGER: 'Enviar',
  MANAGER_ADD_EDIT_CARD_TITLE: 'Adminstrador',
  MANAGER_ADD_EDIT_CARD_DESCRIPTION: 'Adicionar Adminstrador',

  MANAGER_LIST_CARD_TITLE: 'Adminstradores',
  MANAGER_LIST_CARD_DESCRIPTION: 'Lista de Adminstradores',
  MANAGER_EDIT: 'Editar Adminstrador',
  MANAGER_LIST_TABLE_HEADER_NAME: 'Nome',
  MANAGER_LIST_TABLE_HEADER_EMAIL: 'Email',
  MANAGER_LIST_TABLE_HEADER_PHONE: 'Telefone',
  MANAGER_LIST_TABLE_HEADER_OPTION: 'Opções',

  EDITOR_LIST_CARD_TITLE: 'Editores',
  EDITOR_LIST_CARD_DESCRIPTION: 'Lista de editores',
  EDITOR_LIST_TABLE_HEADER_NAME: 'Nome',
  EDITOR_LIST_TABLE_HEADER_EMAIL: 'Email',
  EDITOR_LIST_TABLE_HEADER_PHONE: 'Telefone',
  EDITOR_LIST_TABLE_HEADER_OPTION: 'Opções',

  EDITOR_EDIT_TITLE: 'Editar Editor',
  EDITOR_ADD_EDIT_SEND: 'Enviar',

  EDIT_NEWS_TITLE: 'Editar Noticia',

  LIST_NEWS_TABLE_HEADER_TITLE: 'Título',
  LIST_NEWS_TABLE_HEADER_DESCRIPTION: 'Descrição',
  LIST_NEWS_TABLE_HEADER_OPTION: 'Opções',
  LIST_NEWS_CARD_TITLE: 'News',
  LIST_NEWS_CARD_DESCRIPTION: 'Lista de Notícias',
  LIST_NEWS_TABLE_FILTER: 'Pesquisar por título',

  LIST_NEWS_LETTERS_TABLE_HEADER_NAME: 'Nome',
  LIST_NEWS_LETTERS_TABLE_HEADER_EMAIL: 'Email',
  LIST_NEWS_LETTERS_TABLE_HEADER_PHONE: 'Telefone',
  LIST_NEWS_LETTERS_TABLE_HEADER_OPTION: 'Opções',
  LIST_NEWS_LETTERS_CARD_TITLE: 'NewsLetters',
  LIST_NEWS_LETTERS_CARD_DESCRIPTION: 'Boletins informativos',
  LIST_NEWS_LETTERS_TABLE_FILTER: 'Pesquisar pelo nome...',

  ADD_NEWS_CARD_TITLE_ADD: 'Notícias',
  ADD_NEWS_CARD_TITLE_DESCRIPTION: 'Lista de Notícias',
  ADD_NEWS_INPUT_LABEL_TITLE: 'Titulo',
  ADD_NEWS_INPUT_LABEL_DESCRIPTION: 'Descrição',

  QUOTATION_TABLE_HEADER_ORIGIN: 'Origem',
  QUOTATION_TABLE_HEADER_DATA: 'Data',
  QUOTATION_TABLE_HEADER_DONE: 'Realizado',
  QUOTATION_TABLE_HEADER_STATUS: "Status",
  QUOTATION_TABLE_HEADER_EXPIRED: 'Expirado',
  QUOTATION_TABLE_HEADER_USER: 'Usuário',
  QUOTATION_TABLE_HEADER_EMAIL: 'Email',
  QUOTATION_TABLE_HEADER_VALUE: 'Value',
  QUOTATION_TABLE_HEADER_OPTION: 'Opções',
  QUOTATION_TABLE_HEADER_WHATSAPP: 'Whatsapp',
  QUOTATION_CARD_TITLE: 'Quotações',
  QUOTATION_CARD_DESCRIPTION: 'Lista de Quotações',
  QUOTATION_TABLE_FILTER: 'Pesquisar pelo usuário...',

  VIEW_QUOTATION_TABLE_HEADER_NAME: 'Nome',
  VIEW_QUOTATION_TABLE_HEADER_RECEIPT: 'Tipo de pagamento',
  VIEW_QUOTATION_TABLE_HEADER_MILES: 'Milhas',
  VIEW_QUOTATION_TABLE_HEADER_VALUE: 'Valor',
  VIEW_QUOTATION_TABLE_HEADER_OPTION: 'Opções',
  VIEW_QUOTATION_MODAL_TITLE: 'Exibir quotação',
  VIEW_QUOTATION_MODAL_CANCEL: 'Cancelar',
  VIEW_QUOTATION_TABLE_FILTER: 'Pesquisar pela compania...',

  LIST_TAGS_CARD_TITLE: 'Tags',
  LIST_TAGS_CARD_DESCRIPTION: 'Lista de tags',
  LIST_TAGS_TABLE_HEADER_NOME: 'Nome',
  LIST_TAGS_TABLE_HEADER_COLOR: 'Color',
  LIST_TAGS_TABLE_HEADER_OPTION: 'Opções',
  LIST_TAGS_TABLE_FILTER: 'Pesquisar pelo nome...',
  EDIT_TAG_TITLE: 'Editar Tag',

  ADD_TAG_INPUT_LABEL_TITLE: 'Titulo',
  ADD_TAG_INPUT_LABEL_COLOR: 'Cor',
  ADD_TAG_CARD_TITLE_ADD: 'Adicionar Tag',
  ADD_TAG_CARD_TITLE_EDIT: 'Tags',
  ADD_TAG_CARD_TITLE_DESCRIPTION: 'Preencha todos os campos',
  ADD_TAG_SEND: 'Enviar',

  LIST_TESTIMONY_TABLE_HEADER_NOME: 'Nome',
  LIST_TESTIMONY_TABLE_HEADER_REGION: 'Região',
  LIST_TESTIMONY_TABLE_HEADER_DESCRIPTION: 'Descrição',
  LIST_TESTIMONY_TABLE_HEADER_OPTION: 'Opções',
  LIST_TESTIMONY_CARD_TITLE: 'Titulo',
  LIST_TESTIMONY_CARD_DESCRIPTION: 'Descrição',
  LIST_TESTIMONY_TABLE_FILTER: 'Pesquisar pelo nome...',

  EDIT_TESTIMONY_TITLE: 'Editar Depoimento',

  ADD_TESTIMONY_CARD_TITLE_ADD: 'Adicionar depoimento',
  ADD_TESTIMONY_CARD_TITLE_EDIT: 'Editar depoimento',
  ADD_TESTIMONY_CARD_TITLE_DESCRIPTION: 'Preencha todos os campos',
  ADD_TESTIMONY_SEND: 'Enviar',

  ADD_TESTIMONY_INPUT_LABEL_NAME: 'Nome',
  ADD_TESTIMONY_INPUT_LABEL_REGION: 'Região',
  ADD_TESTIMONY_INPUT_LABEL_TEXT: 'Mensagem',

  LIST_AIRLINE_TABLE_HEADER_NAME: 'Nome',
  LIST_AIRLINE_TABLE_HEADER_BEGIN: 'Valor Ininial',
  LIST_AIRLINE_TABLE_HEADER_END: 'Valor Final',
  LIST_AIRLINE_TABLE_HEADER_OPTION: 'Opções',

  LIST_AIRLINE_CARD_TITLE: 'Lista',
  LIST_AIRLINE_CARD_DESCRIPTION: 'Companhias Aéreas',
  LIST_AIRLINE_TABLE_FILTER: 'Pesquisar pelo nome...',

  EDIT_AIRLINE_TITLE: 'Editar Airline',

  ADD_TOPIC_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_TOPIC_ADD_EDIT_SEND: 'Enviar Topico',
  ADD_TOPIC_ADD_EDIT_TITLE_ADD: 'Adicionar Topico',
  ADD_TOPIC_ADD_EDIT_TITLE_EDIT: 'Editar Topico',

  ADD_AIRLINE_CARD_TITLE_ADD: 'Adicionar Companhia Aérea',
  ADD_AIRLINE_CARD_TITLE_EDIT: "Editar Companhia Aérea",
  ADD_AIRLINE_CARD_TITLE_DESCRIPTION: 'Companhia Aérea',

  ADD_AIRLINE_ADD_RANGE_TABLE_HEADER_BEGIN: 'Intervalo Inicial',
  ADD_AIRLINE_ADD_RANGE_TABLE_HEADER_END: 'Intervalo Final',
  ADD_AIRLINE_ADD_RANGE_TABLE_HEADER_VALUE: 'Range',
  ADD_AIRLINE_ADD_RANGE_TABLE_HEADER_OPTIONS: 'Opções',
  ADD_AIRLINE_ADD_RANGE_TABLE_FILTER: 'Pesquisar pelo valor...',

  ADD_ARTICLE_RANGE_ADD_EDIT_INPUT_LABEL_BEGIN: 'Intervalo Inicial',
  ADD_ARTICLE_RANGE_ADD_EDIT_INPUT_LABEL_END: 'Intervalo Final',
  ADD_ARTICLE_RANGE_ADD_EDIT_INPUT_LABEL_VALUE: 'Range',
  ADD_ARTICLE_RANGE_ADD_EDIT_TITLE_ADD: 'Adicionar Range',
  ADD_ARTICLE_RANGE_ADD_EDIT_TITLE_EDIT: 'Editar Range',
  ADD_ARTICLE_RANGE_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_ARTICLE_RANGE_ADD_EDIT_SEND: 'Enviar',

  ADD_AIRLINE_ADD_RULE_TABLE_HEADER_BEGIN: 'Intervalo Inicial',
  ADD_AIRLINE_ADD_RULE_TABLE_HEADER_END: 'Intervalo Final',
  ADD_AIRLINE_ADD_RULE_TABLE_HEADER_QUOTATION: 'Valor da cotação',
  ADD_AIRLINE_ADD_RULE_TABLE_HEADER_OPTIONS: 'Opções',
  ADD_AIRLINE_ADD_RULE_TABLE_FILTER: 'Pesquisar pela quotação...',

  ADD_ARTICLE_RULE_ADD_EDIT_INPUT_LABEL_BEGIN: 'Intervalo Inicial',
  ADD_ARTICLE_RULE_ADD_EDIT_INPUT_LABEL_END: 'Intervalo Final',
  ADD_ARTICLE_RULE_ADD_EDIT_INPUT_LABEL_QUOTATION: 'Valor da cotação',
  ADD_ARTICLE_RULE_ADD_EDIT_TITLE_ADD: 'Adiconar Regra',
  ADD_ARTICLE_RULE_ADD_EDIT_TITLE_EDIT: 'Editar Regra',
  ADD_ARTICLE_RULE_ADD_EDIT_CANCEL: 'Cancelar',
  ADD_ARTICLE_RULE_ADD_EDIT_SEND: 'Enviar',

  ADD_AIRLINE_SEND: 'Enviar Cadastro',
  ADD_AIRLINE_FORM_LABEL_NAME: 'Nome',
  ADD_AIRLINE_FORM_LABEL_LIMIT_MINIMUM: 'Limite Minimo de Milhas',
  ADD_AIRLINE_FORM_LABEL_LIMIT_MAXIMUM: 'Limite Maximo de Milhas',
  ADD_AIRLINE_FORM_LABEL_PERCENT: 'Pagamento programado',

  ADD_AIRLINE_ADD_RULE_TITLE: 'Adicionar Regras',
  ADD_AIRLINE_ADD_RANGE_TITLE: 'Adicionar Range',

  ADD_AIRLINE_TAB_FORM_TITLE: 'Adicionar Airline',
  ADD_AIRLINE_TAB_RANGE_TITLE: 'Adicionar Range',
  ADD_AIRLINE_TAB_RULE_TITLE: 'Adicionar Rule',
  ADD_AIRLINE_TAB_POST_TITLE: 'Adicionar Post',

  ADD_AIRLINE_POST_INPUT_LABEL_TITLE: 'Title',
  ADD_AIRLINE_POST_INPUT_LABEL_DESCRIPTION: 'Descrição',
  ADD_AIRLINE_POST_ADD_ARTICLE_TITLE: 'Adicionar Artigo',

  ADD_AIRLINE_FORM_LABEL_TYPE_NATIAONAL: 'Cia nacional',
  ADD_AIRLINE_FORM_LABEL_TYPE_INTERNATIONAL: 'Cia Internacional',

  WHATSAPP_CARD_TITLE: "Fale com a gente pelo Whatsapp",
  WHATSAPP_CARD_TEXT_ONE: "Vender Milhas",
  WHATSAPP_CARD_TEXT_TWO: "Comprar passagem aérea",

  EXTRACT_DATA_CSV: "Extrair dados",

  ADD_QUOTATION_TITLE_EDIT: "Enviar cotação manual",
  ADD_QUOTATION_TITLE_DESCRIPTION: "Cotação manual",
  ADD_QUOTATION_SEND: "Enviar",

  QUOTATION_AIRLINE_ADD_EDIT_TITLE_ADD: "Adicionar companhia aérea",

  ADD_QUOTATION_INPUT_LABEL_USER_NAME: "Nome",
  ADD_QUOTATION_INPUT_LABEL_USER_EMAIL: "Email",
  ADD_QUOTATION_INPUT_LABEL_USER_WHATSAPP: "Whatsapp",
  ADD_AIRLINE_TABLE_HEADER_AIRLINE: "Programa",
  ADD_AIRLINE_TABLE_HEADER_AMOUNT: "Quantidade",
  ADD_AIRLINE_TABLE_HEADER_VALUE: "Valor",
  ADD_AIRLINE_TABLE_HEADER_PAYMENT: "Tipo de pagamento",
  ADD_AIRLINE_TABLE_HEADER_OPTIONS: "Opções",
  ADD_AIRLINE_TABLE_FILTER: "Filtrar pelo programa",

  ADD_QUOTATION_TITLE_ADD: "Adicionar companhia aérea",

  QUOTATION_AIRLINE_ADD_EDIT_CANCEL: "Cancelar",
  QUOTATION_AIRLINE_ADD_EDIT_SEND: "Enviar",


  ADD_QUOTATION_TITLE_APROVED: "Aprovar Quotação",

  QUOTATION_AIRLINE_ADD_EDIT_TITLE_EDIT: "Editar companhia aérea",
  QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_AIRLINE: "Companhia aérea",
  QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_PAYMENT: "Pagamento",
  QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_AMOUNT: "Quantidade",
  QUOTATION_AIRLINE_ADD_EDIT_TITLE_INPUT_LABEL_PRICE: "Valor",

}
