class SellMilesUtils {
  transformData = (airlines = []) => {
    let airline = {}
    if (airlines.length) {
      for (const element of airlines) {
        airline[element._id] = element
      }
    }
    return airline
  }

  countTheFullPrice = ({ miles = [] }) => {
    let price = 0
    miles.forEach((item) => (price += item.price))
    return price
  }

  getRange = ({ element, airline, amount }) => {
    const { range } = airline[element.idAirline] || {}
    const array = Array.isArray(range) ? range : []
    const object = array.find(({ begin, end }) => amount >= begin && amount < end) || {}
    const lastRange = array[array.length - 1] || {}
    const value = object.value || lastRange.value
    return value
  }

  options(airline, element) {
    let options = []
    if (element.idAirline) {
      const { limitMaximum, limitMinimum } = airline[element.idAirline] || {}
      if (limitMaximum && limitMinimum) {
        for (let amount = limitMinimum; amount <= limitMaximum; amount += this.getRange({ airline, element, amount })) {
          options.push(amount)
        }
      }
    }
    return options
  }

  getPrice = ({ rules = [], percent, amount, method }) => {
    let price = ''
    if (amount) {
      rules.sort((a, b) => {
        return a.end < b.end ? -1 : a.end > b.end ? 1 : 0
      })
      for (const rule of rules) {
        if (amount >= rule.begin && amount <= rule.end) {
          if (method === 'inCash') {
            price = amount * rule.quotation
          } else {
            price = amount * rule.quotation + (amount * rule.quotation * percent) / 100
          }
        }
      }
      if (!price) {
        let { quotation } = rules[rules.length - 1] || {}
        if (method === 'inCash') {
          price = amount * quotation
        } else {
          price = amount * quotation + (amount * quotation * percent) / 100
        }
      }
      return Number(Number(price).toFixed(2))
    }
  }

  convertData = (value) => {
    if (value) {
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
  }
}

export default new SellMilesUtils()
