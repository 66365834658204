import React from 'react'
import * as Semantic from 'semantic-ui-react'

//@import compoent
import { dict } from '@UI/Translate'

// Styles
import './styles.scss'

const Select = (props) => {
  const handleChange = (e, { name, value }) => {
    const { array = [], nameValue } = props
    const event = {
      target: {
        name,
        value,
        item: array.find((item) => item[nameValue] === value),
      },
    }
    props.handleChange(event)
  }

  let {
    array,
    name,
    value,
    index,
    translate,
    disabled,
    nameValue,
    iconPosition,
    placeholder = '',
    nameText = nameValue,
    keyValue = nameValue,
  } = props

  const icon = props.onIcon()

  return (
    <Semantic.Select
      fluid
      name={name}
      value={value}
      index={index}
      className="Select"
      disabled={disabled}
      iconPosition={iconPosition}
      icon={icon || undefined}
      placeholder={dict.translate(placeholder)}
      onChange={handleChange}
      options={array.map((element) => ({
        key: element[keyValue],
        value: translate ? dict.translate(element[nameValue]) : element[nameValue],
        text: translate ? dict.translate(element[nameText]) : element[nameText],
      }))}
    />
  )
}

export default Select