import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import config from '@configs'
import MultiStep from '@UI/MultiStep'
import Translate from '@UI/Translate'
import { getDecode } from '@utils/user.utils'

import Address from '@components/Admin/Register/Address'
import Documents from '@components/Admin/Register/Documents'
import BankDetails from '@components/Admin/Register/BankDetails'
import LoyaltyProgram from '@components/Admin/Register/LoyaltyProgram'
import PersonalData from '@components/Admin/Register/PersonalData'
import PermissionConstant from '@constants/permission.contants'

import * as Yup from 'yup'
import { Formik } from 'formik'

const UserProfileScreen = (props) => {
  useEffect(() => {
    let data = getDecode() || {}
    const { permission, user: object } = data
    delete object.password
    setObject(object)
    setPermission(permission)
  }, [])

  const [object, setObject] = useState({})
  const [permission, setPermission] = useState(null)

  const getApi = (permission) => {
    switch (permission) {
      case 'User': {
        return 'users'
      }
      case 'Manager': {
        return 'managers'
      }
      case 'Editor': {
        return 'editor'
      }
      default: {
        return 'users'
      }
    }
  }

  const panel = [{ title: 'PROFILE_USER', icon: 'pe-7s-menu' }]

  const steps = [
    {
      name: 'USER_PROFILE_SCREEN_MULTI_STEP_PERSONAL_DATA',
      component: (props) => <PersonalData permission={permission} {...props} />,
    },
    {
      name: 'USER_PROFILE_SCREEN_MULTI_STEP_ADDRESS',
      component: (props) => <Address {...props} />,
    },
    {
      name: 'USER_PROFILE_SCREEN_MULTI_STEP_BACK_DETAILS',
      component: (props) => <BankDetails {...props} />,
    },
    {
      name: 'USER_PROFILE_SCREEN_MULTI_STEP_LOYALTY_PROGRAM',
      component: (props) => <LoyaltyProgram profile {...props} />,
    },
    {
      name: 'USER_PROFILE_SCREEN_MULTI_STEP_DOCUMENTS',
      component: (props) => <Documents {...props} />,
    },
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    cpf: Yup.string(),
    email: Yup.string().email('USER_ALREADY_REGISTERED'),
    birthdate: Yup.string(),
    phone: Yup.string(),
    address: Yup.object().shape({
      cep: Yup.string(),
      uf: Yup.string().nullable(),
      city: Yup.string().nullable(),
      district: Yup.string(),
      street: Yup.string(),
      number: Yup.string(),
      complement: Yup.string(),
    }),
    bank: Yup.object().shape({
      agency: Yup.string('REQUIRED'),
      account: Yup.string('REQUIRED'),
      holder: Yup.string('REQUIRED'),
      name: Yup.string('REQUIRED').nullable(),
      type: Yup.string('REQUIRED').nullable(),
    }),
    fidelity: Yup.array().of(
      Yup.object().shape({
        urlPhoto: Yup.string(),
        name: Yup.string(),
        limitMinimum: Yup.number(),
        login: Yup.string(),
        password: Yup.string(),
      })
    ),
    password: Yup.string().min(4, 'TO_SHORT').max(70, 'TOO_LONG'),
    urlHome: Yup.string(),
    urlDocument: Yup.string(),
  })

  const formik = {
    enableReinitialize: true,
    initialValues: { ...object },
    validationSchema: validationSchema,
  }

  const onSubmit = ({ data, submit }) => {
    const [params] = data
    submit({ params })
  }

  const getComponentByPermission = (formikProps) => {
    const { handleSubmit } = formikProps
    const { MANAGER, EDITOR } = PermissionConstant
    if (permission === MANAGER || permission === EDITOR) {
      return (
        <>
          <PersonalData formik={formikProps} />
          <div className="form-footer">
            <Button onClick={handleSubmit}>
              <Translate>USER_PROFILE_SCREEN_FORM_SEND</Translate>
            </Button>
          </div>
        </>
      )
    } else {
      return <MultiStep steps={steps} formik={formikProps} submit={handleSubmit} />
    }
  }

  const onSuccess = ({ data = {} }) => {
    let object = getDecode() || {}
    object.user = data
    localStorage.setItem(config.ObjectSession, JSON.stringify(object))
  }

  return (
    <div className="content">
      <div className="UserProfileScreen">
        <Tabs tabs={panel}>
          <Axios method="put" params={object} others={object._id} onSuccess={onSuccess} api={getApi(permission)}>
            {({ submit }) => (
              <Formik {...formik} onSubmit={(...data) => onSubmit({ data, submit })}>
                {(formikProps) => getComponentByPermission(formikProps)}
              </Formik>
            )}
          </Axios>
        </Tabs>
      </div>
    </div>
  )
}

export default UserProfileScreen
