import React from 'react'
import Translate from '@UI/Translate'

const NavPanel = (props) => {
  const { tabs = [], custom, panelOpen, isActive } = props
  return (
    <div className="NavPanel ui attached tabular nav-tabs" tabs>
      <div className="nav-tabs-navigation">
        {tabs.map((nav, index) => (
          <div
            key={index}
            className={`nav-item nav__custom  ${nav.class || ''} ${isActive === index ? 'active' : ''}`}
            onClick={() => panelOpen(Number(index))}
          >
            <span className="nav-link link">
              <i className={nav.icon}></i>
              <Translate>{nav.title}</Translate>
            </span>
          </div>
        ))}
      </div>
      {custom && typeof custom === 'function' && (() => <div className="nav__custom"> {custom(isActive)}</div>)}
    </div>
  )
}

export default NavPanel
