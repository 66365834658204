import React from 'react'

import InputRange from "react-input-range";

// Styles
import './styles.scss'

const Ranger = (props) => {
  const { minValue, maxValue, options = [], value } = props

  const handleChange = (item) => {
    const { index, name } = props
    const value = options.find(option => option >= item)
    const event = {
      target: {
        name,
        value,
        index
      }
    }
    props.handleChange(event)
  }

  return (
    <div className="Range">
      <InputRange
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        onChange={handleChange}
        formatLabel={value => `${value / 1000} Mil`}
      />
    </div>
  )
}

export default Ranger
