import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'

// @import components
import Input from '@UI/Input'
import Translate from '@UI/Translate'

// @import styles
import './styles.scss'

const ModalEdit = (props) => {
  const [hash, setHash] = useState('')

  const onChange = ({ target: { value } }) => {
    setHash(value)
  }

  const success = () => {
    const { submit } = props
    submit(hash)
    setHash('')
  }

  const { open, close } = props
  return (
    <Modal className="ModalEdit" open={open} onClose={close}>
      <Modal.Header>
        <Translate>USER_MODAL_EDIT_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="center-center">
            <Translate>USER_MODAL_EDIT_SUB_TITLE</Translate>
          </p>
          <div>
            <Input required name="hash" value={hash} label="USER_MODAL_EDIT_INPUT_HASH" onChange={onChange} />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className="form-footer modal-footer">
          <Button onClick={close}>
            <Translate>USER_MODAL_EDIT_CANCEL</Translate>
          </Button>
          <Button onClick={success}>
            <Translate>USER_MODAL_EDIT_SEND</Translate>
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalEdit
