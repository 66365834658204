import React from 'react'
import Translate from '@UI/Translate'

const Footer = () => {
  return (
    <footer className="footer">
      <p className="copyright pull-right">&copy; {new Date().getFullYear()} &nbsp;
      <Translate>MAIS_MILHAS</Translate></p>
    </footer>
  )
}

export default Footer
