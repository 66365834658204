import React from 'react';

import './styles.scss';

const Error = () => {
  return (
    <div className='Error'>
      <div className="wrapper">
        <h1>Hmm.</h1>
        <p>Parece que aconteceu algum problema ao tentar acessar a página, volte ao site e tente logar novamente.</p>
        <div className="buttons">
          <a href={process.env.REACT_APP_PORTAL_URL}>Voltar ao site do mais milhas</a>
        </div>
      </div>
      <div class="space">
        <div class="blackhole"></div>
        <div class="ship"></div>
      </div>
    </div>
  )
}

export default Error;

