import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Axios from '@UI/Axios'
import Input from '@UI/Input'
import Card from '@UI/Card'

import './styles.scss'

const Address = (props) => {
  const { formik = {} } = props
  const { handleBlur, handleChange, values = {}, errors } = formik

  const [object, setObject] = useState({})

  const [citys, setCitys] = useState([])
  const [state, setState] = useState([])

  const [cepLoad, setCepLoad] = useState(false)
  const [cityLoad, setCityLoad] = useState(false)
  const [stateLoad, setStateLoad] = useState(false)

  useEffect(() => {
    const { cep } = Utils.getValue(values, 'address') || {}
    Utils.setValue(object, 'address.cep', cep)
    setObject({ ...object })
  }, [])

  const onSuccessCity = (data) => {
    setCitys(data)
  }

  const onSuccessState = ({ data }) => {
    setState(data)
  }

  const onSuccessCep = (data) => {
    const { Nome, ID } = state.find(({ Sigla }) => Sigla === data.uf) || {}
    const address = {
      ...object.address,
      uf: Nome,
      city: data.localidade,
      district: data.bairro,
      street: data.logradouro,
      complement: data.complement,
    }
    setCepLoad(false)
    object.address.uf = ID
    Utils.setValue(formik, 'values.address', address)
    setObject({ ...object })
  }

  const onChange = (event) => {
    const {
      target: { item, name, value = '' },
    } = event
    if (!value) {
      handleChange(event)
    }
    if (name === 'address.cep') {
      let cep = value.replace(/[^\d]+/g, '')
      if (cep.length === 8) {
        setCepLoad(value)
      }
      Utils.setValue(object, name, value)
    } else if (name === 'address.uf') {
      Utils.setValue(object, name, item ? item.ID : 30)
      Utils.setValue(formik, 'values.address.city', null)
      handleChange(event)
    }
    setObject({ ...object })
  }

  return (
    <div className="Address">
      <Card title="ADDRESS_TITLE" category="ADDRESS_DESCRIPTION">
        <Grid stackable columns="equal">
          <Grid.Row className="pd-0">
            <Grid.Column width={5}>
              <Axios
                api="cep"
                load={false}
                run={cepLoad}
                onSuccess={onSuccessCep}
                others={`${Utils.getValue(object, 'address.cep')}/json`}
              >
                {() => (
                  <Input
                    load={cepLoad}
                    name="address.cep"
                    mask={'99999-999'}
                    onBlur={handleBlur}
                    onChange={onChange}
                    label="ADDRESS_INPUT_LABEL_CEP"
                    error={Utils.getValue(errors, 'address.cep')}
                    helperText={Utils.getValue(errors, 'address.cep')}
                    value={Utils.getValue(values, 'address.cep')}
                    {...props}
                  />
                )}
              </Axios>
            </Grid.Column>
            <Grid.Column>
              <Input
                name="address.street"
                onBlur={handleBlur}
                onChange={handleChange}
                label="ADDRESS_INPUT_LABEL_STREET"
                disabled={!Utils.getValue(values, 'address.cep')}
                value={Utils.getValue(values, 'address.street')}
                error={Utils.getValue(errors, 'address.street')}
                helperText={Utils.getValue(errors, 'address.street')}
                {...props}
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Input
                name="address.number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="ADDRESS_INPUT_LABEL_NUMBER"
                disabled={!Utils.getValue(values, 'address.cep')}
                value={Utils.getValue(values, 'address.number')}
                error={Utils.getValue(errors, 'address.number')}
                helperText={Utils.getValue(errors, 'address.number')}
                {...props}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pd-0">
            <Grid.Column width="4">
              <Input
                name="address.district"
                onBlur={handleBlur}
                onChange={handleChange}
                label="ADDRESS_INPUT_LABEL_DISTRICT"
                value={Utils.getValue(values, 'address.district')}
                disabled={!Utils.getValue(values, 'address.cep')}
                error={Utils.getValue(errors, 'address.district')}
                helperText={Utils.getValue(errors, 'address.district')}
                {...props}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                onBlur={handleBlur}
                onChange={handleChange}
                name="address.complement"
                label="ADDRESS_INPUT_LABEL_COMPLEMENT"
                disabled={!Utils.getValue(values, 'address.cep')}
                value={Utils.getValue(values, 'address.complement')}
                error={Utils.getValue(errors, 'address.complement')}
                helperText={Utils.getValue(errors, 'address.complement')}
                {...props}
              />
            </Grid.Column>
            <Grid.Column>
              <Axios run api="state" load={false} onSuccess={onSuccessState} onRequest={() => setStateLoad(!stateLoad)}>
                <Input
                  array={state}
                  load={stateLoad}
                  type="autocomplete"
                  nameValue="Nome"
                  name="address.uf"
                  onBlur={handleBlur}
                  onChange={onChange}
                  label="ADDRESS_INPUT_LABEL_UF"
                  value={Utils.getValue(values, 'address.uf')}
                  error={Utils.getValue(errors, 'address.uf')}
                  disabled={!Utils.getValue(values, 'address.cep')}
                  helperText={Utils.getValue(errors, 'address.uf')}
                  {...props}
                />
              </Axios>
            </Grid.Column>
            <Grid.Column>
              <Axios
                load={false}
                api="city"
                onSuccess={onSuccessCity}
                onRequest={() => setCityLoad(!cityLoad)}
                run={Utils.getValue(object, 'address.uf')}
                others={`${Utils.getValue(object, 'address.uf')}`}
              >
                {() => (
                  <Input
                    {...props}
                    array={citys}
                    load={cityLoad}
                    nameValue="Nome"
                    type="autocomplete"
                    onBlur={handleBlur}
                    name="address.city"
                    onChange={handleChange}
                    label="ADDRESS_INPUT_LABEL_CITY"
                    value={Utils.getValue(values, 'address.city')}
                    error={Utils.getValue(errors, 'address.city')}
                    helperText={Utils.getValue(errors, 'address.city')}
                    disabled={!Utils.getValue(values, 'address.cep')}
                  />
                )}
              </Axios>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </div>
  )
}

export default Address
