import configs from '@configs'

const goToSite = () => {
  window.location.href = process.env.REACT_APP_PORTAL_URL;
};

const getDecode = () => {
  return JSON.parse(localStorage.getItem(configs.ObjectSession)) || {}
}

const getUser = () => {
  const { user } = getDecode();
  return user
}

const setUser = (user) => {
  if (user) {
    const data = getDecode();
    data.user = user;
    localStorage.setItem(configs.ObjectSession, JSON.stringify(data))
  }
}

const getPermission = () => {
  const { permission } = getDecode();
  return permission
}

export { getUser, setUser, getPermission, getDecode, goToSite }
