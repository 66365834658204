import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import Utils from '@utils'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import Input from '@UI/Input'
import SimpleTab from '@UI/SimpleTab'

import AddTopic from './components/AddTopic'
import AddTicket from './components/AddTicket'
import AddArticle from './components/AddArticle'

import Translate from '@UI/Translate'

import * as Yup from 'yup'
import { Formik } from 'formik'

import './styles.scss'

const AddAirline = (props) => {
  const [post, setPost] = useState({})

  useEffect(() => {
    const post = {
      ...props.post,
      airline: props.airline,
    }
    setPost(post)
  }, [props])

  const formComponent = ({ formik }) => {
    const { handleBlur, handleChange, values = {}, errors } = formik
    return (
      <div className="form">
        <Input
          name="url"
          onBlur={handleBlur}
          onChange={handleChange}
          label="ADD_AIRLINE_POST_INPUT_LABEL_URL"
          value={Utils.getValue(values, 'url')}
          error={Utils.getValue(errors, 'url')}
          helperText={Utils.getValue(errors, 'url')}
        />
        <Input
          name="title"
          onBlur={handleBlur}
          onChange={handleChange}
          label="ADD_AIRLINE_POST_INPUT_LABEL_TITLE"
          value={Utils.getValue(values, 'title')}
          error={Utils.getValue(errors, 'title')}
          helperText={Utils.getValue(errors, 'title')}
        />
        <Input
          type="tinymce"
          name="description"
          onBlur={handleBlur}
          onChange={handleChange}
          label="ADD_AIRLINE_POST_INPUT_LABEL_DESCRIPTION"
          value={Utils.getValue(values, 'description')}
          error={Utils.getValue(errors, 'description')}
          helperText={Utils.getValue(errors, 'description')}
        />
      </div>
    )
  }

  const topicComponent = ({ formik }) => {
    const { values } = formik;
    return <AddTopic post={values} formik={formik} />
  }

  const ticketComponent = ({ formik }) => {
    const { values } = formik;
    return <AddTicket post={values} formik={formik} />
  }

  const articleComponent = ({ formik }) => {
    const { values } = formik;
    return <AddArticle post={values} formik={formik} />
  }

  const tabs = [
    {
      title: 'ADD_AIRLINE_POST_TAB_FORM_TITLE',
      component: (props) => formComponent(props),
    },
    {
      title: 'ADD_AIRLINE_POST_TAB_TOPIC_TITLE',
      component: (props) => topicComponent(props),
    },
    {
      title: 'ADD_AIRLINE_POST_TAB_TICKET_TITLE',
      component: (props) => ticketComponent(props),
    },
    {
      title: 'ADD_AIRLINE_POST_TAB_ARTICLE_TITLE',
      component: (props) => articleComponent(props),
    },
  ]

  let type = props.post && Object.keys(props.post).length ? 'EDIT' : 'ADD'

  const api = {}
  api.params = post
  if (type === 'ADD') {
    api.method = 'post'
  } else {
    api.method = 'put'
    api.others = props.post._id
  }

  const formik = {
    enableReinitialize: true,
    initialValues: { ...post },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('REQUIRED'),
      description: Yup.string().required('REQUIRED')
    }),
  }

  const onSuccess = () => {
    props.history.push('/admin/arline');
  }

  const onSubmit = ({ values: params, resetForm, submit }) => {
    submit({ params })
    resetForm()
  }

  return (
    <Axios {...api} api="postairline" onSuccess={onSuccess}>
      {({ submit }) => (
        <Formik {...formik} onSubmit={(values, data) => onSubmit({ values, submit, ...data })}>
          {({ handleSubmit, isValid, ...form }) => (
            <div className="AddPostAirline">
              <Card title="ADD_AIRLINE_POST_TITLE" category="ADD_AIRLINE_POST_DESCRIPTION">
                <SimpleTab formik={form} tabs={tabs} />
              </Card>
              <div className="form-footer">
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_POST_AIRLINE_SEND</Translate>
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Axios>
  )
}

export default AddAirline
