import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

const Form = (props) => {
  const { formik } = props

  const { handleBlur, handleChange, values = {}, errors } = formik

  const setSizeImage = ({ width, height }) => {
    values.image_width = width
    values.image_height = height
  }
  return (
    <Grid stretched stackable columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Input
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                label="ADD_NEWS_INPUT_LABEL_TITLE"
                value={Utils.getValue(values, 'title')}
                error={Utils.getValue(errors, 'title')}
                helperText={Utils.getValue(errors, 'title')}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Input
              type="tinymce"
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              label="ADD_NEWS_INPUT_LABEL_DESCRIPTION"
              value={Utils.getValue(values, 'description')}
              error={Utils.getValue(errors, 'description')}
              helperText={Utils.getValue(errors, 'description')}
            />
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={5}>
          <Input
            type="cropper"
            aspectRatio={6 / 4}
            name="urlPhoto"
            onBlur={handleBlur}
            onChange={handleChange}
            setSizeImage={setSizeImage}
            value={Utils.getValue(values, 'urlPhoto')}
            error={Utils.getValue(errors, 'urlPhoto')}
            helperText={Utils.getValue(errors, 'urlPhoto')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Form
