import { Link } from "react-router-dom";
import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'

import Icon from '@UI/Icon'

import './styles.scss'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  activeRoute(routeName) {
    const { location } = this.props
    return location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  getItemMenu(prop) {
    if (prop.close) {
      return (
        <Menu.Item className='active active-pro close-menu'>
          <div className="nav-link link" onClick={prop.onClick}>
            <i className={prop.icon} />
            <p>{prop.name}</p>
          </div>
        </Menu.Item>
      )
    } else {
      return (
        <Menu.Item className={this.activeRoute(prop.layout + prop.path)}>
          <Link to={prop.layout + prop.path} className="nav-link link">
            <i className={prop.icon} />
            <p>{prop.name}</p>
          </Link>
        </Menu.Item>
      )
    }
  }

  render() {
    const sidebarBackground = {
      backgroundImage: 'url(' + this.props.image + ')',
    }
    return (
      <div id="sidebar" className="Sidebar sidebar" data-color={this.props.color} data-image={this.props.image}>
        {this.props.hasImage ? <div className="sidebar-background" style={sidebarBackground} /> : null}
        <div className="logo">
          <Icon name="maismilhas" />
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            <Menu secondary vertical >
              {this.props.routes.map((prop) => {
                if (!prop.redirect) {
                  return this.getItemMenu(prop)
                }
                return null
              })}
            </Menu>
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
