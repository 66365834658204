import React from 'react'

import Icons from './dictionary'

import './styles.scss'

const Icon = (props) => {
  const { size, name } = props
  const Image = Icons[name]
  if (Image) {
    return (
      <span className="Icon">
        <Image style={size ? { width: size, height: size } : null} />
      </span>
    )
  } else {
    return null
  }
}

export default Icon
