import React from 'react'
import Translate from '@UI/Translate'

// @import Styles
import './styles.scss'

const Card = (props) => {
  return (
    <div className="Card">
      <div className={'card' + (props.plain ? ' card-plain' : '')}>
        <div className={'header' + (props.hCenter ? ' text-center' : '')}>
          <div>
            <h4 className="title">
              <Translate>{props.title}</Translate>
            </h4>
            <p className="category">
              <Translate>{props.category}</Translate>
            </p>
          </div>
          <div>{props.component && props.component()}</div>
        </div>
        <div
          className={
            'content' +
            (props.ctAllIcons ? ' all-icons' : '') +
            (props.ctTableFullWidth ? ' table-full-width' : '') +
            (props.ctTableResponsive ? ' table-responsive' : '') +
            (props.ctTableUpgrade ? ' table-upgrade' : '')
          }
        >
          {props.children || props.content}
        </div>
      </div>
    </div>
  )
}

export default Card
