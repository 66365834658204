import React from 'react'

import Compress from "compressorjs";
import Translate from '@UI/Translate'

// Styles
import './styles.scss'

class File extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameFile: '',
    }
    this.close = this.close.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async handleChange({ target: { files } }) {
    const { name } = this.props
    const file = files[0]
    if (file) {
      const value = await this.compress(file);
      const event = {
        target: {
          name,
          value,
        },
      }
      this.setState({ nameFile: file.name })
      this.props.handleChange(event)
    }
  }

  getBase64ToFile = (file) => {
    return new Promise((resolver) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolver(reader.result)
      }
    })
  }

  compressImage(blob) {
    return new Promise((resolver) => {
      new Compress(blob, {
        quality: 0.5,
        convertSize: 1000,
        success(result) {
          resolver(result)
        },
      })
    })
  }

  compress = async (blob) => {
    const result = await this.compressImage(blob)
    let base64 = await this.getBase64ToFile(result)
    return base64
  }

  close() {
    const { name } = this.props
    const event = {
      target: {
        name,
        value: '',
      },
    }
    this.setState({ nameFile: '' })
    this.props.handleChange(event)
  }

  render() {
    const { nameFile } = this.state
    let { value, placeholder, error, name, readonly, disabled } = this.props
    let url = String(value).indexOf('http') !== -1
    return (
      <div className="File center-center">
        <input
          value={nameFile || value}
          readonly="readonly"
          className={`file ${error ? 'error' : ''}`}
          placeholder={placeholder}
        />
        {!value ? (
          <label class="btn center-center btn-label">
            <Translate>FILE_SELECT</Translate> 
            <input
              type="file"
              value={value}
              name={name}
              class="arquivo"
              accept="image/*,.pdf"
              disabled={disabled || readonly}
              onChange={this.handleChange}
            />
          </label>
        ) : (
          <>
            {!(disabled || readonly) && (
              <label onClick={this.close} class={`btn center-center btn-remove ${!url ? 'btn-label' : ''}`}>
                <Translate>FILE_REMOVE</Translate>
              </label>
            )}
            {url && (
              <a href={value} target="_blank" rel="noopener noreferrer" class="btn center-center btn-label">
                <Translate>FILE_VIEW</Translate>
              </a>
            )}
          </>
        )}
      </div>
    )
  }
}
export default File
