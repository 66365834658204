import 'date-fns'

import moment from 'moment'
import React  from 'react'
import { ptBR } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import './styles.scss'

const DatePicker = props => {

  const handleChange = value => {
    const { name } = props
    const event = {
      target: {
        name,
        value: moment.utc(value).format(),
      },
    }
    props.handleChange(event)
  }

  const { label, value = null } = props

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...props}
        type={null}
        label={label}
        value={value}
        format="dd/mm/yyyy"
        className="DatePicker"
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePicker.defaultProps = {
  error: null,
  helperText: null,
}

export default DatePicker
