import React  from 'react'
import { Modal } from 'semantic-ui-react'

// @import components
import Translate from '@UI/Translate'
import AddAirline from '@components/Admin/Airline/AddAirline'

// @import styles
import './styles.scss'

const EditAirline = (props) => {
  const { open, close, ...rest } = props
  return (
    <Modal open={open} centered={false} size="large" className="EditAirline" onClose={close}>
      <Modal.Header>
        <Translate>EDIT_AIRLINE_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddAirline {...rest} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default EditAirline
