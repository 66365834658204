import React from 'react';
import * as Semantic from 'semantic-ui-react'

import { dict } from '@UI/Translate';

// Styles
import './styles.scss';

class Radio extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const { nameValue, name } = this.props;
    const event = {
      target: {
        name,
        value: nameValue
      }
    }
    this.props.handleChange(event)
  };

  render() {
    let {
      value,
      label,
      error,
      nameValue
    } = this.props;
    return (
      <Semantic.Radio
        className={`Radio ${error ? 'error' : ''}`}
        label={dict.translate(label)}
        checked={value === nameValue}
        onChange={this.handleChange}
      />
    )
  }
}

export default Radio;
