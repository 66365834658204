import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import Axios from '@UI/Axios'
import Card from '@UI/Card'
import SimpleTab from '@UI/SimpleTab'
import Translate from '@UI/Translate'

import AddRule from './components/AddRule'
import AddRange from './components/AddRange'
import FormAirline from './components/FormAirline'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AddAirline = (props) => {
  const { airline = {} } = props

  const [object, setObject] = useState({})

  useEffect(() => {
    setObject(airline)
  }, [])

  const panes = [
    {
      title: 'ADD_AIRLINE_TAB_FORM_TITLE',
      component: (props) => <FormAirline {...props} />,
    },
    {
      title: 'ADD_AIRLINE_TAB_RANGE_TITLE',
      component: (props) => <AddRange {...props} />,
    },
    {
      title: 'ADD_AIRLINE_TAB_RULE_TITLE',
      component: (props) => <AddRule {...props} />,
    },
  ]

  const formik = {
    enableReinitialize: true,
    initialValues: { ...airline },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('REQUIRED'),
      percent: Yup.number().required('REQUIRED'),
      limitMinimum: Yup.number().required('REQUIRED'),
      limitMaximum: Yup.number().required('REQUIRED'),
      type: Yup.string().required('REQUIRED'),
      urlPhoto: Yup.string().required('REQUIRED'),
    }),
  }

  const onSubmit = ({ values, submit }) => {
    submit({ params: values })
    if (props.setAirline) {
      props.setAirline(values)
    }
  }

  const onSuccess = () => {
    props.updateScreen()
    props.close()
  }

  let type = Object.keys(airline).length ? 'EDIT' : 'ADD'

  const api = {}
  let title = 'ADD_AIRLINE_CARD_TITLE_ADD'

  api.params = object
  if (type === 'ADD') {
    api.method = 'post'
  } else {
    api.method = 'put'
    api.others = airline._id
    title = 'ADD_AIRLINE_CARD_TITLE_EDIT'
  }

  return (
    <Axios {...api} api="airlines" onSuccess={onSuccess}>
      {({ submit }) => (
        <Formik {...formik} onSubmit={(values) => onSubmit({ values, submit })}>
          {({ handleSubmit, isValid, ...form }) => (
            <div className="AddAirline">
              <Card title={title} category="ADD_AIRLINE_CARD_TITLE_DESCRIPTION">
                <SimpleTab formik={form} tabs={panes} />
              </Card>
              <div className="form-footer">
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_AIRLINE_SEND</Translate>
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Axios>
  )
}

export default AddAirline
