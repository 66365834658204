import React  from 'react'
import { Modal } from 'semantic-ui-react'

// @import components
import Translate from '@UI/Translate'
import AddPost from '@components/Admin/Posts/AddPost'

// @import styles
import './styles.scss'

const EditPost = (props) => {
  const { open, close, ...rest } = props
  return (
    <Modal open={open} centered={false} size="large" className="EditPost" onClose={close}>
      <Modal.Header>
        <Translate>EDIT_POST_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AddPost {...rest} close={close} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default EditPost
