import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'

import Utils from '@utils'
import configs from '@configs'
import Axios from '@UI/Axios'
import Input from '@UI/InputMaterial'

import * as Yup from 'yup'
import { Formik } from 'formik'

import { ReactComponent as MaisMilhasWhite } from '@images/logo_white.svg'

import './styles.scss'

const Login = ({ match, history }) => {

  useEffect(() => {
    localStorage.clear(configs.TokenSession)
    localStorage.clear(configs.ObjectSession)
    setToken(match.params?.token);
  }, [match.params?.token])

  const schema = {
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('REQUIRED')
        .email(),
      password: Yup.string().required('REQUIRED')
    })
  }

  const onSuccess = success => {
    setToken(Utils.getValue(success, 'data.token'))
  }

  const setToken = token => {
    if (token) {
      const decode = Buffer.from(String(token).split('.')[1], 'base64').toString();
      const response = JSON.parse(decode);
      const timestamp = new Date().getTime() / 1000;
      if (token && response.exp > timestamp) {
        localStorage.setItem(configs.TokenSession, token)
        localStorage.setItem(configs.ObjectSession, decode)
        history.push('/admin/home')
      } else {
        window.location.href = process.env.REACT_APP_PORTAL_URL
      }
    }
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    submit({ params: values })
    // resetForm()
  }

  return (
    <div className="Login">
      <div className="wrapper">
        <div className="column">
          <div></div>
          <span>&copy; 2023 Mais Milhas. Todos direiros reservados</span>
        </div>
        <div className="column">
          <MaisMilhasWhite />
        </div>
      </div>
      <div className="card">
        <div className="card_header">
          <p className="title">
            Login
          </p>
        </div>
        <div className="card_content">
          <Axios api="login" method="post" onSuccess={onSuccess}>
            {({ submit }) => (
              <Formik
                {...schema}
                onSubmit={(values, event) =>
                  onSubmit({ ...event, values, submit })
                }
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <>
                    <div className="form">
                      <Input
                        name="email"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        helperText={errors.email}
                        label="LOGIN_FORM_INPUT_LABEL_USER"
                      />
                      <Input
                        type="password"
                        name="password"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        helperText={errors.password}
                        label="LOGIN_FORM_INPUT_LABEL_PASSWORD"
                      />
                    </div>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Enviar
                    </Button>
                    <a href={process.env.REACT_APP_PORTAL_URL}>
                      Voltar ao site do mais milhas
                    </a>
                  </>
                )}
              </Formik>
            )}
          </Axios>
        </div>
      </div>
    </div>
  )
}

export default Login