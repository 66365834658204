import React, { useEffect, useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import AddEdit from './components/AddEdit'

// @import styles
import './styles.scss'

const AddRule = (props) => {
  const { formik = {} } = props

  const [open, setOpen] = useState(false)
  const [rule, setRule] = useState({})

  const headers = [
    { name: '#' },
    { name: 'ADD_AIRLINE_ADD_RULE_TABLE_HEADER_BEGIN' },
    { name: 'ADD_AIRLINE_ADD_RULE_TABLE_HEADER_END' },
    { name: 'ADD_AIRLINE_ADD_RULE_TABLE_HEADER_QUOTATION' },
    { name: 'ADD_AIRLINE_ADD_RULE_TABLE_HEADER_OPTIONS', class: 'text-center' },
  ]

  const path = 'rules'
  const { values } = formik

  const [list, setList] = useState([])
  const [filter, setFilter] = useState('')

  useEffect(() => {
    const list = Utils.getValue(values, path) || []
    updateList(list) // eslint-disable-next-line
  }, [values])

  const updateList = (list) => {
    setRule({})
    setList([...list])
    Utils.setValue(values, path, list)
  }

  const onAdd = (item) => {
    toggle()
    list.push(item)
    updateList(list)
  }

  const onEdit = (item) => {
    toggle()
    const index = list.indexOf(rule)
    if (index >= 0) {
      list[index] = item
      updateList(list)
    }
  }

  const onDelete = (index) => {
    list.splice(index, 1)
    updateList([...list])
  }

  const onSelectEdit = (rule) => {
    setOpen(true)
    setRule(rule)
  }

  const toggle = () => {
    setOpen(!open)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  let array = Utils.filter(list, 'quotation', filter)

  return (
    <div className="AddRule">
      <Table
        pagination={2}
        onAdd={toggle}
        headers={headers}
        onChange={onChange}
        label={'ADD_AIRLINE_ADD_RULE_TABLE_FILTER'}
      >
        {array.map((element, index) => (
         <tr key={index}>
         <td>{index + 1}</td>
         <td>{element.begin}</td>
         <td>{element.end}</td>
         <td>{element.quotation}</td>
         <td className="td-options text-center">
           <button onClick={() => onSelectEdit(element)} className="button-edit" />
           <button onClick={() => onDelete(index)} className="button-delete" />
         </td>
       </tr>
        ))}
      </Table>
      <AddEdit open={open} close={toggle} onEdit={onEdit} onAdd={onAdd} rule={rule} {...props} />
    </div>
  )
}

export default AddRule
