import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'
import AddAirline from './components/AddAirline'

const Form = (props) => {
  const { formik } = props

  const { handleBlur, handleChange, values = {}, errors } = formik

  return (
    <Grid stretched stackable columns="equal">
      <Grid.Row>
          <Grid.Column>
            <Input
              name="user.name"
              onBlur={handleBlur}
              onChange={handleChange}
              label="ADD_QUOTATION_INPUT_LABEL_USER_NAME"
              value={Utils.getValue(values, 'user.name')}
              error={Utils.getValue(errors, 'user.name')}
              helperText={Utils.getValue(errors, 'user.name')}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              type="email"
              name="user.email"
              onBlur={handleBlur}
              onChange={handleChange}
              label="ADD_QUOTATION_INPUT_LABEL_USER_EMAIL"
              value={Utils.getValue(values, 'user.email')}
              error={Utils.getValue(errors, 'user.email')}
              helperText={Utils.getValue(errors, 'user.email')}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              name="user.whatsapp"
              onBlur={handleBlur}
              onChange={handleChange}
              mask={'(99) 9 9999-9999'}
              value={Utils.getValue(values, 'user.whatsapp')}
              error={Utils.getValue(errors, 'user.whatsapp')}
              helperText={Utils.getValue(errors, 'user.whatsapp')}
              label="ADD_QUOTATION_INPUT_LABEL_USER_WHATSAPP"
            />
          </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <AddAirline {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Form
