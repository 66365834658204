import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

const Form = (props) => {
  const { formik } = props

  const { handleBlur, handleChange, values = {}, errors } = formik

  return (
    <Grid stretched stackable columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Grid.Row>
            <Grid stretched stackable columns="equal">
              <Grid.Column>
                <Input
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="ADD_TESTIMONY_INPUT_LABEL_NAME"
                  value={Utils.getValue(values, 'name')}
                  error={Utils.getValue(errors, 'name')}
                  helperText={Utils.getValue(errors, 'name')}
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  name="region"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="ADD_TESTIMONY_INPUT_LABEL_REGION"
                  value={Utils.getValue(values, 'region')}
                  error={Utils.getValue(errors, 'region')}
                  helperText={Utils.getValue(errors, 'region')}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row>
            <Grid stretched stackable columns="equal">
              <Grid.Column>
                <Input
                  type="textarea"
                  name="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="add_testimony_textarea"
                  label="ADD_TESTIMONY_INPUT_LABEL_TEXT"
                  value={Utils.getValue(values, 'text')}
                  error={Utils.getValue(errors, 'text')}
                  helperText={Utils.getValue(errors, 'text')}
                />
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  aspectRatio={1 / 1}
                  type="cropper"
                  name="urlPhoto"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={Utils.getValue(values, 'urlPhoto')}
                  error={Utils.getValue(errors, 'urlPhoto')}
                  helperText={Utils.getValue(errors, 'urlPhoto')}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Form
