import React from 'react'

import * as Material from '@material-ui/core'

import './styles.scss'

const Checkbox = (props = {}) => {
  const [checked, setChecked] = React.useState(true)
  const handleChange = event => {
    setChecked(event.target.checked)
  }
  return <Material.Checkbox checked={checked} onChange={handleChange} />
}

export default Checkbox
