import React from 'react'
import { Button } from 'semantic-ui-react'

import Card from '@UI/Card'
import Axios from '@UI/Axios'
import Translate from '@UI/Translate'

import Toast from '@utils/toast.utils'

import Form from './components/Form'

import { Formik } from 'formik'
import * as Yup from 'yup'

import './styles.scss'

const AddQuotation = (props) => {

  const { quotation = {} } = props;

  let type = Object.keys(quotation).length ? 'APROVED' : 'ADD';

  const formik = {
    initialValues: { ...quotation },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      price: Yup.number(),
      user: Yup.object().shape({
        whatsapp: Yup.string(),
        name: Yup.string().required('REQUIRED'),
        email: Yup.string().email('EMAIL_INVALID').required('REQUIRED'),
      }),
      miles: Yup.array().of(
        Yup.object().shape({
          airline: Yup.string(),
          idAirline: Yup.string(),
          payment: Yup.string(),
          urlPhoto: Yup.string(),
          amount: Yup.number(),
          price: Yup.number(),
        })
      ),
    }),
  }

  const onSubmit = ({ values, submit, resetForm }) => {
    const { miles = [] } = values;
    values.price = miles.reduce((accumulator, curValue) => {
      return accumulator + curValue.price
    }, 0);
    submit({ params: values });
    resetForm()
  }

  const onSuccess = () => {
    props.updateScreen()
    if (type === 'APROVED') {
      Toast.set('success', "Email enviado com sucesso!")
    } else {
      Toast.set('success', "Cotação realizada com sucesso!")
    }
  }

  let title = 'ADD_QUOTATION_TITLE_';
  const api = { api: 'quotations', method: 'post' }

  if (type === 'APROVED') {
    api.method = 'put';
    api.others = quotation._id;
    api.api = 'quotations.aproved';
  }

  return (
    <Axios {...api} onSuccess={onSuccess} noMessage={false}>
      {({ submit }) => (
        <Formik {...formik} onSubmit={(values, data) => onSubmit({ values, submit, ...data })}>
          {({ handleSubmit, isValid, ...form }) => (
            <div className="AddQuotation">
              <Card title={`${title}${type}`} category="ADD_QUOTATION_TITLE_DESCRIPTION">
                <Form formik={form} {...props} />
              </Card>
              <div className="form-footer">
                <Button disabled={!isValid} onClick={handleSubmit}>
                  <Translate>ADD_QUOTATION_SEND</Translate>
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Axios>
  )
}

export default AddQuotation
