import React from 'react'

import Image from '@UI/Image'
import Translate from '@UI/Translate'

import Button from '@UI/Button'
import checkmark from '@images/checkmark.png'

// Styles
import './styles.scss'

const StepFive = ({ resetStep }) => {
  return (
    <div className="StepFive">
      <div className="step_five_content">
        <div className="step_five_content_image">
          <Image src={checkmark} size="small" alt="IMAGE"/>
        </div>
        <div className="step_five_content_text">
          <h3>
            <Translate>ADMIN_STEP_STEP_FIVE_TITLE</Translate>
          </h3>
        </div>
        <div className="step_five_content_footer">
          <Button onClick={resetStep} basic iconLeft="large_arrow_left">
            ADMIM_STEP_BACK_HOME
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StepFive
