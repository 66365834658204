import React from 'react';
import * as Semantic from 'semantic-ui-react'

// Styles
import './styles.scss';

class Dropdown extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, { value }) {
    const { array = [], nameValue, name } = this.props;
    const event = {
      target: {
        name,
        value,
        item: array.find(item => item[nameValue] === value)
      }
    };
    this.props.handleChange(event);
  }

  render() {
    let {
      value,
      nameValue,
      onClick,
      array = [],
      placeholder = '',
      nameText = nameValue,
      keyValue = nameValue
    } = this.props;
    return (<Semantic.Dropdown
      value={value}
      onClick={onClick}
      options={array.map(element => ({
        key: element[keyValue],
        text: element[nameText],
        value: element[nameValue]
      }))}
      className='Dropdown'
      placeholder={placeholder}
      onChange={this.handleChange}
    >
    </Semantic.Dropdown>
    )
  }
}

export default Dropdown;
