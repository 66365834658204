import React from 'react';
import * as Semantic from 'semantic-ui-react'

import { dict } from '@UI/Translate';

// Styles
import './styles.scss';

class TextArea extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, { name, value }) {
    const event = {
      target: {
        name,
        value
      }
    }
    this.props.handleChange(event)
  };

  render() {
    let {
      name,
      value,
      label,
      placeholder,
    } = this.props;

    return (
      <Semantic.TextArea
        className="TextArea"
        label={dict.translate(label)}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={this.handleChange}
      />
    )
  }
}

export default TextArea;
