import React from 'react'
import { Grid } from 'semantic-ui-react'

import Input from '@UI/Input'
import Card from '@UI/Card'

import './styles.scss'

const PersonalData = (props) => {
  const { formik = {}, editRule = true, loadingEmail, loadingCpf } = props
  const { handleBlur, handleChange, values = {}, errors } = formik

  return (
    <div className="PersonalData">
      <Card {...props} title="PERSONAL_TITLE" category="PERSONAL_DESCRIPTION">
        <Grid stackable columns="equal">
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                name="name"
                value={values.name}
                onBlur={handleBlur}
                error={errors.name}
                onChange={handleChange}
                helperText={errors.name}
                disabled={values._id && editRule}
                label="PERSONAL_DATA_INPUT_LABEL_NAME"
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type="date"
                name="birthdate"
                onBlur={handleBlur}
                onChange={handleChange}
                // dateFormat="DD/MM/YYYY"
                error={errors.birthdate}
                value={values.birthdate}
                helperText={errors.birthdate}
                label="PERSONAL_DATA_INPUT_LABEL_BIRTHDATE"
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type="cpf"
                name="cpf"
                load={loadingCpf}
                onBlur={handleBlur}
                error={errors.cpf}
                onChange={handleChange}
                helperText={errors.cpf}
                value={values.cpf}
                mask={'999.999.999-99'}
                disabled={values._id && editRule}
                label="PERSONAL_DATA_INPUT_LABEL_CPF"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                name="phone"
                value={values.phone}
                onBlur={handleBlur}
                error={errors.phone}
                onChange={handleChange}
                mask={'(99) 9 9999-9999'}
                helperText={errors.phone}
                label="PERSONAL_DATA_INPUT_LABEL_PHONE"
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type="email"
                name="email"
                load={loadingEmail}
                onBlur={handleBlur}
                onChange={handleChange}
                error={errors.email}
                value={values.email}
                helperText={errors.email}
                disabled={values._id && editRule}
                label="PERSONAL_DATA_INPUT_LABEL_EMAIL"
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type="password"
                name="password"
                onBlur={handleBlur}
                value={values.password}
                onChange={handleChange}
                error={errors.password}
                helperText={errors.password}
                label="PERSONAL_DATA_INPUT_LABEL_PASSWORD"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </div>
  )
}

export default PersonalData
