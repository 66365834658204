import React, { useState } from 'react'

import Axios from '@UI/Axios'
import Tabs from '@UI/Tabs'
import ListNews from '@components/Admin/News/ListNews'
import AddNews from '@components/Admin/News/AddNews'

const NewsScreen = (props) => {
  let [run, setRun] = useState(1)
  const [listNews, setListNews] = useState([])

  const panel = [
    { title: 'NEWS_SCREEN_TAG_LIST', icon: 'pe-7s-menu' },
    { title: 'NEWS_SCREEN_TAG_ADD', icon: 'pe-7s-plus' },
  ]

  const onGetSuccess = ({ data }) => {
    setListNews(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

  return (
    <div className="content">
      <Axios run={run} api="news" messageLoad="LOADING" onSuccess={onGetSuccess}>
        {() => (
          <Tabs tabs={panel}>
            <ListNews listNews={listNews} updateScreen={updateScreen} {...props} />
            <AddNews updateScreen={updateScreen} {...props} />
          </Tabs>
        )}
      </Axios>
    </div>
  )
}

export default NewsScreen
