import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'

// @import components
import Input from '@UI/Input'
import Axios from '@UI/Axios'
import Translate from '@UI/Translate'

// @import styles
import './styles.scss'

const ModalDelete = (props) => {
  const [validator, setValidator] = useState('')

  const onChange = ({ target: { value } }) => {
    setValidator(value)
  }

  const success = () => {
    const { updateScreen, close } = props
    setValidator('')
    updateScreen()
    close()
  }

  const { open, close, item } = props

  return (
    <Modal className="ModalDelete" open={open} onClose={close}>
      <Modal.Header>
        <Translate>USER_MODAL_DELETE_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p className="center-center">
            <Translate>USER_MODAL_DELETE_SUB_TITLE</Translate>
          </p>
          <div>
            <Input
              required
              name="validator"
              value={validator}
              label="USER_MODAL_DELETE_INPUT_HASH"
              onChange={onChange}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className="form-footer modal-footer">
          <Button onClick={close}>
            <Translate>USER_MODAL_DELETE_CANCEL</Translate>
          </Button>
          <Axios api="users" method="delete" others={`${item._id}?validator=${validator}`} onSuccess={success}>
            {({ submit }) => (
              <Button onClick={submit}>
                <Translate>USER_MODAL_DELETE_SEND</Translate>
              </Button>
            )}
          </Axios>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalDelete
