import React, { useState, useEffect } from 'react'

import Tabs from '@UI/Tabs'
import * as Api from '@UI/Axios'
import { getDecode } from '@utils/user.utils'
import ListUsers from '@components/Admin/Users/ListUsers'

import useDebounce from '@hooks/useDebounce'

const UsersScreen = (props) => {
  const [name, setName] = useState('')
  const [users, setUsers] = useState([])
  const [load, setLoad] = useState(true)
  const [filter, setFilter] = useState({})
  const [params, setParams] = useState({ limit: 10, total: 1 })

  const { permission } = getDecode() || {}
  const debouncedName = useDebounce(name, 500)

  useEffect(() => {
    const init = async () => {
      filter.name = name
      setFilter({ ...filter })
      await getApi()
    }
    init();
  }, [debouncedName])

  const panel = [
    { title: 'USER_SCREEN_TAB_LIST', icon: 'pe-7s-menu' }
  ]

  const onGetSuccess = ({ data }) => {
    const { data: list, ...itens } = data
    setUsers(list)
    setParams(itens)
    setLoad(false)
  }

  const pagination = async (page) => {
    params.page = page
    await getApi()
  }

  const getApi = async () => {
    setLoad(true)
    setUsers([])
    const { data } = await Api.http({ api: 'users', others: { ...params, ...filter } })
    onGetSuccess(data)
  }

  return (
    <div className="content">
      <Tabs tabs={panel}>
        <ListUsers
          load={load}
          users={users}
          params={params}
          setName={setName}
          permission={permission}
          pagination={pagination}
          updateScreen={getApi}
          {...props}
        />
      </Tabs>
    </div>
  )
}

export default UsersScreen

