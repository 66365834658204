import React from 'react'
import { Modal, Button, Grid } from 'semantic-ui-react';

import Input from '@UI/Input'
import Translate from '@UI/Translate';

// @import styles
import './styles.scss'

const ViewUser = (props) => {

  const { open, close, quotation = {} } = props
  const { registry } = quotation;
  
  return (
    <Modal open={open} size="large" className="ViewUser" onClose={close}>
      <Modal.Header>
        <Translate>VIEW_QUOTATION_MODAL_TITLE</Translate>
      </Modal.Header>
      <Modal.Content>
        <Grid stackable columns="equal">
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                disabled
                name="name"
                value={registry?.name}
                label="PERSONAL_DATA_INPUT_LABEL_NAME"
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                disabled
                type="cpf"
                name="cpf"
                value={registry?.cpf}
                label="PERSONAL_DATA_INPUT_LABEL_CPF"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                disabled
                type="email"
                name="email"
                value={registry?.email}
                label="PERSONAL_DATA_INPUT_LABEL_EMAIL"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <div className="form-footer">
          <Button onClick={close}>
            <Translate>CLOSE</Translate>
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ViewUser
