import React, { useState } from 'react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import ListManager from '@components/Admin/Manager/ListManager'
import AddManager from '@components/Admin/Manager/AddManager'

const Manager = (props) => {
  let [run, setRun] = useState(1)
  const [list, setList] = useState([])

  const panel = [
    { title: 'MANAGER_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'MANAGER_SCREEN_TAB_ADD', icon: 'pe-7s-plus' },
  ]

  const onGetSuccess = ({ data }) => {
    setList(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

  return (
    <div className="content">
      <Axios run={run} api="managers" messageLoad="LOADING" onSuccess={onGetSuccess}>
        {() => (
          <Tabs tabs={panel}>
            <ListManager {...props} list={list} updateScreen={updateScreen} />
            <AddManager {...props} updateScreen={updateScreen} />
          </Tabs>
        )}
      </Axios>
    </div>
  )
}

export default Manager
