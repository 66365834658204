import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import Translate from '@UI/Translate'

import './styles.scss'

const getNavStyles = (indx, length) => {
  let styles = []
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push('done')
    } else if (i === indx) {
      styles.push('doing')
    } else {
      styles.push('todo')
    }
  }
  return styles
}

const getButtonsState = (indx, length) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true
    }
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true
    }
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
      submit: true
    }
  }
}

export default function MultiStep (props) {
  const [stylesState, setStyles] = useState(getNavStyles(0, props.steps.length))
  const [compState, setComp] = useState(0)
  const [buttonsState, setButtons] = useState(getButtonsState(0, props.steps.length))

  function setStepState (indx) {
    setStyles(getNavStyles(indx, props.steps.length))
    setComp(indx < props.steps.length ? indx : compState)
    setButtons(getButtonsState(indx, props.steps.length))
  }

  const next = () => setStepState(compState + 1)

  const previous = () => setStepState((compState > 0) ? compState - 1 : compState)

  const handleKeyDown = (evt) => evt.which === 13 ? next(props.steps.length) : {}

  const handleOnClick = (evt) => {
    if (evt.currentTarget.value === props.steps.length - 1 && compState === props.steps.length - 1) {
      setStepState(props.steps.length)
    } else {
      setStepState(evt.currentTarget.value)
    }
  }

  const icon = (type) => {
    switch (type) {
      case 'done':
        return 'pe-7s-check'
      case 'doing':
        return 'pe-7s-clock'
      case 'todo':
        return 'pe-7s-config'
      default:
        return ''
    }
  }

  const renderSteps = () =>
    props.steps.map((step, i) => (
      step.name ? <li
        data-index-number={i + 1}
        className={'progtrckr-' + stylesState[i] + ' ' + icon(stylesState[i])}
        onClick={handleOnClick}
        key={i}
        value={i}
      >
        <em>{i + 1}</em>
        <span>
          <Translate>{step.name}</Translate>
        </span>
      </li> : null
    ))

  return (
    <div className="MultiStep">
      <div onKeyDown={handleKeyDown}>
        <ol className='progtrckr'>
          {renderSteps()}
        </ol>
        {
          props.steps.map((e, index) =>
            <div key={index} className={compState === index ? 'show' : 'hide'}>
              {e.component(props)}
            </div>
          )
        }
        <div className={`buttons ${!buttonsState.showPreviousBtn && props.steps.length === 1 ? '' : 'flex-end'}`} style={props.showNavigation ? {} : { display: 'none' }}>
          {buttonsState.showPreviousBtn && props.steps.length !== 1
            ? <Button
              className="custom"
              onClick={previous}
            >
              <Translate>MULTI_STEP_PREVIOS</Translate>
            </Button>
            : null}

          {buttonsState.showNextBtn && props.steps.length !== 1
            ? <Button
              className="custom"
              onClick={next}
            >
              <Translate>MULTI_STEP_NEXT</Translate>
            </Button>
            : null}

          {buttonsState.submit || props.steps.length === 1
            ? <Button
              className="custom"
              onClick={props.submit}
            >
              <Translate>MULTI_STEP_SEND</Translate>
            </Button>
            : null}

        </div>
      </div>
    </div>
  )
}

MultiStep.defaultProps = {
  showNavigation: true,
  submit: () => true
}
