import React from 'react';

import Icon from '@UI/Icon';
import Translate from '@UI/Translate';

// Styles
import './styles.scss';

class Number extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
    this.less = this.less.bind(this);
    this.plus = this.plus.bind(this);
  }

  plus() {
    const { name, value = 0 } = this.props;
    const event = {
      target: {
        name, value: (value || 0) + 1
      }
    }
    this.props.handleChange(event);
  };

  less() {
    const { name, value = 0 } = this.props;
    let newValue = (value || 0) - 1;
    if (newValue < 0) {
      newValue = 0;
    }
    const event = {
      target: {
        name, value: newValue
      }
    }
    this.props.handleChange(event);
  };

  render() {
    let {
      label,
      value = 0,
    } = this.props;
    return (
      <div className="Number">
        <label className="number__label">
          <Translate>{label}</Translate>
        </label>
        <div className="number__body center-center">
          <div onClick={this.plus} className="plus">
            <span className="center-center">
              <Icon name="plus" />
            </span>
          </div>
          <span className="value center-center">
            {value < 10 ? `0${value || 0}` : value}
          </span>
          <div onClick={this.less} className="less">
            <span className="center-center">
              <Icon name="less" />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Number;
