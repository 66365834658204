import React from 'react';
import classNames from 'classnames'

import Icon from '@UI/Icon'
import Translate from '@UI/Translate'

import './styles.scss'

const ButtonComponent = (props) => {
  const { className, iconLeft, iconRigth, basic, disabled, ...items } = props;
  const styles = classNames(["Button", className], { basic, disabled })
  return (<button {...items} className={styles}>
    {iconLeft &&
      <span style={{ marginRight: 10 }}>
        <Icon name={iconLeft} />
      </span>}
    <Translate>{props.children}</Translate>
    {iconRigth &&
      <span style={{ marginLeft: 10 }}>
        <Icon name={iconRigth} />
      </span>
    }
  </button>)

}

export default ButtonComponent;
