import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'

const Form = (props) => {
  const { formik } = props

  const { handleBlur, handleChange, values = {}, errors } = formik

  return (
    <Grid stretched stackable columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Input
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                label="ADD_TAG_INPUT_LABEL_TITLE"
                value={Utils.getValue(values, 'title')}
                error={Utils.getValue(errors, 'title')}
                helperText={Utils.getValue(errors, 'title')}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Input
              type="color"
              name="color"
              onBlur={handleBlur}
              onChange={handleChange}
              label="ADD_TAG_INPUT_LABEL_COLOR"
              value={Utils.getValue(values, 'color')}
              error={Utils.getValue(errors, 'color')}
              helperText={Utils.getValue(errors, 'color')}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Form
