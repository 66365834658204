import React, { useState } from 'react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import AddAirline from '@components/Admin/Airline/AddAirline'
import ListAirline from '@components/Admin/Airline/ListAirline'

const AirlineScreen = (props) => {
  const [run, setRun] = useState(1)
  const [airlines, setAirlines] = useState([])

  const panel = [
    { title: 'AIRLINE_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'AIRLINE_SCREEN_TAB_ADD', icon: 'pe-7s-plus' },
  ]

  const onGetSuccess = ({ data }) => {
    setAirlines(data)
  }

  const updateScreen = () => {
    setRun(run + 1)
  }

  return (
    <div className="content">
      <Axios run={run} api="airlines" messageLoad="LOADING" onSuccess={onGetSuccess}>
        {() => (
          <Tabs tabs={panel}>
            <ListAirline airlines={airlines} updateScreen={updateScreen} {...props} />
            <AddAirline airlines={airlines} updateScreen={updateScreen} {...props} />
          </Tabs>
        )}
      </Axios>
    </div>
  )
}

export default AirlineScreen
