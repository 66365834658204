import React from 'react';

import { dict } from '@UI/Translate'
import { Image } from 'semantic-ui-react'

const ImageComponent = (props) => {
  const { alt = 'IMAGE' } = props || {};
  return <Image {...props} alt={dict.translate(alt)} />
}

export default ImageComponent;
