import React, { useState } from 'react'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import ListEditor from '@components/Admin/Editor/ListEditor'
import AddEditor from '@components/Admin/Editor/AddEditor'

const Editor = (props) => {

  let [run, setRun] = useState(1)
  const [list, setList] = useState([])

  const panel = [
    { title: 'EDITOR_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'EDITOR_SCREEN_TAB_ADD', icon: 'pe-7s-plus' }
  ]

  const onGetSuccess = ({data}) => {
    setList(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

    return (
      <div className="content">
        <Axios run={run} api="editor" messageLoad="LOADING" onSuccess={onGetSuccess}>
          {() => (
            <Tabs tabs={panel}>
              <ListEditor list={list} updateScreen={updateScreen} {...props} />
              <AddEditor updateScreen={updateScreen} {...props} />
            </Tabs>
          )}
        </Axios>
      </div>
    )
  }

export default Editor
