import React from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Input from '@UI/Input'
import Card from '@UI/Card'

import './styles.scss'

const Documents = (props) => {
  const { formik } = props
  const { handleBlur, handleChange, values = {}, errors } = formik
  return (
    <div className="Documents">
      <Card title="DOCUMENTS_TITLE" category="DOCUMENTS_DESCRIPTION">
        <Grid stackable columns="equal">
          <Grid.Column>
            <Input
              {...props}
              type="file"
              name="urlDocument"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'urlDocument')}
              error={Utils.getValue(errors, 'urlDocument')}
              helperText={Utils.getValue(errors, 'urlDocument')}
              label="DOCUMENTS_INPUT_LABEL_DOCUMENT"
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              {...props}
              type="file"
              name="urlHome"
              onBlur={handleBlur}
              onChange={handleChange}
              value={Utils.getValue(values, 'urlHome')}
              error={Utils.getValue(errors, 'urlHome')}
              helperText={Utils.getValue(errors, 'urlHome')}
              label="DOCUMENTS_INPUT_LABEL_HOME"
            />
          </Grid.Column>
        </Grid>
      </Card>
    </div>
  )
}

export default Documents
