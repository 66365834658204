import classNames from 'classnames'
import React, { useEffect } from 'react'
import InputMask from 'react-input-mask'

import * as Semantic from 'semantic-ui-react'
import { Form } from 'semantic-ui-react'

import Utils from '@utils'
import Icon from '@UI/Icon'
import Translate, { dict } from '@UI/Translate'

// @import compoent
import Date from './components/Date'
import Search from './components/Search'
import Select from './components/Select'
import Radio from './components/Radio'
import Checkbox from './components/Checkbox'
import Number from './components/Number'
import Dropdown from './components/Dropdown'
import TextArea from './components/TextArea'
import Range from './components/Range'
import File from './components/File'
import Color from './components/Color'
import Tinymce from './components/Tinymce'
import Cropper from './components/Cropper'
import FileImage from './components/FileImage'

import HtmlToText from 'html-to-text'

// Styles
import './styles.scss'

const Input = (props) => {
  let {
    error,
    inline,
    className,
    value,
    type,
    mask,
    countText,
    disabled,
    readonly,
    rowreverse,
    feedback,
    helperText = feedback,
  } = props

  useEffect(() => {
    if (props.run) {
      initValue()
    }
  }, [props.run, props.value])

  const initValue = () => {
    const event = {
      target: { ...props },
    }
    props.onChange(event)
  }

  const onChange = (event) => {
    const { index, type, disabled, item, nameValue } = props
    if (disabled) {
      event.target.value = undefined
    }

    if (nameValue) {
      event.target.nameValue = nameValue
    }

    if (item) {
      event.target.item = item
    }

    if (type === 'number') {
      if (isNaN(event.target.value)) {
        event.target.value = new Number(event.target.value)
      }
    }

    let valid = validate(event.target.value)
    if (typeof props.onChange === 'function') {
      event.target.index = index
      if (!event.target.type) {
        event.target.type = type
      }
      event.target.valid = valid
      props.onChange(event)
    }
  }

  const validate = (value) => {
    let { type, required, testValue, readonly, disabled, helperText } = props
    if (readonly || disabled) {
      required = false
    }
    switch (type) {
      case 'email':
        return required ? /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(value) && !helperText : true
      case 'date':
        return required ? /(\d{2})[-.\/](\d{2})[-.\/](\d{4})/.test(value) && !helperText : true
      case 'cpf':
        return required ? Utils.validateCPF(value || '') && !helperText : true
      default:
        return required ? (testValue !== undefined ? testValue : Boolean(value) && !helperText) : true
    }
  }

  const inputIcon = () => {
    const { icon } = props
    if (icon) {
      return (
        <i className="icon center-center">
          <Icon name={icon} />
        </i>
      )
    } else {
      return null
    }
  }

  const label = () => {
    const { label, type, value } = props
    const error = !validate(value)
    if (label && type !== 'radio' && type !== 'checkbox' && type !== 'numberCustom') {
      return (
        <label className={`${error ? 'error' : ''}`}>
          <Translate>{String(label)}</Translate>
        </label>
      )
    } else {
      return null
    }
  }

  const inputProps = () => {
    let {
      mask,
      keys,
      type,
      error,
      load,
      label,
      array,
      keyValue,
      disabled,
      translate,
      format,
      helperText,
      index,
      name,
      item,
      nameValue,
      nameText,
      value,
      icon,
      iconPosition,
      checked,
      extenalIcon,
      placeholder = '',
    } = props
    if (!icon) {
      iconPosition = null
    }
    return {
      keys,
      mask,
      error,
      label,
      load,
      index,
      name,
      item,
      array,
      type,
      disabled,
      keyValue,
      value,
      nameText,
      format,
      translate,
      nameValue,
      helperText,
      iconPosition,
      onIcon: inputIcon,
      onChange: onChange,
      handleChange: onChange,
      error: !validate(value),
      checked: Boolean(checked),
      placeholder: dict.translate(placeholder),
      icon: extenalIcon ? inputIcon() : props.icon,
    }
  }

  const switchInput = () => {
    const { type } = props
    switch (type) {
      case 'search':
        return <Search {...inputProps()} />
      case 'select':
        return <Select {...inputProps()} />
      case 'date':
        return <Date {...inputProps()} />
      case 'radio':
        return <Radio {...inputProps()} />
      case 'checkbox':
        return <Checkbox {...inputProps()} />
      case 'numberCustom':
        return <Number {...inputProps()} />
      case 'dropdown':
        return <Dropdown {...inputProps()} />
      case 'textarea':
        return <TextArea {...inputProps()} />
      case 'range':
        return <Range {...inputProps()} />
      case 'fileimage':
        return <FileImage {...inputProps()} />
      case 'file':
        return <File {...inputProps()} />
      case 'color':
        return <Color {...inputProps()} />
      case 'tinymce':
        return <Tinymce {...inputProps()} />
      case 'cropper':
        return <Cropper {...inputProps()} />
      default:
        if (mask) {
          return (
            <InputMask {...inputProps()}>
              {(inputProps) => <Semantic.Input {...inputProps} disabled={disabled || readonly} />}
            </InputMask>
          )
        } else {
          return <Semantic.Input {...inputProps()} disabled={disabled || readonly} />
        }
    }
  }

  let check = false
  if (type === 'checkbox' || type === 'radio') {
    check = true
  }

  let styles = classNames('Input', className, {
    inline,
    rowreverse,
    check: check,
    error: !validate(value) || error,
  })

  return (
    <Form.Field className={styles}>
      {label()}
      {switchInput()}
      {helperText ? (
        <div className="helperText">
          <Translate>{helperText}</Translate>
          {countText && <span className="count">{HtmlToText.fromString(value).length}</span>}
        </div>
      ) : null}
    </Form.Field>
  )
}

Input.defaultProps = {
  label: '',
  index: 0,
  disabled: false,
  required: false,
  placeholder: '',
  name: '',
  value: '',
  type: 'input',
  inline: false,
  className: '',
  rowreverse: false,
  iconPosition: 'left',
  onChange: () => true,
}

export default Input
