import React, { useState, useEffect } from 'react'

import { getPermission } from '@utils/user.utils'

import Tabs from '@UI/Tabs'
import Axios from '@UI/Axios'
import ListNewsLetters from '@components/Admin/NewsLetters/ListNewsLetters'

const NewsLettersScreen = (props) => {
  let [run, setRun] = useState(1)
  const [newsLetters, setNewsLetters] = useState([])
  const [permission, setPermission] = useState('')
  const panel = [{ title: 'LIST_NEWS_LETTERS_TAG_LIST', icon: 'pe-7s-menu' }]

  useEffect(() => {
    setPermission(getPermission())
  }, [props.user])

  const onGetSuccess = ({ data }) => {
    setNewsLetters(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

  return (
    <div className="content">
      <Axios run={run} api="newsletter" messageLoad="LOADING" onSuccess={onGetSuccess}>
        {() => (
          <Tabs tabs={panel}>
            <ListNewsLetters newsLetters={newsLetters} updateScreen={updateScreen} permission={permission} {...props} />
          </Tabs>
        )}
      </Axios>
    </div>
  )
}

export default NewsLettersScreen
