import React from 'react'

import Utils from '@utils'
import Image from '@UI/Image'
import Translate from '@UI/Translate'
import InputMaterial from '@UI/InputMaterial'

import './styles.scss'

const CardStepFour = (props) => {
  const { formik, index, item } = props
  const { handleBlur, handleChange, values = {}, errors } = formik
  const form = {
    name: `fidelity.${index}.name`,
    login: `fidelity.${index}.login`,
    password: `fidelity.${index}.password`,
  }
  return (
    <div className="CardStepFour">
      <div className="card_step_four_content_header">
        <div className="card_image">
          <Image src={item.urlPhoto} size="small" alt="IMAGE"/>
        </div>
        <div className="card_price">
          <p>
            <span>{item.limitMinimum / 1000}</span>
            <Translate>ADMIN_STEP_LABEL_NUMBER_TEXT</Translate>
          </p>
        </div>
      </div>
      <div className="card_step_four_body ">
        <InputMaterial
          {...props}
          name={form.login}
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, form.login)}
          error={Utils.getValue(errors, form.login)}
          helperText={Utils.getValue(errors, form.login)}
          label="ADMIN_STEP_FORM_LOYALTY_PROGRAM_INPUT_LABEL_LOGIN"
        />
        <InputMaterial
          {...props}
          type="password"
          name="password"
          name={form.password}
          onBlur={handleBlur}
          onChange={handleChange}
          value={Utils.getValue(values, form.password)}
          error={Utils.getValue(errors, form.password)}
          helperText={Utils.getValue(errors, form.password)}
          label="ADMIN_STEP_FORM_LOYALTY_PROGRAM_INPUT_LABEL_PASSWORD"
        />
      </div>
    </div>
  )
}

export default CardStepFour
