import React, { Component } from 'react'
import { createBrowserHistory } from 'history'
import { ToastContainer } from 'react-toastify'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'

import Login from '@pages/Login'
import Error from '@pages/Error'

import GuardedRoute from "@UI/GuardedRoute";
import AdminLayout from '@components/Admin/Layout'

import Load, { LoadProvider } from '@contexts/Load'

const history = createBrowserHistory()

class App extends Component {
  render() {
    return (
        <LoadProvider>
          <BrowserRouter basename="/" history={history}>
            <Switch>
              <Route exact path="/error" component={Error} />
              <Route exact path="/login/:token?" component={Login} />
              <GuardedRoute path="/admin" component={AdminLayout}/>
              <Redirect path="*" to="/login" />
            </Switch>
          </BrowserRouter>
          <ToastContainer />
          <Load />
        </LoadProvider>
    )
  }
}

export default App
