import React, { Component } from 'react'

import { Menu, Accordion, Icon } from 'semantic-ui-react'

import AdminNavbarLinks from './AdminNavbarLinks.jsx'

import './styles.scss'
class AdminNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarExists: false,
    }
  }

  mobileSidebarToggle = (e) => {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      })
    }
    e.preventDefault()
    document.documentElement.classList.toggle('nav-open')
    var node = document.createElement('div')
    node.onclick = function () {
      this.parentElement.removeChild(this)
      document.documentElement.classList.toggle('nav-open')
    }
    document.body.appendChild(node)
  }

  render() {
    return (
      <Menu className="HeaderAdmin" fluid>
        <Menu.Header>
          <div>{this.props.brandText}</div>
          <div className="icon" onClick={this.mobileSidebarToggle}>
            <Icon name="bars" size="large" />
          </div>
        </Menu.Header>
        <Accordion>
          <AdminNavbarLinks />
        </Accordion>
      </Menu>
    )
  }
}

export default AdminNavbar
