import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'

import Utils from '@utils'
import Axios from '@UI/Axios'
import Input from '@UI/Input'
import Card from '@UI/Card'

import './styles.scss'

const BankDetails = (props) => {
  const [bank, setBacks] = useState([])

  const { formik = {} } = props
  const { handleBlur, handleChange, values = {}, errors } = formik

  const [typePix, setTypePix] = useState(Utils.getValue(values, 'bank.pix'))

  const onSuccess = (success) => {
    const data = Utils.getValue(success, 'data')
    const bank = data.map(({ Code, Name }) => {
      return {
        name: Name,
        title: `${Code} - ${Name}`,
      }
    })
    setBacks(bank)
  }

  const onchangePix = (event) => {
    const {
      target: { item },
    } = event
    setTypePix(item)
    handleChange(event)
    // setFieldValue('bank.pix.key', '')
  }

  const type = [
    { type: 'CHAIN', title: 'BACK_DETAILS_TYPE_CHAIN' },
    { type: 'SAVINGS', title: 'BACK_DETAILS_TYPE_SAVINGS' },
  ]

  const listTypePix = [
    { title: 'E-mail' },
    { title: 'Telefone', mask: '(99) 9 9999-9999' },
    { title: 'CPF', mask: '999.999.999-99' },
    { title: 'CNPJ', mask: '99.999.999/9999-99' },
    { title: 'Aleatória' },
  ]

  return (
    <div className="BankDetails">
      <Card title="BANK_DETAILS_TITLE" category="BANK_DETAILS_DESCRIPTION">
        <Grid stackable columns="equal">
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                {...props}
                name="bank.holder"
                onBlur={handleBlur}
                onChange={handleChange}
                label="BANK_DETAILS_INPUT_LABEL_HOLDER"
                value={Utils.getValue(values, 'bank.holder')}
                error={Utils.getValue(errors, 'bank.holder')}
                helperText={Utils.getValue(errors, 'bank.holder')}
              />
            </Grid.Column>
            <Grid.Column>
              <Axios load={false} run api="bank" onSuccess={onSuccess}>
                {() => (
                  <Input
                    {...props}
                    array={bank}
                    type="autocomplete"
                    nameValue="title"
                    name="bank.name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="BANK_DETAILS_INPUT_LABEL_BANK"
                    value={Utils.getValue(values, 'bank.name')}
                    error={Utils.getValue(errors, 'bank.name')}
                    helperText={Utils.getValue(errors, 'bank.name')}
                  />
                )}
              </Axios>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                {...props}
                translate
                type="select"
                name="bank.type"
                array={type}
                nameValue={'type'}
                nameText={'title'}
                onBlur={handleBlur}
                onChange={handleChange}
                label="BANK_DETAILS_INPUT_LABEL_TYPE"
                value={Utils.getValue(values, 'bank.type')}
                error={Utils.getValue(errors, 'bank.type')}
                helperText={Utils.getValue(errors, 'bank.type')}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                {...props}
                name="bank.agency"
                onBlur={handleBlur}
                onChange={handleChange}
                label="BANK_DETAILS_INPUT_LABEL_AGENCY"
                value={Utils.getValue(values, 'bank.agency')}
                error={Utils.getValue(errors, 'bank.agency')}
                helperText={Utils.getValue(errors, 'bank.agency')}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                {...props}
                name="bank.account"
                onBlur={handleBlur}
                onChange={handleChange}
                label="BANK_DETAILS_INPUT_LABEL_ACCOUNT"
                value={Utils.getValue(values, 'bank.account')}
                error={Utils.getValue(errors, 'bank.account')}
                helperText={Utils.getValue(errors, 'bank.account')}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                {...props}
                name="bank.op"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                label="BANK_DETAILS_INPUT_LABEL_OP"
                value={Utils.getValue(values, 'bank.op')}
                error={Utils.getValue(errors, 'bank.op')}
                helperText={Utils.getValue(errors, 'bank.op')}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pd-0">
            <Grid.Column>
              <Input
                {...props}
                array={listTypePix}
                type="autocomplete"
                nameValue="title"
                name="bank.pix.type"
                onBlur={onchangePix}
                onChange={onchangePix}
                label="BANK_DETAILS_INPUT_LABEL_PIX_TYPE"
                value={Utils.getValue(values, 'bank.pix.type')}
                error={Utils.getValue(errors, 'bank.pix.type')}
                helperText={Utils.getValue(errors, 'bank.pix.type')}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
              {...props}
              name="bank.pix.key"
              disabled={!typePix}
              onBlur={handleBlur}
              onChange={handleChange}
              mask={Utils.getValue(typePix, 'mask')}
              label="BANK_DETAILS_INPUT_LABEL_PIX_KEY"
              value={Utils.getValue(values, 'bank.pix.key')}
              error={Utils.getValue(errors, 'bank.pix.key')}
              helperText={Utils.getValue(errors, 'bank.pix.key')}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </div>
  )
}
export default BankDetails
