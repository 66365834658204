import React, { useState } from 'react';

import Axios from '@UI/Axios';
import Tabs from '@UI/Tabs';
import ListTags from '@components/Admin/Tags/ListTags';
import AddTags from '@components/Admin/Tags/AddTags';

const TagsScreen = (props) => {
  let [run, setRun] = useState(1)
  const [listTags, setListTags] = useState([])

  const panel = [
    { title: 'TAGS_SCREEN_TAB_LIST', icon: 'pe-7s-menu' },
    { title: 'TAGS_SCREEN_TAB_ADD', icon: 'pe-7s-plus' }
  ]

  const onGetSuccess = ({ data }) => {
    setListTags(data)
  }

  const updateScreen = () => {
    const value = run + 1
    setRun(value)
  }

  return (
    <div className="content">
      <Axios run={run} api="tag" messageLoad="LOADING" onSuccess={onGetSuccess}>
          {() => (
            <Tabs tabs={panel}>
              <ListTags listTags={listTags} updateScreen={updateScreen} {...props} />
              <AddTags listTags={listTags} updateScreen={updateScreen} {...props} />
            </Tabs>
          )}
        </Axios>
    </div>
  )
}

export default TagsScreen

