import React from 'react'

import Card from '@UI/Card'
import FormLoyaltyProgram from './components/FormLoyaltyProgram'

import './styles.scss'

const LoyaltyProgram = (props) => {
  const { formik = {} } = props
  const { values = {} } = formik
  return (
    <div className="LoyaltyProgram">
      <Card title="LOYALTY_PROGRAM_TITLE" category="LOYALTY_PROGRAM_DESCRIPTION">
        {(values.fidelity || []).map((item, index) => (
          <FormLoyaltyProgram key={index} index={index} item={item} {...props} />
        ))}
      </Card>
    </div>
  )
}

export default LoyaltyProgram
