import React, { useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import Card from '@UI/Card'
import Image from '@UI/Image'
import Axios from '@UI/Axios'
import EditNews from './components/EditNews'

// @import styles
import './styles.scss'

const ListNews = (props) => {
  const [news, setNews] = useState({})
  const [open, setOpen] = useState(false)
  
  const [filter, setFilter] = useState('')

  const headers = [
    { name: '#' },
    { name: 'LIST_NEWS_TABLE_HEADER_TITLE' },
    { name: 'LIST_NEWS_TABLE_HEADER_DESCRIPTION' },
    { name: 'LIST_NEWS_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (news) => {
    setNews(news)
    setOpen(true)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  const { listNews = [], updateScreen } = props

  let array = Utils.filter(listNews, 'title', filter)

  return (
    <div className="ListNews">
      <Card title="LIST_NEWS_CARD_TITLE" category="LIST_NEWS_CARD_DESCRIPTION">
        <Table pagination={10} label={'LIST_NEWS_TABLE_FILTER'} onChange={onChange} headers={headers}>
          {array.map((element, index) => (
            <tr key={index}>
              <td className="logo-new">
                <Image src={element.urlPhoto} alt="POST" />
              </td>
              <td>{element.title}</td>
              <td>
                <span dangerouslySetInnerHTML={{ __html: Utils.elipses(element.description) }} />
              </td>
              <td className="td-options text-center">
                <button onClick={() => onEdit(element)} className="button-edit" />
                <Axios api="news" method="delete" others={element._id} onSuccess={updateScreen}>
                  {({ submit }) => <button onClick={submit} className="button-delete" />}
                </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <EditNews news={news} open={open} close={toggle} {...props} />
    </div>
  )
}

export default ListNews
