import React from 'react'

import StepsAdmin from '@components/Admin/StepsAdmin'

import './styles.scss'

const DashboardScreen = () => {
  return (
    <div className="DashboardScreen">
      <div className="dashboard-content">
        <StepsAdmin />
      </div>
    </div>
  )
}

export default DashboardScreen
