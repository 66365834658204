import React from 'react'

import Ultis from '@utils'
import Image from '@UI/Image'
import Compress from "compressorjs";

// Styles
import './styles.scss'

class FileImage extends React.Component {
  constructor(props) {
    super(props)
    this.close = this.close.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async handleChange({ target: { files } }) {
    const { name } = this.props
    const file = files[0]
    if (file) {
      const value = await this.compress(file);
      const event = {
        target: {
          name,
          value,
        },
      }
      this.props.handleChange(event)
    }
  }

  getBase64ToFile = (file) => {
    return new Promise((resolver) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolver(reader.result)
      }
    })
  }

  compressImage(blob) {
    return new Promise((resolver) => {
      new Compress(blob, {
        quality: 0.4,
        convertSize: 1000,
        success(result) {
          resolver(result)
        },
      })
    })
  }

  compress = async (blob) => {
    const result = await this.compressImage(blob)
    let base64 = await this.getBase64ToFile(result)
    return base64
  }

  close(e) {
    Ultis.stopPropagation(e)
    const { name } = this.props
    const event = {
      target: {
        name,
        value: '',
      },
    }
    this.props.handleChange(event)
  }

  render() {
    let { value, placeholder, error } = this.props
    return (
      <div className="FileImage center-center">
        <div className="fileinput fileinput-exists" data-provides="fileinput" data-name="myimage">
          <label className="fileinput-new thumbnail" style={{ width: '150px', height: '150px;' }}>
            {value && (
              <>
                <span onClick={this.close} className="icon">
                  <i className="pe-7s-close-circle"></i>
                </span>
                <Image src={value} alt="IMAGE" />
              </>
            )}
            {!value && <div className={`${error ? 'error' : ''} file-input-border`}>{placeholder || 'Imagem'}</div>}
            <input onChange={this.handleChange} className="input-file" type="file" />
          </label>
        </div>
      </div>
    )
  }
}
export default FileImage
