import React from 'react'

import BankDetails from '@components/Admin/Register/BankDetails'

// Styles
import './styles.scss'

const StepThree = (props) => {
  return (
    <div className="StepThree">
      <BankDetails {...props} />
    </div>
  )
}

export default StepThree
