import React from 'react'
import { Tab } from 'semantic-ui-react'

import {dict} from '@UI/Translate'

//Styles
import './styles.scss'

const SimpleTab = (props) => {
  const {tabs} = props;
  const panes = tabs.map((item) => {
    return {
      menuItem: dict.translate(item.title),
      render: () => <Tab.Pane>{item.component(props)}</Tab.Pane>,
    }
  })
  return <Tab className="SimpleTab" panes={panes} />
}

export default SimpleTab
