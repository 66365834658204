import React from "react";

import Compress from "compressorjs";
import Cropper from "react-cropper";
import { Button, Grid } from "semantic-ui-react";

import Translate from "@UI/Translate";

import "cropperjs/dist/cropper.css";

// Styles
import "./styles.scss";

class CropperComponent extends React.Component {

  static defaultProps = {
    setSizeImage: () => true
  }

  constructor(props) {
    super(props);
    this.state = {
      image: "",
      urlImage: ""
    };
  }

  handleChange = value => {
    const { name } = this.props;
    const event = {
      target: {
        name,
        value: value
      }
    };
    this.props.handleChange(event);
  };

  cropImage = () => {
    const { image } = this.state;
    if (image) {
      this.setState({ image: "" });
      this.cropper.getCroppedCanvas()
        .toBlob(blob => {
          this.compress(blob);
        });
    }
  };

  onChangeImage = async ({ target: { files } }) => {
    const base64 = await this.getBase64ToFile(files[0]);
    this.setState({ image: base64, valueInput: null });
  };

  getBase64ToFile = (file) => {
    return new Promise((resolver) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolver(reader.result);
      };
    })
  };

  getSizeImage = (base64) => {
    return new Promise((resolver) => {
      const img = new Image();
      img.src = base64;
      img.onload = function () {
        resolver({ width: this.width, height: this.height })
      }
    })
  }

  compressImage(blob) {
    const file = new File([blob], "image.png", {
      type: "image/png",
      lastModified: Date.now()
    });
    return new Promise((resolver) => {
      new Compress(file, {
        quality: 0.7,
        convertSize: 1000,
        success(result) {
          resolver(result);
        }
      });
    })
  }

  compress = async blob => {
    const result = await this.compressImage(blob);
    let base64 = await this.getBase64ToFile(result);
    const size = await this.getSizeImage(base64);
    this.handleChange(base64);
    this.props.setSizeImage(size);
  };

  remove = () => {
    this.setState({ image: "" }, this.handleChange);
  };

  renderImage = () => {
    let { value } = this.props;
    if (value) {
      return <img src={value} alt="IMAGE" />;
    }
  };

  renderCropper = () => {
    const { image } = this.state;
    let { placeholder, value, aspectRatio } = this.props;
    if (!value) {
      if (!image) {
        return (
          <div className="content">
            {placeholder || "Selecione uma imagem"}
            <input onChange={this.onChangeImage} type="file" />{" "}
          </div>
        );
      } else {
        return (
          <Cropper
            ref={cropper => {
              this.cropper = cropper;
            }}
            src={image}
            guides={false}
            style={{ height: "100%", width: "100%" }}
            aspectRatio={aspectRatio || "free"}
          />
        );
      }
    }
  };

  render() {
    let { error } = this.props;
    return (
      <div className="CropperComponent center-center">
        <Grid stretched stackable columns="equal">
          <Grid.Column className="upload">
            <div className={`${error ? "error" : ""} cropper_content`}>
              <label>
                <div className="content-file">
                  {this.renderImage()}
                  {this.renderCropper()}
                </div>
              </label>
            </div>
            <div className="cropper_footer">
              <Button onClick={this.remove} color="yellow">
                <Translate>CROPPER_REMOVE</Translate>
              </Button>
              <Button onClick={this.cropImage} color="green">
                <Translate>CROPPER_SEND</Translate>
              </Button>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
export default CropperComponent;
