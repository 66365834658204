import React, { useEffect } from 'react'
import Utils from '@utils'
import CardStepFour from './components/CardStepFour'

import './styles.scss'
import { useState } from 'react'

const StepFour = (props) => {
  const { formik } = props

  const [fidelity, setFidelity] = useState([])

  useEffect(() => {
    const miles = Utils.getValue(formik, 'values.miles')
    let fidelity = Utils.getValue(formik, 'values.fidelity')
    if (!fidelity) {
      fidelity = miles.map((item) => {
        return {
          name: item.airline,
          urlPhoto: item.urlPhoto,
          limitMinimum: item.amount,
        }
      })
    }
    Utils.setValue(formik, 'values.fidelity', fidelity)
    setFidelity(fidelity)
  }, [])

  return (
    <div className="StepFour">
      {fidelity.map((item, index) => (
        <CardStepFour key={index} index={index} item={item} {...props} />
      ))}
    </div>
  )
}

export default StepFour
