import React from 'react'
import { Modal } from 'semantic-ui-react'

// @import components
import Translate from '@UI/Translate'
import AddEditor from '@components/Admin/Editor/AddEditor'

// @import styles
import './styles.scss'

const EditEditor = (props) => {
    const { open, close } = props
    return (
      <Modal open={open} centered={false} size="large" className="EditEditor" onClose={close}>
        <Modal.Header>
          <Translate>EDITOR_EDIT_TITLE</Translate>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <AddEditor {...props}/>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }

export default EditEditor
