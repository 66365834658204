import React, { useEffect, useState } from 'react'

import Utils from '@utils'
import Table from '@UI/Table'
import AddEdit from './components/AddEdit'

// @import styles
import './styles.scss'

const AddAirline = (props) => {
  const { formik = {} } = props

  const [open, setOpen] = useState(false)
  const [airline, setAirline] = useState({})

  const headers = [
    { name: '#' },
    { name: 'ADD_AIRLINE_TABLE_HEADER_AIRLINE' },
    { name: 'ADD_AIRLINE_TABLE_HEADER_AMOUNT' },
    { name: 'ADD_AIRLINE_TABLE_HEADER_PAYMENT' },
    { name: 'ADD_AIRLINE_TABLE_HEADER_VALUE' },
    { name: 'ADD_AIRLINE_TABLE_HEADER_OPTIONS' },
  ]

  const path = 'miles'
  const { values } = formik

  const [list, setList] = useState([])
  const [filter, setFilter] = useState('')

  useEffect(() => {
    const list = Utils.getValue(values, path) || []
    updateList(list) // eslint-disable-next-line
  }, [values])

  const updateList = (list) => {
    setAirline({})
    setList([...list])
    Utils.setValue(values, path, list)
  }

  const onAdd = (item) => {
    toggle()
    list.push(item)
    updateList(list)
  }

  const onEdit = (item) => {
    toggle()
    const index = list.indexOf(airline)
    if (index >= 0) {
      list[index] = item
      updateList(list)
    }
  }

  const onDelete = (index) => {
    list.splice(index, 1)
    updateList([...list])
  }

  const onSelectEdit = (airline) => {
    setOpen(true)
    setAirline(airline)
  }

  const toggle = () => {
    setOpen(!open)
  }

  const onChange = ({ target: { value } }) => {
    setFilter(value)
  }

  let array = Utils.filter(list, 'airline', filter) || []

  return (
    <div className="AddAirline">
      <Table
        pagination={2}
        onAdd={toggle}
        headers={headers}
        onChange={onChange}
        label={'ADD_AIRLINE_TABLE_FILTER'}
      >
        {array.map((element, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{element.airline}</td>
            <td>{Utils.toMaskNumber(element.amount)}</td>
            <td>{element.payment}</td>
            <td>{Utils.maskMoney(element.price)}</td>
            <td className="td-options text-center">
              <button onClick={() => onSelectEdit(element)} className="button-edit" />
              <button onClick={() => onDelete(index)} className="button-delete" />
            </td>
          </tr>
        ))}
      </Table>
      <AddEdit {...props} open={open} close={toggle} onEdit={onEdit} onAdd={onAdd} airline={airline} />
    </div>
  )
}

export default AddAirline
