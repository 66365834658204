import Home from '@pages/Home'
import Profile from '@pages/Profile'
import Airline from '@pages/Airline'
import PostAirline from '@pages/PostAirline'
import Editor from '@pages/Editor'
import Letter from '@pages/Letter'
import Manager from '@pages/Manager'
import News from '@pages/News'
import Posts from '@pages/Posts'
import Quotation from '@pages/Quotation'
import Tags from '@pages/Tags'
import Testimony from '@pages/Testimony'
import Users from '@pages/Users'

import { goToSite } from '@utils/user.utils'

const dashboardRoutes = [
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/home',
    name: 'Home',
    icon: 'pe-7s-graph',
    component: Home,
    permission: ['Manager', 'User', 'Editor'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/profile',
    name: 'Perfil',
    icon: 'pe-7s-user',
    component: Profile,
    permission: ['Manager', 'User', 'Editor'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/arline',
    component: Airline,
    icon: 'pe-7s-plane',
    name: 'Companhias Aéreas',
    permission: ['Manager'],
  },
  {
    menu: false,
    layout: '/admin',
    params: '/:id',
    path: '/postairline',
    component: PostAirline,
    permission: ['Manager'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    name: 'Cotações',
    path: '/quotation',
    icon: 'pe-7s-rocket',
    component: Quotation,
    permission: ['Manager', 'User'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/users',
    name: 'Usuários',
    component: Users,
    icon: 'pe-7s-users',
    permission: ['Manager'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/manager',
    name: 'Managers',
    icon: 'pe-7s-coffee',
    component: Manager,
    permission: ['Manager'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/editor',
    name: 'Gestão',
    icon: 'pe-7s-id',
    component: Editor,
    permission: ['Manager'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    icon: 'pe-7s-chat',
    path: '/testimony',
    name: 'Depoimentos',
    component: Testimony,
    permission: ['Manager'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/letter',
    name: 'Boletins',
    component: Letter,
    permission: ['Manager'],
    icon: 'pe-7s-mail-open-file',
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/news',
    name: 'Noticias',
    component: News,
    icon: 'pe-7s-news-paper',
    permission: ['Manager', 'Editor'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    name: 'Posts',
    path: '/posts',
    component: Posts,
    icon: 'pe-7s-notebook',
    permission: ['Manager', 'Editor'],
  },
  {
    menu: true,
    layout: '/admin',
    params: '',
    path: '/tags',
    name: 'Tags',
    component: Tags,
    icon: 'pe-7s-ribbon',
    permission: ['Manager', 'Editor'],
  },
  {
    params: '',
    menu: true,
    close: true,
    layout: '',
    name: 'Sair',
    icon: 'pe-7s-power',
    onClick: goToSite,
    permission: ['Manager', 'User', 'Editor'],
  }
]

export default dashboardRoutes
