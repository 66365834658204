import React, { useState } from 'react'

import Table from '@UI/Table'
import Card from '@UI/Card'
import Axios from '@UI/Axios'
import EditManager from './components/EditManager'

// @import styles
import './styles.scss'

const ListManager = (props) => {
  const headers = [
    { name: '#' },
    { name: 'MANAGER_LIST_TABLE_HEADER_NAME' },
    { name: 'MANAGER_LIST_TABLE_HEADER_EMAIL' },
    { name: 'MANAGER_LIST_TABLE_HEADER_PHONE' },
    { name: 'MANAGER_LIST_TABLE_HEADER_OPTION', class: 'text-center' },
  ]

  const [user, setUser] = useState()
  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (user) => {
    setOpen(true)
    setUser(user)
  }

  const { list, updateScreen } = props

  return (
    <div className="ListManager">
      <Card title="MANAGER_LIST_CARD_TITLE" category="MANAGER_LIST_CARD_DESCRIPTION">
        <Table pagination={10} headers={headers}>
          {list.map((element, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{element.name}</td>
              <td>{element.email}</td>
              <td>{element.phone}</td>
              <td className="td-options text-center">
                <button onClick={() => onEdit(element)} className="button-edit" />
              <Axios api="managers" method="delete" others={element._id} onSuccess={updateScreen}>
                {({ submit }) => <button onClick={submit} className="button-delete" />}
              </Axios>
              </td>
            </tr>
          ))}
        </Table>
      </Card>
      <EditManager {...props} user={user} open={open} close={toggle} />
    </div>
  )
}

export default ListManager
